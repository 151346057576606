(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDataDocumentController', ImportedDataDocumentController);

    function ImportedDataDocumentController($scope, $state, $uibModal, noty, ImportedDataDocument, DataUtils, Organization, defaultOrganization, previousState) {
        var vm = this;

        vm.organizations = [];
        vm.organization = undefined;
        vm.showOrganizations = false;
        vm.data = undefined;
        vm.fileName = undefined;
        vm.extension = undefined;
        vm.type = undefined;

        vm.selectFiles = selectFiles;
        vm.loadOrganizations = loadOrganizations;
        vm.organizationSelected = organizationSelected;
        vm.upload = upload;
        vm.clear = clear;

        if (defaultOrganization.organizationKey === 'giro') {
            loadAllOrganizations();
            vm.showOrganizations = true;
        } else {
            vm.showOrganizations = false;
            vm.organization = defaultOrganization;
        }

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
            }, function () {
            });
        }

        function loadOrganizations(query, callback) {
            Organization.getSimpleByProvider({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result);
            }, function () {
            });
        }

        function organizationSelected($id, $model) {
            vm.organization = $id ? $model : undefined;
        }

        function selectFiles($files) {
            if (!$files || $files.length === 0) return;
            var $file = $files
                .find(function ($it) {
                    return $it.$error !== 'pattern';
                });

            if (!$file) return;

            DataUtils.toBase64($file, function (base64Data) {
                $scope.$apply(function () {
                    vm.data = base64Data;
                    vm.fileName = $file.name;
                    vm.extension = mapFileExtensionByName($file.name);
                });
            });
        }

        function mapFileExtensionByName($name) {
            if (!$name) return undefined;
            if ($name.endsWith('.txt')) return 'TXT';
            if ($name.endsWith('.csv')) return 'CSV';
            if ($name.endsWith('.xlsx')) return 'XSLX';
            return undefined;
        }

        function upload() {
            $uibModal.open({
                templateUrl: 'app/entities/imported-data-document/imported-data-document-upload-dialog.html',
                controller: 'ImportedDataDocumentUploadDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: {
                        data: vm.data,
                        type: vm.type,
                        fileName: vm.fileName,
                        extension: vm.extension,
                        organization: vm.organization
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('imported-data-document');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function ($result) {
                $state.go('imported-data-document', null, {reload: true});
            });
        }

        function clear() {
            $state.go(previousState.name || '^', previousState.params);
        }
    }
})();
