(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ExternalCreditsDialogController', ExternalCreditsDialogController);

    /** @ngInject **/
    function ExternalCreditsDialogController($translate, $timeout, $rootScope, $state, previousState, entity, ExternalCredit,
                                             ProgramProvider, Program, StringUtils) {
        var vm = this;

        vm.credit = entity;
        vm.datePickerOpenStatus = {};

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.expire = expire;

        vm.programs = [];
        vm.programProviders = [];

        vm.loaded = false;

        vm.expireDate = {
            datepickerOptions: {
                minDate: new Date()
            }
        };

        if (vm.credit && vm.credit.id && vm.credit.program) {
            vm.programs.push(vm.credit.program);
            $rootScope.$broadcast('update.program');
        }

        Program.filterToExternalCreditByCompanyDefault(function (result) {
            vm.programs = vm.programs.concat(result.content);
        });

        if (vm.credit && vm.credit.id && vm.credit.program && vm.credit.program.id) {
            if (vm.credit.programProvider) {
                vm.programProviders.push(StringUtils.formatToNameAndIdentificationLabel(vm.credit.programProvider));
            }
            ProgramProvider.searchByProgramSimple({
                param: vm.credit.program.id
            }, function (result) {
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListToNameAndIdentificationLabel(result.content));
                $rootScope.$broadcast('update.programProvider');
            })
        }


        vm.searchPrograms = searchPrograms;
        vm.selectProgram = selectProgram;

        function searchPrograms(searchText, callback) {
            Program.filterToExternalCreditByCompanyDefault({q: searchText}, function (result) {
                vm.programs = result.content;
                callback(result);
            });
        }

        function selectProgram(id, model) {
            vm.credit.program = model;
            if (vm.credit.program.id) {
                ProgramProvider.searchByProgramSimple({
                    param: vm.credit.program.id
                }, function (result) {
                    vm.programProviders = vm.programProviders.concat(StringUtils.formatListToNameAndIdentificationLabel(result.content));
                    $rootScope.$broadcast('update.programProvider');
                });
            }
        }

        vm.searchProviders = searchProviders;
        vm.selectProvider = selectProvider;


        function searchProviders(searchText, callback) {
            if (vm.credit && vm.credit.program && vm.credit.program.id) {
                ProgramProvider.searchByProgramSimple({
                    param: vm.credit.program.id,
                    q: searchText
                }, function (result) {
                    vm.programProviders = vm.programProviders.concat(StringUtils.formatListToNameAndIdentificationLabel(result.content));
                    $rootScope.$broadcast('update.programProvider');
                    callback(result.content);
                });
            }
        }

        function selectProvider(id, model) {
            vm.credit.programProvider = model;
        }

        function clear() {
            $state.go(previousState.name || 'external-credit', previousState.params);
        }

        function save(form) {
            if (form.editForm.$invalid) {
                return;
            }
            vm.isSaving = true;
            if (vm.credit.id !== undefined) {
                ExternalCredit.update(vm.credit, onSaveSuccess, onSaveError);
            } else {
                ExternalCredit.save(vm.credit, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess() {
                $timeout(function () {
                    $state.go(previousState.name || 'external-credit', previousState.params);
                }, 150);
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        }

        function expire() {
            if (!vm.credit.id) return;

            vm.isSaving = true;
            ExternalCredit.expire(undefined, {id: vm.credit.id}, onExpireSuccess, onExpireError);

            function onExpireSuccess() {
                $timeout(function () {
                    $state.go(previousState.name || 'external-credit', previousState.params);
                }, 150);
                vm.isSaving = false;
            }

            function onExpireError() {
                vm.isSaving = false;
            }
        }


        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.paidDate = false;
        vm.datePickerOpenStatus.requestedDate = false;
        vm.datePickerOpenStatus.expireDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
