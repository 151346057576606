/**
 * Created by rene on 12/29/17.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AnticipationPanelDetailController', AnticipationPanelDetailController);

    /** @ngInject **/
    function AnticipationPanelDetailController(noty, entity, credit, anticipationByMonth, $translate, $state, $uibModal, $window, $stateParams, $rootScope, pagingParams, CompanyEvent, SaleStatus, ProgramProvider, GiroConstants, AlertService, InterestStatus) {
        var vm = this;

        vm.anticipation = entity;
        vm.credit = credit;
        vm.anticipationByMonth = anticipationByMonth;
        vm.programId = $stateParams.programId;

        vm.currency = GiroConstants.Constants.currency;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.allSaleStatus = [];
        vm.saleStatus = entity.saleStatus ? entity.saleStatus : {};

        vm.copyToClipboard = copyToClipboard;
        vm.showInviteUrl = undefined;

        vm.newEmail = '';
        vm.saveNewEmail = saveNewEmail;

        vm.newPhone = {
            number: '',
            whatsAppResponsible: '',
            type: 'CELLULAR',
            whatsAppStatus: 'NOT_KNOWN'
        };
        vm.saveNewPhone = saveNewPhone;

        vm.inviteProvider = inviteProvider;

        vm.allInterestStatus = [];
        vm.interestStatus = vm.anticipation
        && vm.anticipation.companyInterest
        && vm.anticipation.companyInterest.interestStatus ?
            vm.anticipation.companyInterest.interestStatus : undefined;
        vm.selectInterestStatus = selectInterestStatus;
        vm.loadInterestStatus = loadInterestStatus;
        vm.saveCompanyInterest = saveCompanyInterest;
        loadInterestStatus();


        loadEvents();

        chart();

        vm.loadSaleStatus = loadSaleStatus;

        loadSaleStatus();

        vm.saveEvent = function () {
            vm.event.company = {id: vm.anticipation.company.id};
            vm.event.programProvider = {id: $stateParams.id};

            CompanyEvent.saveFromAnticipationPanel(vm.event, function () {
                vm.event = {};
                loadEvents();
            });
        };

        vm.goToExecutedCredits = function () {
            $state.go('credit-approved-detail-provider', {
                providerId: $stateParams.id,
                programId: $stateParams.programId
            });
        };

        function loadEvents() {
            CompanyEvent.getAll({
                page: 0,
                size: 5,
                sort: 'eventCreated,desc',
                companyId: vm.anticipation.company.id
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.companyEvents = data.content;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.goToEvent = function () {
            $state.go("company-event", {companyId: vm.anticipation.company.id});
        };

        vm.openEmailModal = function ($id) {
            $uibModal.open({
                templateUrl: 'app/components/email/send-invite-provider-email-dialog.html',
                controller: 'SendInviteProviderEmailDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: ['EmailAddress', function (EmailAddress) {
                        return EmailAddress.get({id: $id}).$promise;
                    }],
                    body: ['EmailAddress', function (EmailAddress) {
                        return EmailAddress.getInviteProviderEmailBody({providerId: vm.anticipation.id}).$promise;
                    }]
                }
            }).result.then(function () {

            }, function () {

            });
        };

        vm.sort = function () {
            vm.credit.credits.sort(function (a, b) {
                if (vm.predicate === 'nfNumber') {
                    var v1 = parseInt(a.nfNumber);
                    var v2 = parseInt(b.nfNumber);
                    return v1 - v2;
                }

                if (vm.predicate === 'nfSerial') {
                    var v1 = parseInt(a.nfSerial);
                    var v2 = parseInt(b.nfSerial);
                    return v1 - v2;
                }

                if (vm.predicate === 'value') {
                    return a.value - b.value;
                }

                if (vm.predicate === 'estimatedValue') {
                    return a.creditEstimate.estimatedValue - b.creditEstimate.estimatedValue;
                }

                if (vm.predicate === 'createdDate') {
                    var v1 = new Date(a.createdDate);
                    var v2 = new Date(b.createdDate);
                    return v1 - v2;
                }

                if (vm.predicate === 'expireDate') {
                    var v1 = new Date(a.expireDate);
                    var v2 = new Date(b.expireDate);
                    return v1 - v2;
                }

            });

            if (vm.reverse) {
                vm.credit.credits.reverse();
            }
        };

        function chart() {
            var months = anticipationByMonth.months.map(function (month) {
                return month.month + '/' + month.year;
            });

            var titleValues = anticipationByMonth.months.map(function (month) {
                return month.value;
            });

            var anticipationValue = anticipationByMonth.months.map(function (month) {
                return month.anticipateValue;
            });

            Highcharts.setOptions({
                lang: {
                    thousandsSep: '.',
                    decimalPoint: ','
                }
            });

            Highcharts.chart('chart', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Valor Antecipado X Valor do Título'
                },
                xAxis: {
                    categories: months
                },
                yAxis: {
                    title: {
                        text: vm.currency
                    }
                },
                tooltip: {
                    pointFormat: vm.currency + ' {point.y:,.2f}'
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Valor Antecipado',
                    data: anticipationValue
                }, {
                    name: 'Valor do Título',
                    data: titleValues
                }]
            });
        }

        vm.batchQuantity = 0;
        vm.allSelected = false;
        vm.batchChanged = function ($credit) {
            if ($credit.batched) {
                $credit.batched = false;
                vm.batchQuantity = vm.batchQuantity - 1;
            } else {
                $credit.batched = true;
                vm.batchQuantity = vm.batchQuantity + 1;
            }
        };

        vm.selectAll = function () {
            vm.allSelected = !vm.allSelected;
            if (vm.allSelected) {
                vm.companiesList = vm.companies;
                vm.credit.credits.forEach(function ($credit) {
                    $credit.batched = true;
                });
                vm.batchQuantity = vm.credit.credits.length;
            } else {
                vm.companiesList = [];
                vm.credit.credits.forEach(function ($credit) {
                    $credit.batched = false;
                });
                vm.batchQuantity = 0;
            }
        };

        function transition() {
            $state.transitionTo($state.$current, {
                id: $stateParams.id
            }, {reload: true});
        }

        vm.createBatch = function () {
            var batch = {
                credits: [],
                value: 0,
                programProviderId: $stateParams.id
            };

            angular.forEach(vm.credit.credits, function ($credit) {
                if ($credit.batched) {
                    batch.credits.push(angular.copy($credit));
                    batch.value += $credit.value;
                }
            });

            $uibModal.open({
                templateUrl: 'app/entities/credit-visualizer/batch-visualizer-request-dialog.html',
                controller: 'BatchVisualizerRequestController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return batch;
                    }
                }
            }).result.then(function () {
                noty.success($translate.instant('message.alert.batch.save'));
                vm.page = 0;
                transition();
            }, function ($result) {
                if ($result !== 'cancel') {
                    vm.page = 0;
                    transition();
                }
            });
        };


        vm.saleStatusSelected = function ($id, $model) {
            vm.saleStatus = $model;
        };

        function loadSaleStatus(query, callback) {
            SaleStatus.query({q: query, sort: 'order'}, function (result) {
                vm.allSaleStatus = result.content;
                if (callback) {
                    callback(vm.allSaleStatus);
                }
            });
        }


        vm.saveSaleStatus = function () {
            ProgramProvider.updateSaleStatus({id: entity.id}, vm.saleStatus,
                function () {
                    noty.success($translate.instant('message.alert.programProvider.saleStatus.update'));
                });
        };

        function copyToClipboard($value, $id) {
            if (!$value) return;

            var copyElement = $window.document.createElement("span");
            copyElement.appendChild($window.document.createTextNode($value));
            copyElement.id = 'tempCopyToClipboard';
            angular.element($window.document.body.append(copyElement));

            var range = $window.document.createRange();
            range.selectNode(copyElement);
            $window.getSelection().removeAllRanges();
            $window.getSelection().addRange(range);

            try {
                var result = $window.document.execCommand('copy');
                if (result) {
                    noty.success($translate.instant('message.alert.inviteLink.copy.success'));
                } else {
                    noty.error($translate.instant('message.alert.inviteLink.copy.error'));
                    vm.showInviteUrl = $id;
                }
            } catch (e) {
                noty.error($translate.instant('message.alert.inviteLink.copy.error'));
                vm.showInviteUrl = $id;
            }

            $window.getSelection().removeAllRanges();
            copyElement.remove();
        }

        function saveNewEmail(form) {
            if (form.newEmailForm.$invalid) return;

            ProgramProvider.addEmail({id: vm.anticipation.id}, {email: vm.newEmail}, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.anticipation.company.emailAddresses.push(result);
                vm.newEmail = '';
                noty.success($translate.instant('message.alert.company.emailAddress.created'));
                form.newEmail.$setPristine();
                form.newEmail.$setUntouched();
            }

            function onSaveError(error) {
                AlertService.error(error.data.message);
            }

        }

        function saveNewPhone(form) {
            if (form.newPhoneForm.$invalid) return;

            ProgramProvider.addPhone({id: vm.anticipation.id}, vm.newPhone, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.anticipation.company.phones.push(result);
                vm.newPhone = {
                    number: '',
                    whatsAppResponsible: '',
                    type: 'CELLULAR',
                    whatsAppStatus: 'NOT_KNOWN'
                };
                noty.success($translate.instant('message.alert.company.phone.created'));
                form.newPhoneForm.$setPristine();
                form.newPhoneForm.$setUntouched();
            }

            function onSaveError(error) {
                AlertService.error(error.data.message);
            }
        }

        function inviteProvider(email, companyId, emailId) {
            $uibModal.open({
                templateUrl: 'app/entities/anticipation-panel/anticipation-panel-invite-provider.html',
                controller: 'AnticipationPanelInviteProviderController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            companyId: companyId,
                            emailId: emailId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    transition();
                }
            }, function () {
            });
        }

        vm.selectedValueSum = 0;

        $rootScope.$watch(function () {
            if (!vm.credit.credits) return 0;
            return vm.credit.credits
                .filter(function ($credit) {
                    return $credit.batched === true;
                })
                .map(function ($credit) {
                    return $credit.value;
                })
                .filter(function ($value) {
                    return typeof ($value) === 'number' && !isNaN($value);
                })
                .reduce(function ($one, $other) {
                    return $one + $other;
                }, 0);
        }, function (newValue) {
            vm.selectedValueSum = newValue;
        });

        function selectInterestStatus($id, $model) {
            vm.interestStatus = $model;
        }

        function loadInterestStatus(query, callback) {
            InterestStatus.getAll({search: query, sort: 'order'}, onSuccess);

            function onSuccess(result) {
                vm.allInterestStatus = result.content;
                if (callback) {
                    callback(vm.allInterestStatus);
                }
            }
        }

        function saveCompanyInterest(allOrganizations) {
            if (!vm.interestStatus) return;

            ProgramProvider.updateCompanyInterest(
                {id: $stateParams.id, interestId: vm.interestStatus.id, allOrganizations: allOrganizations === true}
                , onSuccess);

            function onSuccess(result) {
                vm.anticipation.companyInterest = result;
            }
        }

    }
})();
