(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ApprovalRequestManageController', ApprovalRequestManageController);

    function ApprovalRequestManageController(pagingParams, ParseLinks, ApprovalRequested) {
        var vm = this;
        vm.batches = [];

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 10;
        vm.transition = transition;
        vm.loadPage = loadPage;

        getApprovalRequest();

        function getApprovalRequest() {
            ApprovalRequested.getOpen({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            },function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = result.totalElements;
                vm.queryCount = vm.totalItems;
                vm.batches = result.content;
                vm.page = result.number + 1;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        vm.approve = function ($approvalRequested) {
            ApprovalRequested.approve($approvalRequested, function () {
                getApprovalRequest();
            })
        };

        vm.denied = function ($approvalRequested) {
            ApprovalRequested.denied($approvalRequested, function () {
                getApprovalRequest();
            })
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

    }
})();
