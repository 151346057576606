(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('BlockedEmail', BlockedEmail);

    /** @ngInject **/
    function BlockedEmail($resource) {
        var resourceUrl = 'api/blocked-emails/:id/:action';

        return $resource(resourceUrl, {id : '@id'}, {
            'blocked': {method: 'POST'}
        })
    }
})();
