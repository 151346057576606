(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('NavbarController', NavbarController);

    function NavbarController($sce, $state, $timeout, Auth, Principal, ProfileService, LoginService, GiroAccount, $scope) {
        var vm = this;

        vm.jivo = $sce.trustAsHtml('<div></div>');
        vm.digisac = $sce.trustAsHtml('<div></div>');
        vm.isNavbarCollapsed = true;
        vm.authenticated = Principal.authenticated();
        vm.account = {};
        vm.mutipleCompanies = Principal.haveMultipleCompanies();

        $scope.$on('navbarReload', function (event) {
            vm.mutipleCompanies = Principal.haveMultipleCompanies();
            vm.authenticated = Principal.authenticated();
            giroAccount();
        });

        $scope.$on('defaultCompanyChanged', function (event, companyName) {
            vm.account.companyName = companyName;
        });

        $scope.$on('userCompaniesInPrincipal', function (event) {
            var company = Principal.getDefaultCompany();
            vm.mutipleCompanies = Principal.haveMultipleCompanies();
            vm.account['companyName'] = company ? company.name : '';
        });

        giroAccount();

        function giroAccount() {
            if (vm.authenticated && !Principal.hasAnyAuthority(["ROLE_ADMIN"])) {
                GiroAccount.user(function (result) {
                    vm.account = result;
                    vm.account.firstName = vm.account.firstName ? vm.account.firstName.split(' ')[0] : vm.account.firstName;

                    if (vm.account.intercomEnabled) {
                        window.Intercom("boot", {
                            app_id: vm.account.intercomApiKey,
                            name: result.firstName,
                            email: vm.account.email,
                            user_id: vm.account.id,
                            user_hash: vm.account.userHash,
                            company: {
                                company_id: vm.account.companyId,
                                name: vm.account.companyName,
                                identification: vm.account.companyIdentification,
                                canAnticipate: vm.account.canAnticipate,
                                isProvider: vm.account.isProvider,
                                isCustomer: vm.account.isCustomer,
                                openCreditsValue: vm.account.openCreditsValue,
                                lastAnticipation: vm.account.lastAnticipation
                            }
                        });
                    } else if (vm.account.jivoEnabled) {
                        var html = '<script src="//code.jivosite.com/widget/' + vm.account.jivoApiKey + '" async></script>';
                        vm.jivo = $sce.trustAsHtml(html);
                    } else if (vm.account.digisacEnabled) {
                        window._digisac = {id: vm.account.digisacApiKey};
                        var html = '<script src="https://webchat.digisac.app/embedded.js"></script>';
                        vm.jivo = $sce.trustAsHtml(html);
                    }
                });
            }
        }

        window.jivo_onLoadCallback = function () {
            window.jivo_api.setUserToken(vm.account.id);

            window.jivo_api.setContactInfo({
                name: vm.account.firstName,
                email: vm.account.email,
                phone: "",
                description: ""
            });

            window.jivo_api.setCustomData([
                {
                    title: "companyId",
                    content: vm.account.companyId
                },
                {
                    title: "companyName",
                    content: vm.account.companyName
                },
                {
                    title: "companyIdentification",
                    content: vm.account.companyIdentification
                },
                {
                    title: "canAnticipate",
                    content: vm.account.canAnticipate + ""
                },
                {
                    title: "isProvider",
                    content: vm.account.isProvider + ""
                },
                {
                    title: "isCustomer",
                    content: vm.account.isCustomer + ""
                },
                {
                    title: "openCreditsValue",
                    content: vm.account.openCreditsValue + ""
                },
                {
                    title: "lastAnticipation",
                    content: vm.account.lastAnticipation + ""
                }
            ])
        };

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();

            vm.authenticated = null;

            $state.go('redirect');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
