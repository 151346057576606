(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProgramDialogController', ProgramDialogController);

    /** @ngInject **/
    function ProgramDialogController($translate, $timeout, $rootScope, entity, Program, $state, Organization, Company, ProgramFinancial, noty, StringUtils) {
        var vm = this;

        vm.categories = [];
        vm.programFinancials = [];
        vm.programInvestors = [];
        vm.persons = [];
        vm.program = entity;
        vm.showProviders = false;

        if (vm.program && vm.program.organization) {
            vm.organization = vm.program.organization;
        }

        if (vm.program !== undefined) {
            if (vm.program.buyer === undefined) {
                vm.program.buyer = {};
            }
        }

        vm.programConfiguration = vm.program.programConfiguration;
        vm.calculateProviderCost = calculateProviderCost;
        vm.removeInvestor = removeInvestor;
        vm.removeIdentification = removeIdentification;
        vm.addInvestor = addInvestor;
        vm.addIdentificationNumbers = addIdentificationNumbers;
        vm.clear = clear;
        vm.save = save;

        if (vm.program.id) {
            vm.buyer = vm.program.buyer.company;
            vm.showProviders = true;

            if (vm.program.buyer && !vm.program.buyer.name) {
                vm.program.buyer.name = vm.buyer.name
            }

            if (!vm.program.financials || vm.program.financials.length === 0) {
                vm.program.financials = [{identificationDefault: false, financialConfiguration: {sessionTerm: ''}}]
            } else {
                vm.program.financials = vm.program.financials.map(function ($it) {
                    if ($it.financialConfiguration === null || $it.financialConfiguration === undefined) {
                        $it.financialConfiguration = {sessionTerm: ''}
                    }
                    return $it;
                })
            }

            if (vm.programConfiguration) {
                vm.programConfiguration.addProviderManually = vm.programConfiguration.addProviderManually || false;
                vm.programConfiguration.daysToPay = vm.programConfiguration.daysToPay || 1;
                vm.programConfiguration.floatInDays = vm.programConfiguration.floatInDays || 0;
                vm.programConfiguration.expireCreditIn = vm.programConfiguration.expireCreditIn || 0;
                vm.programConfiguration.sendEmailInRequest = vm.programConfiguration.sendEmailInRequest || false;
                vm.programConfiguration.sendAllRequestEmail = vm.programConfiguration.sendAllRequestEmail || false;
                vm.programConfiguration.defaultProviderStatus = vm.programConfiguration.defaultProviderStatus || 'INACTIVATED';
                vm.programConfiguration.anticipationMaxTime = vm.programConfiguration.anticipationMaxTime ? new Date(vm.programConfiguration.anticipationMaxTime) : undefined;
            }

        } else {
            vm.program.status = "ACTIVE";
        }

        if (entity.programIndentificationNumbers) {
            vm.programIndentificationNumbers = entity.programIndentificationNumbers;
        } else {
            vm.programIndentificationNumbers = [];
        }

        initializePeople();

        function initializePeople() {
            var people = [];
            if (vm.program.buyer && vm.program.buyer.company && vm.program.buyer.company) {
                people = people.concat(entity.buyer.company);
                people = people.concat(StringUtils.formatListToNameAndIdentificationLabel(people));
            }

            if (entity.financials) {
                angular.forEach(entity.financials, function ($fin) {
                    if ($fin.company && $fin.company) {
                        people = people.concat($fin.company);
                        people = people.concat(StringUtils.formatListToNameAndIdentificationLabel(people));
                    }
                });
            }

            Company.search(function (result) {
                people = people.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
            });

            vm.persons = people;
        }

        function clear() {
            $state.go('^');
        }

        vm.buyerSelected = buyerSelected;
        vm.loadCompanies = loadCompanies;
        vm.financialSelected = financialSelected;

        function buyerSelected(id, $model) {
            vm.program.buyer.company = $model;
            if ($model && !vm.program.buyer.name) {
                vm.program.buyer.name = $model.name;
                addIdentificationNumbers();
                setIdentificationNumber();
            }
        }

        function setIdentificationNumber() {
            if (vm.programIndentificationNumbers && vm.programIndentificationNumbers.length > 0) {
                vm.programIndentificationNumbers[0].identificationNumber = (vm.program.buyer.company.identification);
            }
        }

        function financialSelected(id, $model, financial) {
            financial.company = $model;
        }

        function loadCompanies(searchText, callback) {
            Company.search({q: searchText, limit: 50}, function (result) {
                vm.persons = vm.persons.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
                $rootScope.$broadcast('update.company');
                callback(vm.persons);
            }, function () {
                callback(vm.persons);
            });
        }

        angular.forEach(vm.programIndentificationNumbers, function (result) {
            if (!result.identificationNumber) {
                noty.error("alert.program.identificationNumber.empty");
                vm.isSaving = false;
                return;
            }

            if (!result.companyName) {
                noty.error("alert.program.companyName.empty");
                vm.isSaving = false;
            }
        });


        function save(form) {
            if (form.editForm.$invalid) {
                return;
            }
            removeComma();
            vm.isSaving = true;

            vm.program.buyer.active = true;
            angular.forEach(vm.program.providers, function ($p) {
                if ($p.id && $p.id < 0) {
                    $p.id = undefined;
                }
            });

            vm.program.programIndentificationNumbers = vm.programIndentificationNumbers;
            vm.program.programConfiguration = vm.programConfiguration;

            vm.isSaving = true;
            if (vm.program.id === null || vm.program.id === undefined) {
                Program.save(vm.program, onSaveSuccess, onSaveError);
            } else {
                Program.update(vm.program, onSaveSuccess, onSaveError);
            }

        }

        function onSaveSuccess(result) {
            var program = result;

            if (vm.showProviders) {
                $timeout(function () {
                    $state.go('^');
                }, 150);
            }

            vm.program = program;
            if (!vm.program.providers) {
                vm.program.providers = [];
            }
            if (!vm.program.financials) {
                vm.program.financials = [];
            }

            $timeout(function () {
                $state.go('^');
            }, 150);

            vm.isSaving = false;
            vm.showProviders = true;
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = null;
        }

        function addInvestor() {
            var newFinancial = new ProgramFinancial();
            newFinancial.active = true;
            newFinancial.financialConfiguration = {sessionTerm: ''}
            vm.program.financials.push(newFinancial);
        }

        function addIdentificationNumbers() {
            vm.programIndentificationNumbers.push({identificationDefault: false});
        }

        function removeInvestor($index) {
            var item = vm.program.financials[$index];
            if (item.id) {
                ProgramFinancial.remove({id: item.id},
                    function () {
                        vm.program.financials = vm.program.financials.filter(function (_value, _index) {
                            return _index !== $index;
                        });
                    });
            } else {
                vm.program.financials = vm.program.financials.filter(function (_value, _index) {
                    return _index !== $index;
                });
            }


        }

        function removeIdentification($index) {
            vm.programIndentificationNumbers = vm.programIndentificationNumbers.filter(function (_value, _index) {
                return _index !== $index;
            });
        }

        function calculateProviderCost() {
            removeComma();
            vm.program.providerCost = parseFloat(vm.program.buyerRemuneration === undefined ? 0 : vm.program.buyerRemuneration) +
                parseFloat(vm.program.investorRemuneration === undefined ? 0 : vm.program.investorRemuneration) +
                parseFloat(vm.program.platformRemuneration === undefined ? 0 : vm.program.platformRemuneration);
        }

        function removeComma() {
            if (vm.program.platformRemuneration !== null && vm.program.platformRemuneration !== undefined) {
                vm.program.platformRemuneration = vm.program.platformRemuneration.toString().trim().replace(",", ".");
            }
            if (vm.program.investorRemuneration !== null && vm.program.investorRemuneration !== undefined) {
                vm.program.investorRemuneration = vm.program.investorRemuneration.toString().trim().replace(",", ".");
            }
            if (vm.program.buyerRemuneration !== null && vm.program.buyerRemuneration !== undefined) {
                vm.program.buyerRemuneration = vm.program.buyerRemuneration.toString().trim().replace(",", ".");
            }
        }

        vm.isDefaultProviderStatus = function ($provider, $status) {
            if (!vm.program || !vm.program.programConfiguration) {
                return false;
            }

            if ($provider.status) {
                return $provider.status === $status;
            }

            var config = vm.program.programConfiguration;

            if (!config.defaultProviderStatus) {
                return false;
            }

            return config.defaultProviderStatus === $status;
        };

        //Selectize Organization
        vm.organizations = [];

        loadAllOrganizations();

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
            }, function () {
            });
        }

        vm.loadOrganizations = function (query, callback) {
            Organization.getSimple({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result.content);
            }, function () {
            });
        };

        vm.organizationSelected = function ($id, $model) {
            vm.organization = $model;

            if ($id) {
                vm.program.organization = $model;
            } else {
                vm.program.organization = undefined;
            }
        };
        //----------

        vm.isFinancialAbsent = function ($index) {
            return !vm.program.financials[$index] || !vm.program.financials[$index].company;
        }
    }
})();
