(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ClientInvoiceController', ClientInvoiceController);

    function ClientInvoiceController($state, $window, ClientInvoice, Principal, ParseLinks, pagingParams) {
        var vm = this;

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.backToPanel = backToPanel;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 20;
        vm.currentSearch = '';

        loadAll(pagingParams.page);

        vm.downloadXml = function ($invoiceKey) {
            ClientInvoice.downloadFile({type: 'XML', invoiceKey: $invoiceKey}, function (result) {
                var fileName = "visualizacao_doc_auxiliar.xml";
                debugBase64(result, fileName);
            })
        };

        vm.downloadPdf = function ($invoiceKey) {
            ClientInvoice.downloadFile({type: 'PDF', invoiceKey: $invoiceKey}, function (result) {
                var fileName = "visualizacao_doc_auxiliar.pdf";
                debugBase64(result, fileName);
            })
        };

        function debugBase64(response, fileName) {
            var byteCharacters = atob(response.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }

        function loadAll(page) {
            ClientInvoice.query({
                isArray: false,
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                invoiceNumber: !isNaN(vm.currentSearch) ? vm.currentSearch : undefined
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.invoices = data.content;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            loadAll(vm.page);
        }

        function search() {
            loadAll(vm.page);
        }

        function backToPanel() {
            if (Principal.hasAnyAuthority(["ROLE_PROVIDER"])) {
                $state.go('provider-panel');
            } else {
                $state.go('client-panel');
            }

        }
    }
})();
