(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Tag', Tag);

    function Tag($resource) {
        var resourceUrl = 'api/tags/:id/:first';

        return $resource(resourceUrl, {id: '@id'}, {
            'create': {method: 'POST'},
            'update': {method: 'PUT'},
            'get': {method: 'GET'},
            'getAll': {method: 'GET'},
            'filter': {method: 'GET', params: {first: 'simple'}},
            'delete': {method: 'DELETE'}
        });
    }
})();
