/**
 * Created by rene on 9/15/17.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditVoucherController', CreditVoucherController);

    /** @ngInject **/
    function CreditVoucherController($state, $window, entity, previousState, CreditEstimate, GiroConstants) {
        var vm = this;

        vm.batch = entity;
        vm.currency = GiroConstants.Constants.currency;
        vm.clear = clear;
        vm.print = print;

        CreditEstimate.financial({id: entity.id}, function (result) {
            vm.estimate = result;
            vm.estimates = result.estimates;

            vm.programProvider = result.estimates[0].credit.programProvider;
            vm.account = result.estimates[0].credit.programProvider.company.accounts[0];

            if (!vm.account) {
                return;
            }
            if (vm.account.type === 'CHECKING_ACCOUNT') {
                vm.account.type = "Conta Corrente"
            } else {
                vm.account.type = "Poupança"
            }
        });

        function clear() {
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/close
            $window.close();
        }

        function print() {
            window.print();
        }
    }

})();
