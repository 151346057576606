(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('active', active);

    function active() {
        return function (value) {
            switch (value) {
                case 'true':
                    return "Sim";
                case true:
                    return "Sim";
                case 'false':
                    return "Não";
                case false:
                    return "Não";
            }
            return "Sim";
        }
    }
})();
