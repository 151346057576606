(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('imported-data-document', {
                parent: 'entity',
                url: '/imported-data-documents?page&sort&search&type',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/imported-data-document/imported-data-documents.html',
                        controller: 'ImportedDataDocumentsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            search: $stateParams.search,
                            type: $stateParams.type,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('imported-data-document');
                        return $translate.refresh();
                    }]
                }
            })
            .state('imported-data-document.new', {
                parent: 'imported-data-document',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/imported-data-document/imported-data-document.html',
                        controller: 'ImportedDataDocumentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    defaultOrganization: function (Organization) {
                        return Organization.getCurrent().$promise;
                    },
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'imported-data-document',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('imported-data-document');
                        return $translate.refresh();
                    }]
                }
            })
            .state('imported-data-document.results', {
                parent: 'entity',
                url: '/imported-data-documents/{id}/imported-data-results?page&sort&search&status&isDuplicate&message',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/imported-data-document/imported-data-results.html',
                        controller: 'ImportedDataResultsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ImportedDataDocument', function ($stateParams, ImportedDataDocument) {
                        return ImportedDataDocument.resume({id: $stateParams.id}).$promise;
                    }],
                    previousState: function ($state) {
                        var currentStateData = {
                            name: 'imported-data-document'
                        };
                        return currentStateData;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            search: $stateParams.search,
                            status: $stateParams.status,
                            isDuplicate: $stateParams.isDuplicate,
                            message: $stateParams.message,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],

                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('imported-data-result');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }
})();
