(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('NotificationsController', NotificationsController);

    function NotificationsController($scope, $state, $uibModal, Notification, Organization, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;


        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filter = {};
        vm.organizations = [];

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.loadOrganizations = loadOrganizations;
        vm.organizationSelected = organizationSelected;
        vm.detail = detail;

        loadAll(pagingParams.page);
        loadAllOrganizations();

        function loadAll(page) {

            Notification.getAll({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filter.search,
                type: vm.filter.type,
                organization: vm.filter.organization ? vm.filter.organization.id : undefined
            }, onSuccess, onError);


            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.notifications = data.content;
                vm.page = page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        }

        function search() {
            loadAll(vm.page);
        }

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
            }, function () {
            });
        }

        function loadOrganizations(query, callback) {
            Organization.getSimple({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result.content);
            }, function () {
            });
        }

        function organizationSelected($id, $model) {
            vm.organization = $model;

            if ($id) {
                vm.filter.organization = $model;
                vm.organization = $model;
            } else {
                vm.filter.organization = undefined;
                vm.organization = undefined;
            }
        }

        function detail(notification) {
            $uibModal.open({
                templateUrl: 'app/entities/notification/notification-detail-dialog.html',
                controller: 'NotificationDetailDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: ['Notification', function () {
                        return Notification.get({id: notification.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('notification');
                        return $translate.refresh();
                    }]
                }
            });
        }
    }
})();
