(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('anticipation-report', {
                parent: 'entity',
                url: '/anticipation-report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/reports/anticipation-report.html',
                        controller: 'AnticipationReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('anticipation-report');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dashboard/anticipation-report', {
                parent: 'webapp-redirect',
                url: '/dashboard/anticipation-report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/reports/anticipation-report.html',
                        controller: 'AnticipationReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('anticipation-report');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
