(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BatchVisualizerConfirmController', BatchVisualizerConfirmController);

    /** @ngInject **/
    function BatchVisualizerConfirmController($translate, $uibModalInstance, $scope, entity, BatchOperation, CreditEstimate, $timeout, noty, GiroConstants) {
        var vm = this;

        vm.batch = entity;
        vm.currency = GiroConstants.Constants.currency;
        vm.clear = clear;
        vm.confirm = confirm;

        vm.account = undefined;

        vm.isSaving = false;

        if (entity.status !== 'WAIT_CONFIRMATION') {
            noty.error($translate.instant('message.batch.credit.expiredlink'));
            $timeout(function () {
                $uibModalInstance.dismiss('cancel');
            }, 500)
        }

        CreditEstimate.financial({id: entity.id}, function (result) {
            vm.estimate = result;
            vm.estimates = result.estimates;
            vm.account = result.estimates[0].credit.programProvider.company.accounts[0];

            if (vm.account.type === 'CHECKING_ACCOUNT') {
                vm.account.type = "Conta Corrente"
            } else {
                vm.account.type = "Poupança"
            }
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            vm.isSaving = true;

            BatchOperation
                .accept({id: vm.batch.id}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            noty.success($translate.instant('message.batch.credit.confirm'));
            $timeout(function () {
                if(result.creditFailNumber && result.creditFailNumber > 0) {
                    noty.alert(result.creditFailNumber + $translate.instant('message.batch.credit.fail.reconciliate'));
                }

                $uibModalInstance.close(result);
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError(e) {
            vm.isSaving = false;
            $uibModalInstance.close();
        }
    }

})();
