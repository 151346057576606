(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('authority', authority);

    function authority() {
        return function (value) {
            switch (value) {
                case 'ROLE_ADMIN':
                    return "Administrador";
                case 'ROLE_USER':
                    return "Usuário do sistema";
            }
            return value;
        }
    }
})();
