(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('BatchOperation', BatchOperation);

    function BatchOperation($resource) {
        var resourceUrl = 'api/batch-credits/:id/:first/:second/:third';
        return $resource(resourceUrl, {id: '@id', second: '@second'}, {
            request: {
                method: 'POST',
                params: {first: 'request'}
            },
            accept: {
                method: 'POST',
                params: {first: 'accept'}
            },
            acceptBatches: {
                method: 'POST',
                params: {first: 'accept', second: 'batch'}
            },
            cancel: {
                method: 'POST',
                params: {first: 'cancel'}
            },
            reject: {
                method: 'POST',
                params: {first: 'reject'}
            },
            rejectBatches: {
                method: 'POST',
                params: {first: 'reject', second: 'batch'}
            },
            remove: {
                method: 'PUT',
                params: {first: 'remove'}
            },
            removeConfirmedCredit: {
                method: 'PUT',
                params: {first: 'credit', third: 'remove'}
            },
            removeBatchCredits: {
                method: 'PUT',
                params: {first: 'credits', second: 'remove'}
            }
        });
    }
})();
