(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('HomeAdminController', HomeAdminController);

    /** @ngInject **/
    function HomeAdminController ($scope, Principal, LoginService, $state) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.login = LoginService.open;

        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.authenticated;
            });
        }

        function register () {
            $state.go('register');
        }

    }
})();
