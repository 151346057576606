(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .constant('SubscriptionCode', {
            castrolanda: {
                1: 'CASTROLANDA - MATRIZ',
                2: 'CASTROLANDA - PIRAI',
                4: 'CASTROLANDA - LOJA AGROPECUARIA',
                5: 'CASTROLANDA - UNID. MARRAS REPR.SUINOS',
                7: 'CASTROLANDA - PONTA GROSSA',
                8: 'CASTROLANDA - UNIDADE DE BATATA',
                3: 'CENTRAL DE COLETA DE SEMEN DE SUINOS',
                6: 'CASTROLANDA - CURIUVA',
                9: 'CASTROLANDA - PIRAI II',
                11: 'CASTROLANDA - ITABERA',
                10: 'CASTROLANDA - UNIDADE DE BATATA FRITA',
                12: 'CASTROLANDA - UNIDADE LOUVEIRA',
                13: 'CASTROLANDA - UPL',
                14: 'USINA DE LEITE PR',
                15: 'CASTROLANDA - UNIDADE DE VENTANIA',
                16: 'CASTROLANDA - UNIDADE BENEF. FEIJAO',
                17: 'CASTROLANDA - UNIDADE INDUSTRIAL CARNES',
                19: 'CASTROLANDA - LAVADOR DE BATATAS',
                20: 'CASTROLANDA - USINA DE LEITE SP',
                18: 'CASTROLANDA - ITABERA II',
                21: 'CASTROLANDA - ANGATUBA SP',
                22: 'CASTROLANDA - LATORRE',
                24: 'CASTROLANDA - LOJA ITAPETININGA',
                27: 'CASTROLANDA - UNIDADE BIER HOFF',
                28: 'CASTROLANDA- UPL PIRAI DO SUL',
                30: 'DISTRIBUIDORA CASTROLANDA - LOJA',
                31: 'CENTRO DISTRIBUIÇAO - ITAPEVA',
                32: 'COMERCIALIZADORA DE ENERGIA',
                33: 'GERADORA DE ENERGIA',
                23: 'CASTROLANDA - RIO DE JANEIRO',
                26: 'CASTROLANDA - UNID. DIST.CARNES CURITIBA',
                25: 'CASTROLANDA - LOJA PARANAPANEMA',
                29: 'UNIDADE ALEGRA - SP'
            }
        });
})();
