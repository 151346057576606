(function () {
    'use strict';

    angular.module('giroApiApp')
        .controller('CompanyBankAccountManageController', CompanyBankAccountManageController);

    function CompanyBankAccountManageController($state, $translate, $timeout, $uibModal, $stateParams, entity, Bank, BankAccount, noty) {
        var vm = this;
        vm.company = entity;
        vm.isWebapp = $stateParams.isWebapp;
        vm.account = undefined;

        vm.banks = [];
        vm.bank = undefined;

        vm.approved = undefined;

        if (vm.company.bankAccount) {
            vm.account = vm.company.bankAccount;
            if (vm.account.bank) {
                vm.bank = vm.account.bank;
                vm.banks.push(vm.bank);
                vm.banks.forEach(function (it) {
                    it.presentationLabel = it.number + ' - ' + it.name;
                });
            }
            if (vm.account.approved) {
                vm.approved = vm.account.approved;
            }
        } else {
            vm.account = {type: "CHECKING_ACCOUNT"};
        }


        vm.clear = clear;
        vm.save = save;
        vm.bankSelected = bankSelected;
        vm.loadBanks = loadBanks;

        function clear() {
            if (vm.isWebapp) return;

            $state.go('home');
        }

        function save() {
            $uibModal.open({
                templateUrl: 'app/entities/company-bank-account/company-bank-account-request-dialog.html',
                controller: 'CompanyBankAccountRequestController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                vm.isSaving = true;
                vm.company.bankAccount = vm.account;
                vm.company.bankAccount.bankId = vm.bank.id;
                BankAccount.updateCompanyBankAccount({id: vm.company.id}, vm.company, onSaveSuccess, onSaveError);
            });
        }

        function onSaveSuccess() {
            noty.success($translate.instant('message.alert.company.bankAccount.update'));
            $timeout(function () {
                if (vm.isWebapp) return;
                $state.go('home');
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = false;
        }

        function bankSelected($id, $bank) {
            vm.bank = $bank;
        }

        function loadBanks(query, callback) {
            Bank.querySimple({
                query: query
            }, function (result) {
                vm.banks = vm.banks.concat(result.content);
                vm.banks.forEach(function (it) {
                    it.presentationLabel = it.number + ' - ' + it.name;
                });
                callback(vm.banks);
            });
        }

    }
})();
