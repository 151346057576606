/**
 * Created by rene on 1/3/18.
 */
(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('EmailAddress', EmailAddress);

    function EmailAddress ($resource) {
        var resourceUrl = 'api/email-addresses/:id/:action/:entity';

        return $resource(resourceUrl, {id : '@id'}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getInviteProviderEmailBody': {
                method: 'GET',
                params: {action: 'invite-provider'},
                transformResponse: function (data) {
                    return { content: data };
                }
            },
            'sendEmailAddress': {
                method: 'POST',
                params: {action: 'send'}
            },
            'getEmailAddressWithInvites': {
                method: 'GET',
                params: {action: 'invites'}
            },
            'getCustomerEmailAddressWithInvites': {
                method: 'GET',
                params: {action: 'customer', entity: 'invites'}
            }
        });
    }
})();
