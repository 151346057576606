(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BankDeleteController',BankDeleteController);

    BankDeleteController.$inject = ['$translate', '$timeout', '$uibModalInstance', 'entity', 'Bank', 'noty'];

    function BankDeleteController($translate, $timeout, $uibModalInstance, entity, Bank, noty) {
        var vm = this;

        vm.bank = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Bank.delete({id: id},
                function () {
                    noty.alert($translate.instant('message.alert.bank.delete'));
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    },150);
                });
        }
    }
})();
