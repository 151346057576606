(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('PersonDialogController', PersonDialogController);

    /** @ngInject **/
    function PersonDialogController($translate, $timeout, $scope, $state, Bank, entity, pagingParams,
                                    Person, Company, $rootScope, TeamMember, User,
                                    LoginService, BankAccount, noty, StringUtils) {
        var vm = this;
        vm.person = entity;
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.isSettings = pagingParams.isSettings;
        vm.authorities = ['ROLE_ADMIN', 'ROLE_USER'];
        vm.selection = ['ROLE_ADMIN', 'ROLE_USER'];
        vm.banks = [];
        vm.companiesMember = [];
        vm.companies = [];
        vm.toggleSelection = toggleSelection;
        vm.clear = clear;
        vm.save = save;
        vm.addAddress = addAddress;
        vm.addPhone = addPhone;
        vm.addAccount = addAccount;
        vm.addCompany = addCompany;
        vm.companySelected = companySelected;
        vm.loadCompanies = loadCompanies;
        vm.loadBanks = loadBanks;
        vm.bankSelected = bankSelected;
        vm.removePhone = removePhone;
        vm.removeAddress = removeAddress;
        vm.resendActivationEmail = resendActivationEmail;
        vm.removeCompany = removeCompany;

        Company.search(function (result) {
            vm.companies = vm.companies.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
            $timeout(function () {
                $rootScope.$broadcast('update.company');
            }, 250);
        });

        if (vm.person.id && vm.person.id !== null) {
            vm.addresses = vm.person.addresses;
            vm.phones = vm.person.phones;
            vm.selection = vm.person.authorities;
            if (vm.person.companiesMember) {
                angular.forEach(vm.person.companiesMember, function ($i) {
                    vm.companiesMember.push($i);
                    vm.companies.push(StringUtils.formatToNameAndIdentificationLabel($i.company));
                });
                vm.accounts = vm.person.accounts;
            }
            if (vm.person.accounts) {
                angular.forEach(vm.person.accounts, function ($i) {
                    vm.banks.push($i.bank);
                });
                vm.accounts = vm.person.accounts;
            }
        } else {
            vm.person.type = "INDIVIDUAL";
            vm.phones = [];
            vm.addresses = [];
            vm.accounts = [];
            vm.companiesMember = [];
        }

        function clear() {
            $state.go('^');
        }

        function save() {
            if (vm.person.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERRO';
            } else {
                vm.person.phones = vm.phones;
                angular.forEach(vm.addresses, function ($a, $index) {
                    if (angular.equals($a, {})) {
                        vm.addresses.splice($index, 1);
                    }
                });

                angular.forEach(vm.accounts, function ($a, $index) {
                    if (angular.equals($a, {})) {
                        vm.accounts.splice($index, 1);
                    }
                });

                vm.person.companiesMember = vm.companiesMember;
                vm.person.authorities = vm.selection;
                vm.isSaving = true;
                if (vm.person.id !== null) {
                    Person.update(vm.person, onSaveSuccess, onSaveError);
                } else {
                    Person.save(vm.person, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('giroApiApp:personUpdate', result);

            $timeout(function () {
                if (vm.isSettings) {
                    $state.go('home');
                } else {
                    $state.go('^');
                }
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = null;
        }

        function resendActivationEmail() {
            Person.resendActivationEmail({id: vm.person.id}, function (result) {
                noty.success($translate.instant('message.giroApp.person.emailSent'))
            })
        }

        function addAddress() {
            vm.addresses.push({});
        }

        function addPhone() {
            vm.phones.push({});
        }

        function removeAddress($index) {
            vm.addresses.splice($index, 1);
        }

        function removePhone($index) {
            vm.phones.splice($index, 1);
        }

        function removeCompany($index) {
            // vm.companiesMember.splice($index, 1);
            vm.companiesMember = vm.companiesMember.filter(function (value, index) {
                return index !== $index;
            });
        }

        function addAccount() {
            vm.accounts.push(new BankAccount());
        }

        function toggleSelection(authority) {
            if (vm.selection === undefined) {
                vm.selection = [];
            }
            var idx = vm.selection.indexOf(authority);

            if (idx > -1) {
                vm.selection.splice(idx, 1);
            } else {
                vm.selection.push(authority);
            }
        }

        function bankSelected(id, $bank, $account) {
            $account.bank = $bank;
        }

        function loadBanks(query, callback) {
            Bank.query({
                query: query
            }, function (result) {
                vm.banks = vm.banks.concat(result);
                callback(result);
            }, function () {
            });
        }

        function addCompany() {
            vm.companiesMember.push(new TeamMember());
        }

        function loadCompanies(searchText, callback) {
            Company.search({q: searchText, limit: 50}, function (result) {
                vm.companies = vm.companies.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
                callback(vm.companies);
            }, function () {
                callback(vm.companies);
            });
        }

        function companySelected(id, $model, $index) {
            vm.companiesMember[$index].company = $model;
        }

        vm.setActive = function (id) {
            User.changeStatus({id: id}, function () {
                vm.person.activated = !vm.person.activated;
            });
        }

    }
})();
