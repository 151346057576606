(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AnticipateAuthorizeDialogController', AnticipateAuthorizeDialogController);

    function AnticipateAuthorizeDialogController($translate, $timeout, $uibModalInstance, entity, CompanyProvider, noty) {
        var vm = this;

        vm.companiesList = entity;
        vm.clear = clear;


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.authorizeAnticipate = function () {
            var list = {
                ids: vm.companiesList
                    .map(function (company) {
                        return company.id
                    })
            };

            CompanyProvider.authorizeAnticipate(list, function () {
                noty.success($translate.instant('message.alert.anticipate.authorize'));

                $timeout(function () {
                    $uibModalInstance.close(true);
                }, 150);
            });
        };
    }
})();
