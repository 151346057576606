(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ExternalCreditsController', ExternalCreditsController);

    /** @ngInject **/
    function ExternalCreditsController($stateParams, $rootScope, $timeout,
                                       $state, ExternalCredit, ParseLinks, paginationConstants, GiroConstants,
                                       pagingParams, Program, ProgramBuyer, ProgramProvider, StringUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;


        vm.programs = [];
        vm.program = undefined;
        vm.programId = $stateParams.programId;
        vm.searchPrograms = searchPrograms;
        vm.selectProgram = selectProgram;

        vm.providers = [];
        vm.provider = undefined;
        vm.providerId = $stateParams.providerId;
        vm.searchProviders = searchProviders;
        vm.selectProvider = selectProvider;
        vm.search = search;

        loadProgram();
        loadProvider();

        if (vm.programId && vm.providerId) {
            search(vm.page);
        }

        function loadProgram() {
            if (vm.programId) {
                Program.getSimple({id: $stateParams.programId}, function (result) {
                    vm.programs.push(result);
                    vm.program = result;
                    $timeout(function () {
                        $rootScope.$broadcast('$giro.reloadPrograms');
                    }, 150);
                });
            } else {
                Program.filterToExternalCreditByCompanyDefault(function (result) {
                    vm.programs = result.content;
                });
            }
        }


        function searchPrograms(searchText, callback) {
            Program.filterToExternalCreditByCompanyDefault({q: searchText}, function (result) {
                vm.programs = result.content;
                callback(result);
            });
        }

        function selectProgram(id, model) {
            vm.program = model;
            vm.programId = id ? id : undefined;
            if (vm.programId) {
                ProgramProvider.searchByProgramSimple({param: vm.programId}, function (result) {
                    vm.providers = StringUtils.formatListToNameAndIdentificationLabel(result.content);
                });
            }
        }


        function loadProvider() {
            if (vm.programId) {
                ProgramProvider.searchByProgramSimple({param: vm.programId}, function (result) {
                    vm.providers = StringUtils.formatListToNameAndIdentificationLabel(result.content);
                    if (vm.providerId) {
                        var provider = vm.providers.find(function (it) {
                            return it.id === vm.providerId
                        });
                        if (provider) {
                            vm.provider = provider;
                            $timeout(function () {
                                $rootScope.$broadcast('$giro.reloadProviders');
                            }, 150);
                        } else {
                            ProgramProvider.searchByProgramSimple({id: vm.providerId}, function ($result) {
                                vm.providers.push(StringUtils.formatToNameAndIdentificationLabel($result));
                                vm.provider = StringUtils.formatToNameAndIdentificationLabel($result);
                                $timeout(function () {
                                    $rootScope.$broadcast('$giro.reloadProviders');
                                }, 150);
                            });
                        }

                    }
                });
            }

        }

        function searchProviders(searchText, callback) {
            if (!vm.programId) return;
            ProgramProvider.searchByProgramSimple({
                param: vm.programId,
                q: searchText
            }, function (result) {
                vm.providers = StringUtils.formatListToNameAndIdentificationLabel(result.content);
                callback(result.content);
            });
        }


        function selectProvider(id, model) {
            vm.provider = model;
            vm.providerId = id ? id : undefined;
        }

        // -----------

        function loadAll(page) {
            ExternalCredit.list({
                programId: vm.programId,
                providerId: vm.providerId,
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.credits = data.content;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                programId: vm.programId,
                providerId: vm.providerId
            });
        }

        function search() {
            $stateParams.programId = vm.programId;
            $stateParams.providerId = vm.providerId;
            loadAll(vm.page);
        };

    }
})();
