(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);


    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('person', {
                parent: 'entity',
                url: '/person?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN']
                    // pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/person/people.html',
                        controller: 'PersonController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('person-detail', {
                parent: 'entity',
                url: '/person/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                    // pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/person/person-detail.html',
                        controller: 'PersonDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function ($stateParams, Person) {
                        return Person.get({id: $stateParams.id}).$promise;
                    },
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('person-detail');
                        return $translate.refresh();
                    }]
                }
            })
            .state('person-detail.edit', {
                parent: 'person-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/person/person-dialog.html',
                        controller: 'PersonDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function (Person) {
                                return Person.get({id: $stateParams.id}).$promise;
                            },
                            pagingParams: function ($stateParams, PaginationUtil) {
                                return {
                                    isSettings: false
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('person-detail');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }
            })
            .state('person.new', {
                parent: 'person',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/person/person-dialog.html',
                        controller: 'PersonDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            type: null,
                            identification: null,
                            id: null,
                            phones: [],
                            accounts: []
                        };
                    },
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            isSettings: false
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('team-member');
                        $translatePartialLoader.addPart('person-detail');
                        return $translate.refresh();
                    }],
                    previousState: function ($state) {
                        return {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }
                }
            })
            .state('person.edit', {
                parent: 'person',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/person/person-dialog.html',
                        controller: 'PersonDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function ($q, Person, $stateParams) {
                        var defer = $q.defer();
                        Person.get({id: $stateParams.id}, function (result) {
                            defer.resolve(result);
                        }, function (e) {
                            defer.reject(e);
                        });
                        return defer.promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('team-member');
                        $translatePartialLoader.addPart('person-detail');
                        return $translate.refresh();
                    }],
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            isSettings: false
                        };
                    }
                },
                previousState: function ($state) {
                    return {
                        name: $state.current.name || 'person',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                }
            })
        ;
    }

})();
