(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProviderProgramsController', ProviderProgramsController);

    function ProviderProgramsController($state, ProgramProvider, GiroConstants) {
        var vm = this;
        vm.currency = GiroConstants.Constants.currency;

        vm.goToCreditVizualizer = goToCreditVizualizer;

        loadData();

        function goToCreditVizualizer(programProviderId, programId) {
            $state.go('credit-detail-provider', {
                providerId: programProviderId,
                programId: programId
            });
        }

        function loadData() {
            ProgramProvider.resume(function (result) {
                vm.total = result.total;
                vm.providerPrograms = result.programs;
            });
        }
    }
})();
