(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('BankAccount', BankAccount);

    function BankAccount($resource) {
        var resourceUrl = 'api/bank-accounts/:id/:first/:second/:third';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'getBankAccountsByProvider': {
                method: 'GET',
                params: {first: 'program-provider'},
                isArray: true
            },
            'update': {method: 'PUT'},
            'updateCompanyBankAccount': {
                method: 'PUT',
                params: {second: 'company'}
            },
            'approveCompanyBankAccount': {
                method: 'POST',
                params: {first: 'companies', third: 'approve'}
            }
        });
    }
})();
