(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Program', Program);

    Program.$inject = ['$resource'];

    function Program($resource) {
        var resourceUrl = 'api/programs/:id/:action/:first/:second';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'},
            'querySimple': {method: 'GET', params: {action: 'simple'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getSimple': {
                method: 'GET',
                params: {action: 'simple'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'filterToExternalCreditByCompanyDefault': {
                method: 'GET',
                params: {action: 'external-credit', first: 'default', second: 'simple'}
            }
        });
    }

    angular
        .module('giroApiApp')
        .factory('myPrograms', myPrograms);

    myPrograms.$inject = ['$resource'];

    function myPrograms($resource) {
        var resourceUrl = 'api/programs/me';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }

})();
