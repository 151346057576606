(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('HomeAnticipationWishController', HomeAnticipationWishController);

    /** @ngInject **/
    function HomeAnticipationWishController($uibModalInstance, CompanyEvent, Principal) {
        var vm = this;
        vm.sendWish = send;

        function send() {
            console.log("ABC");
            var company = Principal.getDefaultCompany();
            CompanyEvent.wishAnticipateProgram({"company": company}, function(result) {
                $uibModalInstance.close(true);

            }, function(error) {
                $uibModalInstance.close(true);
            });
            return 'https://giro.tech/fornecedores/';
        }
    }
})();
