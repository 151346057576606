(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDocumentAcceptController', ImportedDocumentAcceptController)
        .controller('ImportedDocumentRejectController', ImportedDocumentRejectController);


    /** @ngInject **/
    function ImportedDocumentAcceptController($uibModalInstance, entity, ImportedDocument) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm(id) {
            ImportedDocument.accept({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }


    /** @ngInject **/
    function ImportedDocumentRejectController($uibModalInstance, entity, ImportedDocument) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm(id) {
            ImportedDocument.reject({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
