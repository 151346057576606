(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SendInviteProviderEmailDialogController', SendInviteProviderEmailDialogController);

    SendInviteProviderEmailDialogController.$inject = ['$translate', '$state', 'entity', 'body', 'previousState', 'noty', 'EmailAddress'];

    function SendInviteProviderEmailDialogController($translate, $state, entity, body, previousState, noty, EmailAddress) {
        var vm = this;

        vm.emailaddress = entity;
        vm.body = body.content;

        vm.clear = function () {
            $state.go(previousState.name, previousState.params);
        };

        vm.sendEmail = function () {
            EmailAddress.sendEmailAddress({id: vm.emailaddress.id}, vm.body, function (result) {
                noty.success($translate.instant('message.alert.emailInviteProvider.send'));
                $state.go(previousState.name, previousState.params);
            })
        }
    }
})();
