(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('personType', personType)
        .filter('profileType', profileType);

    function personType() {
        return function (value) {
            switch (value) {
                case 'INDIVIDUAL':
                    return "Individual";
                case 'CORPORATION':
                    return "Corporativo";
            }
            return value;
        }
    }

    function profileType() {
        return function (value) {
            switch (value) {
                case 'OWNER':
                    return "Proprietário";
                case 'LEGAL_RESPONSIBLE':
                    return "Responsável Legal";
                case 'OPERATOR':
                    return "Operador";
            }
            return value;
        }
    }
})();
