(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ImportedDocument', ImportedDocument);

    ImportedDocument.$inject = ['$resource', 'DateUtils'];

    function ImportedDocument($resource, DateUtils) {
        var resourceUrl = 'api/imported-documents/:id/:action';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET', isArray: true},
            'accept': {method: 'PUT', params: {action: 'accept'}},
            'reject': {method: 'PUT', params: {action: 'reject'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.order.createdDate = DateUtils.convertDateTimeFromServer(data.order.createdDate);
                        data.order.dueDate = DateUtils.convertLocalDateFromServer(data.order.dueDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'queryBuyer': {method: 'GET', params: {action: 'program-buyer'}}
        });
    }


})();
