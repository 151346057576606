(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accept-terms', {
                parent: 'entity',
                url: '/accept-terms?&callback',
                data: {
                    authorities: []
                    // pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                params: {token: undefined},
                views: {
                    'content@': {
                        templateUrl: 'app/account/accept-terms/accept-terms.html',
                        controller: 'AcceptTermsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('accept-terms');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accept-terms-webview', {
                    url: '/accept-terms-webview',
                    views: {
                        'content@': {
                            templateUrl: 'app/account/accept-terms/accept-terms-webview.html'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accept-terms');
                            return $translate.refresh();
                        }]
                    }
                }
            );
    }
})();
