(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('NotificationManageController', NotificationManageController);

    /** @ngInject **/
    function NotificationManageController($state, $stateParams, previousState, Notification, Organization, entity) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.notification = entity;
        vm.datePickerOpenStatus = {};
        vm.organizations = [];
        vm.datePickerOpenStatus.date = false;


        vm.loadOrganizations = loadOrganizations;
        vm.organizationSelected = organizationSelected;
        vm.openCalendar = openCalendar;


        if (vm.notification.id && vm.notification.dueDate) {
            vm.dueDate = new Date(vm.notification.dueDate);
        }

        loadAllOrganizations();

        function clear() {
            $state.go(previousState.name || '^', previousState.params);
        }

        function save(form) {
            if (form.editForm.$invalid || !vm.organization) {
                return;
            }

            vm.isSaving = true;
            vm.notification.dueDate = vm.dueDate;
            vm.notification.organizationId = vm.organization.id;

            if (vm.notification.id) {
                Notification.update(vm.notification, onSaveSuccess, onSaveError);
            } else {
                Notification.create(vm.notification, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess(result) {
                $state.go(previousState.name || '^', $stateParams);
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        }

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
                if (vm.notification && vm.notification.organization) {
                    vm.organization = vm.organizations.find(function (element) {
                        return element.organizationKey === vm.notification.organization.organizationKey
                    })
                }

                if (vm.organizations.length === 1) {
                    vm.notification.organization = vm.organizations[0];
                    vm.organization = vm.organizations[0];
                }

            }, function () {
            });
        }

        function loadOrganizations(query, callback) {
            Organization.getSimple({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result.content);
            }, function () {
            });
        }

        function organizationSelected($id, $model) {
            vm.organization = $model;

            if ($id) {
                vm.notification.organization = $model;
                vm.organization = $model;
            } else {
                vm.notification.organization = undefined;
                vm.organization = undefined;
            }
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
