(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDocumentDialogController', ImportedDocumentDialogController);

    /** @ngInject **/
    function ImportedDocumentDialogController($translate, $timeout, $state, $rootScope, entity, ImportedDocument, Program, noty) {
        var vm = this;

        vm.document = entity;
        vm.datePickerOpenStatus = {};

        vm.programs = [];

        console.log(vm.document.order.amount);
        if (vm.document.order.amount) {
            vm.document.order.amount = vm.document.order.amount / 100;
        }
        console.log(vm.document.order.amount);

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;

        if (entity.program) {
            vm.programs.push(entity.program);
        }

        vm.programSelected = function ($id, $model) {
            vm.document.program = $model;
        };

        vm.loadPrograms = function (q, callback) {
            Program.query({q: name, limit: 50}, function (result) {
                vm.programs = vm.programs.concat(result.content);
                $rootScope.$broadcast('update.program');
                callback(vm.programs);
            }, function () {
                callback(vm.programs);
            });
        };

        function clear() {
            $state.go('^');
        }

        function save() {
            vm.isSaving = true;
            var entity = angular.copy(vm.document);
            entity.order.amount = vm.document.order.amount * 100;

            if (vm.document.id !== undefined) {
                ImportedDocument.update(entity, onSaveSuccess, onSaveError);
            } else {
                ImportedDocument.save(entity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            if(vm.document.id !== null){
                noty.success($translate.instant('message.alert.importeddocument.update'))
            } else {
                noty.success($translate.instant('message.alert.importeddocument.save'))
            }

            $timeout(function () {
                $state.go('^');
            },150);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.dueDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
