(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('RedirectController', RedirectController);

    /** @ngInject **/
    function RedirectController($window) {
        var vm = this;

        var landingUrl = $window.location.protocol + "//" + $window.location.host;
        console.log(landingUrl);
        $window.location.href = landingUrl;
    }
})();
