(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('provider-panel', {
                parent: 'entity',
                url: '/provider-panel?providerId&programId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER', 'ROLE_PROVIDER_CONSULT']
                },
                views: {
                    'content@':
                        {
                            templateUrl: 'app/entities/provider-panel/provider-panel.html',
                            controller: 'ProviderPanelController',
                            controllerAs: 'vm'
                        },
                    'clientPanel@':
                        {
                            templateUrl: 'app/entities/client-panel/client-panel.html',
                            controller: 'ClientPanelController',
                            controllerAs: 'vm'
                        }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('provider-panel');
                        $translatePartialLoader.addPart('client-panel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('provider-programs', {
                parent: 'entity',
                url: '/provider-programs',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER']
                },
                views: {
                    'content@':
                        {
                            templateUrl: 'app/entities/provider-panel/provider-programs.html',
                            controller: 'ProviderProgramsController',
                            controllerAs: 'vm'
                        }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('provider-programs');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invoices', {
                parent: 'entity',
                url: '/invoice?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER', 'ROLE_PROVIDER_CONSULT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/provider-panel/invoices.html',
                        controller: 'InvoiceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'emissionDate,desc',
                        squash: true
                    },
                    search: undefined
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoice');
                        return $translate.refresh();
                    }]
                }
            })
            .state('provider-titles', {
                parent: 'entity',
                url: '/provider-titles?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER', 'ROLE_PROVIDER_CONSULT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/provider-panel/provider-titles.html',
                        controller: 'ProviderTitleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: undefined
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('provider-title-invoice');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
