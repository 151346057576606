(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('credit', {
                parent: 'entity',
                url: '/credit?page&sort&programId&providerId&status&nfNumber',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/credits.html',
                        controller: 'CreditController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            programId: $stateParams.programId,
                            providerId: $stateParams.providerId,
                            status: $stateParams.status,
                            nfNumber: $stateParams.nfNumber
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credits');
                        $translatePartialLoader.addPart('credit-status');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-review', {
                parent: 'entity',
                url: '/credit-review?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/credit-review.html',
                        controller: 'CreditReviewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-review');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail', {
                parent: 'entity',
                url: '/credit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER', 'ROLE_FINANCIAL', 'ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/credit-detail.html',
                        controller: 'CreditDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Credit', function ($stateParams, Credit) {
                        return Credit.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'credit',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-detail');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail.edit', {
                parent: 'credit-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit/credit-dialog.html',
                        controller: 'CreditDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Credit', function (Credit) {
                                return Credit.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name || 'credit',
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit.new', {
                parent: 'credit',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/credit-dialog.html',
                        controller: 'CreditDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {};
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-dialog');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'credit',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('credit.edit', {
                parent: 'credit',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/credit-dialog.html',
                        controller: 'CreditDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Credit', '$stateParams', function (Credit, $stateParams) {
                        return Credit.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-dialog');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'credit',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('credit.delete', {
                parent: 'credit',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit/credit-delete-dialog.html',
                        controller: 'CreditDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Credit', function (Credit) {
                                return Credit.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('credit-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit', null, {reload: 'credit'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit-review.accept', {
                parent: 'credit-review',
                url: '/{id}/accept',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit/credit-review-accept-dialog.html',
                        controller: 'CreditReviewAcceptController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CreditReview', function (CreditReview) {
                                return CreditReview.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('credit-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-review', null, {reload: 'credit-review'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit-review.reject', {
                parent: 'credit-review',
                url: '/{id}/reject',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit/credit-review-reject-dialog.html',
                        controller: 'CreditReviewRejectController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CreditReview', function (CreditReview) {
                                return CreditReview.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('credit-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-review', null, {reload: 'credit-review'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('external-credit', {
                parent: 'entity',
                url: '/external-credit?page&sort&search&programId&providerId',
                data: {
                    authorities: ['ROLE_CREATE_CREDIT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/external/external-credit.html',
                        controller: 'ExternalCreditsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credits');
                        $translatePartialLoader.addPart('credit-status');
                        return $translate.refresh();
                    }]
                }
            })
            .state('external-credit.new', {
                parent: 'external-credit',
                url: '/new',
                data: {
                    authorities: ['ROLE_CREATE_CREDIT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/external/external-credit-dialog.html',
                        controller: 'ExternalCreditsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {};
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-dialog');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'external-credits',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('external-credit.edit', {
                parent: 'external-credit',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_CREATE_CREDIT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/external/external-credit-dialog.html',
                        controller: 'ExternalCreditsDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['ExternalCredit', '$stateParams', function (ExternalCredit, $stateParams) {
                        return ExternalCredit.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-dialog');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'external-credit',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('external-credit-detail', {
                parent: 'entity',
                url: '/external-credit/{id}',
                data: {
                    authorities: ['ROLE_CREATE_CREDIT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit/external/external-credit-detail.html',
                        controller: 'ExternalCreditDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'ExternalCredit', function ($stateParams, ExternalCredit) {
                        return ExternalCredit.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'external-credit',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-detail');
                        return $translate.refresh();
                    }]
                }
            })

        ;
    }

})();
