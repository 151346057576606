(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('documentStatus', documentStatus);

    function documentStatus() {
        return function (value) {
            switch (value) {
                case 'NEW':
                    return "Novo";
                case 'IMPORTED':
                    return "Importado";
                case 'PROCESSED':
                    return "Processado";
                case 'ERROR':
                    return "Erro";
                case 'DENIED':
                    return "Negado";
                case 'NEEDS_REVIEW':
                    return "Pendente de Aprovação";
            }
            return value;
        }
    }

})();
