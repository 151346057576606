(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Credit', Credit);


    /** @ngInject **/
    function Credit($resource, DateUtils) {
        var resourceUrl = 'api/credits/:param/:id/:program/:action';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'},
            'allForProvider': {method: 'GET', isArray: true, params: {action: 'providers'}},
            'buyer': {method: 'GET', params: {program: 'program', action: 'buyer'}},
            'provider': {method: 'GET', params: {program: 'program', action: 'provider'}},
            'allProvider': {method: 'GET', params: {action: 'providers'}},
            'financial': {method: 'GET', isArray: true, params: {program: 'program', action: 'financial'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.paidDate = DateUtils.convertDateTimeFromServer(data.paidDate);
                        data.requestedDate = DateUtils.convertDateTimeFromServer(data.requestedDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'anticipation': {
                method: 'GET',
                params: {param: 'program-provider', action: 'anticipations'}
            },
            'anticipationByMonth': {
                method: 'GET',
                params: {param: 'program-provider', program: 'anticipations', action: 'month'}
            }
        });
    }
})();
