(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('City', City);

    City.$inject = ['$resource'];

    function City($resource) {
        var resourceUrl = 'api/cities/:id/:first/:second';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', params: {first: 'simple'}}
        });
    }
})();
