(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BankAccountDetailController', BankAccountDetailController);

    BankAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BankAccount', 'Bank'];

    function BankAccountDetailController($scope, $rootScope, $stateParams, previousState, entity, BankAccount, Bank) {
        var vm = this;

        vm.bankAccount = entity;
        vm.previousState = previousState.name;

        if (vm.bankAccount && vm.bankAccount !== null) {
            vm.banks = [vm.bankAccount.bank];
        } else {
            vm.banks = [];
        }

        vm.loadBanks = loadBanks;

        function loadBanks(query, callback) {
            Bank.query({
                query: query
            }, function (result) {
                vm.banks = vm.banks.concat(result);
                callback(result);
            }, function () {
            });
        }


        var unsubscribe = $rootScope.$on('giroApiApp:bankAccountUpdate', function(event, result) {
            vm.bankAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
