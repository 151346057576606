/**
 * Created by rene on 8/6/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteCustomerCreateAccountController', InviteCustomerCreateAccountController);

    /** @ngInject **/
    function InviteCustomerCreateAccountController($translate, Person, entity, $stateParams, $rootScope, noty, $scope, $state, Auth, Organization) {
        var vm = this;

        vm.person = entity;
        vm.token = $stateParams.token;

        vm.companyName = $stateParams.companyName;

        vm.password = undefined;
        vm.confirmPassword = undefined;

        vm.error = null;
        vm.errorUserExists = null;
        vm.clear = clear;
        vm.save = save;

        function clear() {
            $state.go('^');
        }

        function save() {
            vm.person.password = vm.password;

            Person.createCustomerPersonByInvite({action: vm.token}, vm.person, onSaveSuccess, onSaveError);

            vm.isSaving = true;
        }

        function onSaveSuccess(result) {
            Organization.getParameters().$promise
                .then(function (parameters) {
                    console.log(parameters);
                    if (parameters.clientAccessConfirmation) {
                        $state.go('customer-account-created');
                    } else {
                        noty.success($translate.instant('message.alert.create.account'));
                        Auth.login({
                            username: vm.person.email,
                            password: vm.password,
                            rememberMe: vm.rememberMe
                        }).then(function () {
                            if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                                $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                                $state.go('home');
                            }

                            $rootScope.$broadcast('authenticationSuccess');

                            if (Auth.getPreviousState()) {
                                var previousState = Auth.getPreviousState();
                                Auth.resetPreviousState();
                                $state.go(previousState.name, previousState.params);
                            }
                            $window.location.reload();
                        }).catch(function () {
                        });
                    }
                })
                .catch(function () {
                    noty.success($translate.instant('message.alert.create.account'));
                    $state.go('login');
                });

            vm.isSaving = false;
        }

        function onSaveError(result) {
            vm.isSaving = false;
            vm.success = null;
        }

        vm.validateForm = function ($editForm) {
            if (vm.isSaving) {
                return true
            }

            return $editForm.$invalid;
        };

        vm.passwordMatch = function ($editForm) {
            console.log($editForm);

            if ($editForm.confirmPassword.$error.minlength || $editForm.confirmPassword.$error.maxlength || $editForm.confirmPassword.$error.required) {
                $editForm.confirmPassword.$error.notmatch = false;
                return
            }

            if ($editForm.password.$error.minlength || $editForm.password.$error.maxlength) {
                $editForm.confirmPassword.$error.notmatch = false;
                $editForm.confirmPassword.$invalid = false;
                return
            }

            if (vm.password !== vm.confirmPassword) {
                $editForm.confirmPassword.$error.notmatch = true;
                $editForm.confirmPassword.$invalid = true
            } else {
                $editForm.confirmPassword.$error.notmatch = false;
                $editForm.confirmPassword.$invalid = false;
            }
        }
    }
})();
