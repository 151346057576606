(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditDialogController', CreditDialogController);

    /** @ngInject **/
    function CreditDialogController($translate, $timeout, $rootScope, $state, previousState, entity, Credit,
                                    ProgramProvider, Program, Principal, ProgramBuyerByProgramId) {
        var vm = this;

        vm.credit = entity;
        vm.datePickerOpenStatus = {};

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.loadData = loadData;

        vm.programs = [];

        vm.programProviders = [];

        Program.querySimple(function (result) {
            vm.programs = result.content;
            if (vm.credit.id !== undefined) {
                vm.programs.push(vm.credit.program);
                $rootScope.$broadcast('update.program');
            }
        });
        vm.loaded = false;

        vm.expireDate = {
            datepickerOptions: {
                minDate: new Date()
            }
        };

        vm.provider = undefined;
        if (vm.credit && vm.credit.programProvider) {
            vm.provider = vm.credit.programProvider;
            vm.programProviders.push(vm.provider);
        }

        vm.programSelected = function ($id, $model) {
            vm.credit.program = $model;
            vm.loadData();
        };

        vm.loadPrograms = function (query, callback) {
            Program.querySimple({query: query, limit: 50}, function (result) {
                vm.programs = vm.programs.concat(result.content);
                $rootScope.$broadcast('update.company');
                callback(vm.programs);
            }, function () {
                callback(vm.programs);
            });
        };

        vm.loadProgramProviders = function (q, callback) {
            var programId = undefined;
            if(vm.credit.program) {
                programId = vm.credit.program.id;
            }

            ProgramProvider.query({query: q, programId: programId}, function (result) {
                angular.forEach(result, function ($result) {
                    $result.companyName = $result.company.name;
                });

                vm.programProviders = vm.programProviders.concat(result);
                $rootScope.$broadcast('update.programProvider');
                callback(vm.programProviders);
            }, function () {
                callback(vm.programProviders);
            })
        };

        vm.programProviderSelected = function(id, model, editForm) {
            vm.provider = model;
        };

        vm.renderProvider = function ($item, $escape) {
            var template = '<div>' + $escape($item.company.name) + ' - ' + $escape($item.program.name) + '</div>';
            return template;
        };

        function clear() {
            $state.go(previousState.name || '^', previousState.params);
        }

        function save(form) {
            if (form.editForm.$invalid) {
                console.log(form.editForm);
                return;
            }
            vm.isSaving = true;
            vm.credit.programProvider = vm.provider;
            vm.credit.programBuyer = vm.credit.program.buyer;
            if (vm.credit.id !== undefined) {
                Credit.update(vm.credit, onSaveSuccess, onSaveError);
            } else {
                Credit.save(vm.credit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            $timeout(function () {
                $state.go(previousState.name || '^', previousState.params);
            },150);

            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.paidDate = false;
        vm.datePickerOpenStatus.requestedDate = false;
        vm.datePickerOpenStatus.expireDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function expireDateValidator(errorMessage) {
            var message = "";

            switch (errorMessage) {
                case "required":
                    message = "O campo \"Validade\" é obrigatório";
                    break;
                case "datetime":
                    message = "A data inserida é inválida";
                    break;
            }

            return "";
        }

        function loadData() {
            if (vm.loaded === false && vm.credit.program) {

                ProgramProvider.query({programId: vm.credit.program.id}, function (result) {
                    angular.forEach(result, function ($result) {
                        $result.companyName = $result.company.name;
                    });

                    vm.programProviders = vm.programProviders.concat(result);
                }, function () {
                });

                ProgramBuyerByProgramId.getSimple({id: vm.credit.program.id}, function (result) {
                    vm.credit.program.buyer = result;
                });

                vm.credit.createdDate = new Date();
                vm.loaded = true;
            }
        }

    }
})();
