(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyDetailController', CompanyDetailController);

    function CompanyDetailController($scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.company = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('giroApiApp:companyUpdate', function(event, result) {
            vm.company = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
