(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Organization', Organization);

    function Organization ($resource) {
        var resourceUrl = 'api/organizations/:id/:param/:action/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSimpleById': {method: 'GET', params: {param: 'simple'}},
            'getSimple': {method: 'GET', params: {param: 'simple'}, isArray: true},
            'getSimpleByProvider': {method: 'GET', params: {param: 'provider', action: 'simple'}, isArray: true},
            'getSimplePage': {method: 'GET', params: {param: 'simple', action: 'page'}},
            'getParameters': {method: 'GET', params: {param: 'parameters'}},
            'getCurrent': {method: 'GET', params: {param: 'current'}},
            'update': {method: 'PUT'},
            'changeToken': {method: 'PUT', params: {param: 'token'}},
            'hasProviderPanel': {method: 'GET', params: {param: 'has', action: 'provider', third: 'panel'}},
            'hasCustomerPanel': {method: 'GET', params: {param: 'has', action: 'customer', third: 'panel'}}
        });
    }
})();
