(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditApprovedDetailFinancialController', CreditApprovedDetailFinancialController);

    /** @ngInject **/
    function CreditApprovedDetailFinancialController(pagingParams, paginationConstants, $stateParams, $state, $translate, $filter, $window, Batch, GiroConstants, ParseLinks, Program, SessionTerm) {
        var vm = this;

        vm.transition = transition;
        vm.search = search;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.currency = GiroConstants.Constants.currency;
        vm.currentSearch = '';

        vm.program = undefined;
        if ($stateParams.programId) {
            Program.getSimple({id: $stateParams.programId}, function (result) {
                vm.program = result;
                queryExecuted();
            });
        } else {
            queryAll();
        }

        function search() {
            queryExecuted();
        }

        function queryExecuted() {
            Batch.financial({
                programId: vm.program.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                providerName: vm.currentSearch,
                confirmedOnly: true,
                sort: sort()
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.batchesExecuted = result.content;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function queryAll() {
            Batch.financial({size: 1000}, function (result) {
                vm.batchesExecuted = result.content;
            });
        }

        vm.downloadCnab = function (batch) {
            Batch.getCnab({id: batch.id}, function (result) {
                var fileName = result.fileName;

                if (!fileName) {
                    fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".REM";
                }

                debugBase64(result, fileName);
            })
        };

        vm.generateCnab = function (batch) {
            Batch.generateCnab({id: batch.id}, function (result) {
                var fileName = result.fileName;
                if (!fileName) {
                    fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".REM";
                }

                debugBase64(result, fileName);
            });
        };

        vm.downloadSessionTerm = function (batch) {
            SessionTerm.getSessionTermFile({id: batch.sessionTermId}, function (result) {
                var fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".pdf";
                debugBase64(result, fileName);
            })
        };

        function debugBase64(response, fileName) {
            var byteCharacters = atob(response.file);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                programId: vm.program.id,
                currentSearch: vm.currentSearch,
                tabIndex: vm.tabIndex
            });
        }

    }
})();
