(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ErrorModalController', ErrorModalController);

    /** @ngInject **/
    function ErrorModalController(entity, $uibModalInstance, $translate) {
        var vm = this;

        vm.errorMessage = entity;

        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
