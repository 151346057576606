(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('PaymentVoucherDefaultController', PaymentVoucherDefaultController);

    function PaymentVoucherDefaultController($stateParams, PaymentVoucher) {
        var vm = this;

        PaymentVoucher.query({organizationKey: $stateParams.organizationKey}, {id: $stateParams.id}, function (result) {
            vm.paymentVoucher = result;
        })
    }
})();
