(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SessionTermController', SessionTermController);

    function SessionTermController ($scope, $state, $stateParams, ParseLinks, paginationConstants, pagingParams, SessionTerm) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.query = $stateParams.query;
        vm.status = $stateParams.status;

        loadAll();

        function loadAll() {
            SessionTerm.getSessionTerms({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                query: vm.query,
                status: vm.status
            }, function(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sessionTerms = data.content;
                vm.page = pagingParams.page;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        vm.generate = function ($id) {
            SessionTerm.generateSessionTerm({id: $id})
        };

        vm.findSessionTerms = function() {
            transition();
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                query: vm.query,
                status: vm.status
            });
        }
    }
})();
