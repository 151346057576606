(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('CreditReview', CreditReview);


    /** @ngInject **/
    function CreditReview ($resource, DateUtils) {
        var resourceUrl =  'api/credit-reviews/:id/:program/:programId/:action';

        return $resource(resourceUrl, {id : '@id'}, {
            'query': {method: 'GET', isArray: true  },
            'accept': {method: 'PUT', params: {action: 'accept'}},
            'reject': {method: 'PUT', params: {action: 'reject'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }



})();
