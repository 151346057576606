(function () {
    /*jshint camelcase: false */
    'use strict';

    angular
        .module('giroApiApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$localStorage', 'Base64'];

    function AuthServerProvider($http, $localStorage, Base64) {
        var service = {
            getToken: getToken,
            login: login,
            logout: logout,
            hasAcceptedTerms: hasAcceptedTerms
        };

        return service;

        function getToken() {
            return $localStorage.authenticationToken;
        }

        function login(credentials) {
            var data = 'username=' + encodeURIComponent(credentials.username) + '&password=' +
                encodeURIComponent(credentials.password) + '&grant_type=password&scope=read%20write&' +
                'client_secret=my-secret-token-to-change-in-production&client_id=giro_apiapp&device=web';

            return $http.post('api/oauth/token', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': 'Basic ' + Base64.encode('giro_apiapp' + ':' + 'my-secret-token-to-change-in-production')
                }
            }).success(authSuccess);

            function authSuccess(response) {
                var expiredAt = new Date();
                expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
                response.expires_at = expiredAt.getTime();
                $localStorage.authenticationToken = response;
                $localStorage.notifications = [];
                return response;
            }
        }

        function hasAcceptedTerms(token) {
            return $http.get('api/accepted-terms', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token.access_token
                }
            }).success(has);

            function has(data) {
                return data
            }
        }

        function logout() {
            $http.post('api/oauth/logout');
            delete $localStorage.authenticationToken;
            delete $localStorage.notifications;
        }
    }
})();
