(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ProgramProvider', ProgramProvider);

    ProgramProvider.$inject = ['$resource'];

    function ProgramProvider($resource) {
        var resourceUrl = 'api/program-providers/:first/:id/:action/:param/:second';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET', isArray: true},
            'search': {method: 'GET', isArray: true, params: {action: 'search'}},
            'searchSimple': {method: 'GET', params: {action: 'search', param: 'simple'}},
            'searchByProgramSimple': {method: 'GET', params: {first: 'programs', second: 'simple'}},
            'simpleElement': {method: 'GET', params: {action: 'simple'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'anticipations': {
                method: 'GET',
                params: {action: 'anticipations'}
            },
            'anticipation': {
                method: 'GET',
                params: {action: 'anticipations'}
            },
            'getByProgram': {
                method: 'GET',
                params: {action: 'programs'}
            },
            'findByProgramQuery': {
                method: 'GET',
                params: {action: 'programs'}
            },
            'resume': {
                method: 'GET',
                params: {action: 'resume'}
            },
            'createProgramProviderForProgram': {
                method: 'POST',
                params: {action: 'program'}
            },
            'updateProgramProviderForProgram': {
                method: 'PUT',
                params: {action: 'program'}
            },
            'updateSaleStatus': {
                method: 'PUT',
                params: {action: 'saleStatus'}
            },
            'addEmail': {
                method: 'POST',
                params: {action: 'emailAddress'}
            },
            'addPhone': {
                method: 'POST',
                params: {action: 'phones'}
            },
            'updateCompanyInterest': {
                method: 'PUT',
                params: {action: 'interest-status', param: '@interestId', allOrganizations: '@allOrganizations'}
            }
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ProgramProviderByProgramId', ProgramProviderByProgramId);

    ProgramProviderByProgramId.$inject = ['$resource'];

    function ProgramProviderByProgramId($resource) {
        var resourceUrl = 'api/program-providers/program/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            }
        });
    }
})();

