(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .factory('FinancialCredit', FinancialCredit);

    /** @ngInject **/
    function FinancialCredit ($resource, DateUtils) {
        var resourceUrl =  'api/credits/program/:id/financial';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.paidDate = DateUtils.convertDateTimeFromServer(data.paidDate);
                        data.requestedDate = DateUtils.convertDateTimeFromServer(data.requestedDate);
                        data.expireDate = DateUtils.convertDateTimeFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
