(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('company', {
                parent: 'entity',
                url: '/company?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/companies.html',
                        controller: 'CompanyController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('company');
                        return $translate.refresh();
                    }]
                }
            })
            .state('company-detail', {
                parent: 'entity',
                url: '/company/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                    // pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-detail.html',
                        controller: 'CompanyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Company', function ($stateParams, Company) {
                        return Company.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'company',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('address-type');
                        $translatePartialLoader.addPart('favored-type');
                        $translatePartialLoader.addPart('account-type');
                        $translatePartialLoader.addPart('phone-type');
                        $translatePartialLoader.addPart('company-detail');
                        return $translate.refresh();
                    }]
                }
            })
            .state('company.new', {
                parent: 'company',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-dialog.html',
                        controller: 'CompanyDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            photo: {}
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('company-dialog');
                        return $translate.refresh();
                    }]
                }
            })
            .state('company.edit', {
                parent: 'company',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-dialog.html',
                        controller: 'CompanyDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function (Company, $stateParams) {
                        return Company.get({id: $stateParams.id}).$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('company-dialog');
                        return $translate.refresh();
                    }]
                }

            })
            .state('company.delete', {
                parent: 'company',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/company-delete-dialog.html',
                        controller: 'CompanyDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Company', function (Company) {
                                return Company.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('company-delete');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('company', null, {reload: 'company'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('companyCanAnticipate', {
                parent: 'company',
                url: '/can-anticipate?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-not-anticipate.html',
                        controller: 'CompanyNotAnticipateController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('company.anticipateAuthorize', {
                parent: 'companyCanAnticipate',
                url: '/authorize',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                params: {
                    companiesList: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/anticipate-authorize-dialog.html',
                        controller: 'AnticipateAuthorizeDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return $stateParams.companiesList;
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('company-delete');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('companyCanAnticipate', null, {reload: 'companyCanAnticipate'});
                    }, function () {
                        $state.go('companyCanAnticipate', null, {reload: 'companyCanAnticipate'});
                    });
                }]
            })
            .state('company.anticipateBlocked', {
                parent: 'companyCanAnticipate',
                url: '/blocked',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                params: {
                    companiesList: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/anticipate-blocked-dialog.html',
                        controller: 'AnticipateBlockedDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return $stateParams.companiesList;
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('company-delete');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('companyCanAnticipate', null, {reload: 'companyCanAnticipate'});
                    }, function () {
                        $state.go('companyCanAnticipate', null, {reload: 'companyCanAnticipate'});
                    });
                }]
            })
            .state('company-address-manage', {
                parent: 'entity',
                url: '/company-address-manage',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_FINANCIAL']
                },
                params: {
                    isWebapp: false
                },
                resolve: {
                    entity: function (Company) {
                        return Company.getDefaultCompanyAddress().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('address-type');
                        $translatePartialLoader.addPart('company_address');
                        return $translate.refresh();
                    }]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-address-manage.html',
                        controller: 'CompanyAddressManageController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('dashboard/company-address-manage', {
                parent: 'webapp-redirect',
                url: '/dashboard/company-address-manage',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_FINANCIAL']
                },
                params: {
                    isWebapp: true
                },
                resolve: {
                    entity: function (Company) {
                        return Company.getDefaultCompanyAddress().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('address-type');
                        $translatePartialLoader.addPart('company_address');
                        return $translate.refresh();
                    }]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-address-manage.html',
                        controller: 'CompanyAddressManageController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('company-files-manage', {
                parent: 'entity',
                url: '/company-files-manage',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-files-manage.html',
                        controller: 'CompanyFilesManageController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    isWebapp: false
                },
                resolve: {
                    entity: function (Company) {
                        return Company.getDefaultFiles().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('company-files-manage');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'company',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('dashboard/company-files-manage', {
                parent: 'webapp-redirect',
                url: '/dashboard/company-files-manage',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-files-manage.html',
                        controller: 'CompanyFilesManageController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    isWebapp: true
                },
                resolve: {
                    entity: function (Company) {
                        return Company.getDefaultFiles().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('company-files-manage');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('company-files', {
                parent: 'entity',
                url: '/company-files?page&sort&query&status',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-files.html',
                        controller: 'CompanyFilesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('company-files');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
