(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('HolidayDetailController', HolidayDetailController);

    function HolidayDetailController($scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.holiday = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('giroApiApp:holidayUpdate', function(event, result) {
            vm.holiday = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
