(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BatchExternalRequestController', BatchExternalRequestController);

    function BatchExternalRequestController($translate, $timeout, $state, entity, BatchOperation, CreditEstimate, $q, noty, GiroConstants) {
        var vm = this;

        vm.batch = entity;
        vm.clear = clear;
        vm.request = request;
        vm.currency = GiroConstants.Constants.currency;
        var credits = [];

        vm.loading = true;

        vm.creditIsvalid = !(vm.batch.credits.length > 0 && vm.batch.credits[0].status !== "OPEN");

        angular.forEach(entity.credits, function ($credit) {
            credits.push($credit.id);
        });

        CreditEstimate.request({credits: credits}, function (result) {
            vm.estimate = result;
            vm.estimates = result.estimates;
            vm.loading = false;
        }, function (error) {
            console.log(error);
        });

        function clear() {
            $state.go('home')
        }

        function request() {
            var credits = [];
            angular.forEach(entity.credits, function ($credit) {
                credits.push($credit.id);
            });
            batch.credits = credits;

            BatchOperation.request(vm.batch, function (result) {
                noty.success($translate.instant('message.batch.credit.request'));
                $timeout(function () {
                    clear();
                },150);
            }, function (e) {
                console.log(e);
            });
        }
    }
})();
