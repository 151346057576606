(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('creditStatus', creditStatus)
        .filter('batchStatus', batchStatus);

    function creditStatus() {
        return function (value) {
            switch (value) {
                case 'OPEN':
                    return "Aberto";
                case 'REQUESTED':
                    return "Solicitado";
                case 'WAIT_CONFIRMATION':
                    return "Aguardando Confirmação";
                case 'FINANCIAL_ANALYSIS':
                    return "Em Análise";
                case 'CONFIRMED':
                    return "Confirmado";
                case 'PAID':
                    return "Pago";
                case 'DENIED':
                    return "Negado";
                case 'REVIEW':
                    return "Aguardando Revisão";
            }
            return value;
        }
    }

    function batchStatus() {
        return function (value) {
            switch (value) {
                case 'OPEN':
                    return "Aberto";
                case 'REQUESTED':
                    return "Solicitado";
                case 'WAIT_CONFIRMATION':
                    return "Aguardando Confirmação";
                case 'FINANCIAL_ANALYSIS':
                    return "Em Análise";
                case 'CONFIRMED':
                    return "Confirmado";
                case 'PAID':
                    return "Pago";
                case 'ARCHIVED':
                    return "Arquivado";
                case 'ERROR':
                    return "Erro ao Confirmar";
            }
            return value;
        }
    }
})();
