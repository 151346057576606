(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('PaymentVoucher', PaymentVoucher);

    /** @ngInject **/
    function PaymentVoucher($resource) {
        var resourceUrl = 'api/payment-voucher/:id';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET'}
        });
    }
})();
