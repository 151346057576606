(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('HomeCompanyController', HomeCompanyController);

    /** @ngInject **/
    function HomeCompanyController(Principal, Company, $state, $window, $timeout, noty) {
        var vm = this;
        vm.setCompanyToDefault = setCompanyToDefault;

        vm.companies = [];
        loadData();

        function loadData() {
            Company.userCompanies(function (result) {
                vm.companies = result;
            });
        }

        function setCompanyToDefault(company) {
            Company.companyDefault({id: company.id}, function () {
                company.isDefault = true;
                Principal.setDefaultCompany(company);
                if ((Principal.hasAnyAuthority(["ROLE_PROVIDER"]) || Principal.hasAnyAuthority(["ROLE_PROVIDER_CONSULT"]) && Principal.systemParameterEnabled("PROVIDER_PANEL"))) {
                    $state.go('provider-panel', null, {reload: 'provider-panel'});
                } else if (Principal.hasAnyAuthority(["ROLE_CLIENT"]) && Principal.systemParameterEnabled("CLIENT_PANEL")) {
                    $state.go('client-panel', null, {reload: 'client-panel'});
                } else {
                    $state.go('home', null, {reload: 'home'});
                }
                $timeout(function () {
                    $window.location.reload();
                }, 150);
            }, onError);
        }

        function onError() {
            noty.error("Ocorreu um erro ao definir a empresa como padrão");
        }
    }
})();
