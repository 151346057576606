/**
 * Created by rene on 1/4/18.
 */
/**
 * Created by rene on 1/3/18.
 */
(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('EmailAddressHistory', EmailAddressHistory);

    function EmailAddressHistory ($resource) {
        var resourceUrl =  'api/email-address-histories/:id/:action';

        return $resource(resourceUrl, {id : '@id'}, {
            'getByEmailAddress': {
                method: 'GET',
                params:{action:'email-address'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
