(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ApprovalProfilesController', ApprovalProfilesController);

    function ApprovalProfilesController ($scope, $state, ApprovalProfile, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 50;
        vm.approvalProfiles = [];

        loadAll(pagingParams.page);

        function loadAll(page) {
            ApprovalProfile.getAll({
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                q: vm.currentSearch
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.approvalProfiles = data.content;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            loadAll(vm.page);
        }
    }

})();
