/**
 * Created by rene on 8/6/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteCustomerDetailsController', InviteCustomerDetailsController);

    /** @ngInject **/
    function InviteCustomerDetailsController($state, $uibModal, entity, previousState, EmailAddress) {
        var vm = this;

        vm.emailAddress = entity;

        function load() {
            EmailAddress.getCustomerEmailAddressWithInvites({id: vm.emailAddress.id},
                function (result) {
                    vm.emailAddress = result;
                })
        }

        vm.back = function () {
            $state.go(previousState.name || '^', previousState.params);
        };

        vm.invite = function (email, companyId, emailId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-dialog.html',
                controller: 'InviteCustomerDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            companyId: companyId,
                            emailId: emailId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    console.log(result);
                    load();
                }
            }, function () {
            });
        };

        vm.cancel = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-cancel-dialog.html',
                controller: 'InviteCustomerCancelDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    console.log(result);
                    load();
                }
            }, function () {
            });
        };

        vm.confirm = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-confirm-dialog.html',
                controller: 'InviteCustomerConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    console.log(result);
                    load();
                }
            }, function () {
            });
        };

        vm.edit = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-update-email-dialog.html',
                controller: 'InviteCustomerUpdateEmailDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    console.log(result);
                    load();
                }
            }, function () {
            });
        };
    }
})();
