(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProgramDeleteController',ProgramDeleteController);

    ProgramDeleteController.$inject = ['$translate', '$timeout', '$uibModalInstance', 'entity', 'Program', 'noty'];

    function ProgramDeleteController($translate, $timeout, $uibModalInstance, entity, Program, noty) {
        var vm = this;

        vm.program = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Program.delete({id: id},
                function () {
                    noty.alert($translate.instant('message.alert.program.delete'));
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    },150);
                });
        }
    }
})();
