(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDataDocumentUploadDialogController', ImportedDataDocumentUploadDialogController);

    /** @ngInject **/
    function ImportedDataDocumentUploadDialogController($uibModalInstance, entity, ImportedDataDocument) {
        var vm = this;

        vm.isSaving = false;
        vm.document = entity;
        vm.clear = clear;

        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {

            vm.isSaving = true;
            ImportedDataDocument.process(vm.document, onSuccess, onError);

            function onSuccess() {
                $uibModalInstance.close(true);
            }

            function onError() {
                $uibModalInstance.close(false);
            }
        }
    }
})();
