(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteCustomerCancelDialogController', InviteCustomerCancelDialogController);

    /** @ngInject **/
    function InviteCustomerCancelDialogController($uibModalInstance, entity, Invite) {
        var vm = this;

        vm.inviteId = entity.inviteId;
        vm.email = entity.email;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.cancel = function () {
            Invite.close({id: vm.inviteId},
                function () {
                    $uibModalInstance.close(true);
                },

                function () {
                    $uibModalInstance.close(false);
                }
            )
        }
    }
})();
