(function () {
  'use strict';

  angular
    .module('giroApiApp')
    .controller('CreditVizualizerController', CreditVizualizer);

  /** @ngInject **/
  function CreditVizualizer($translate, noty, $location, Program, $state, Credit, Batch,
                            paginationConstants, pagingParams, $stateParams, $uibModal, GiroConstants) {
    var vm = this;

    vm.loadPage = loadPage;
    vm.transition = transition;
    vm.createBatch = createBatch;
    vm.batchChanged = batchChanged;
      vm.currency = GiroConstants.Constants.currency;
    vm.itemsPerPage = paginationConstants.itemsPerPage;

    vm.program = pagingParams.program || $stateParams.program;
    if (vm.program) {
      Program.getSimple({id: vm.program}, function (result) {
        vm.programName = result.name;
      });
    }
    vm.category = pagingParams.category;

    vm.loading = true;

    vm.goToVoucher = goToVoucher;

    function goToVoucher(credit) {
      $state.go('credit-visualizer.voucher', {id: credit.id})
    }

    function selectTab() {
      if (vm.credits) {
        vm.initialTabIndex = 0;
      } else {
        vm.initialTabIndex = 1;
      }
    }

    function query() {
      switch (vm.category) {
        case 'buyer':
          vm.credits = Credit.buyer({programId: vm.program}, function () {

          });
          vm.batches = Batch.buyer({programId: vm.program});
          selectTab();
          break;
        case 'provider':
          Credit.provider({providerId: $stateParams.providerId, programId: vm.program}, function (credit) {
            vm.credits = [];
            angular.forEach(credit, function ($credit) {
              if ($credit.status === 'OPEN') {
                vm.credits.push($credit);
                vm.loading = false;
              }
            });
            selectTab();
          });
          vm.batches = Batch.provider({programId: vm.program, providerId: $stateParams.providerId});
          break;
        case 'financial':
          vm.batches = Batch.financial({programId: vm.program});
          selectTab();
          break;
      }
    }

    function queryAll() {
      switch (vm.category) {
        case 'buyer':
          vm.credits = Credit.buyer(function () {

          });
          vm.batches = Batch.buyer();
          break;
        case 'provider':
          vm.readOnly = true;
          Credit.allProvider({providerId: $stateParams.providerId}, function (credit) {
            vm.credits = [];
            angular.forEach(credit, function ($credit) {
              if ($credit.status === 'OPEN') {
                vm.readOnly = false;
                vm.credits.push($credit);
              }
            });
          });
          vm.batches = Batch.provider({providerId: $stateParams.providerId});
          break;
        case 'financial':
          vm.batches = Batch.financial();
          break;
      }
    }

    if (!vm.program || vm.program === null) {
      queryAll();
    } else {
      query();
    }

    function loadPage(page) {
      vm.page = page;
      vm.transition();
    }

    function transition() {
      $state.transitionTo($state.$current, {
        page: vm.page,
        sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
        search: vm.currentSearch
      });
    }

    function createBatch() {
      var batch = {
        credits: [],
        value: 0
      };
      angular.forEach(vm.credits, function ($credit) {
        if ($credit.batched) {
          batch.credits.push(angular.copy($credit));
          batch.value += $credit.value;
        }
      });

      $uibModal.open({
        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-request-dialog.html',
        controller: 'BatchVisualizerRequestController',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          entity: function () {
            return batch;
          }
        }
      }).result.then(function () {
        query();
          noty.success($translate.instant('message.alert.batch.save'));
        vm.batchQuantity = 0;
      }, function () {
        query();
        vm.batchQuantity = 0;
      });

    }

    vm.batchQuantity = 0;
    function batchChanged($credit) {
      if ($credit.batched) {
        vm.batchQuantity = vm.batchQuantity + 1;
      } else {
        vm.batchQuantity = vm.batchQuantity - 1;
      }
    }
  }
})();
