(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('CreditEstimate', CreditEstimate);

    /** @ngInject **/
    function CreditEstimate ($resource) {
        var resourceUrl =  'api/estimates/:action/:id/:credits/:type';

        return $resource(resourceUrl, { id : '@id' }, {
            'getAllForProvider': { method: 'GET', params: { action: 'providers'} },
            'calculate': { method: 'POST'},
            'financial': { method: 'POST', params: {action: 'batch', type: 'financial'} },
            'request': { method: 'POST', params: {action: 'request'} },
            'batch': { method: 'POST', params : {action : 'batch'} }
        });
    }

})();
