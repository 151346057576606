(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customer-titles-report', {
                parent: 'entity',
                url: '/customer-titles/report?page&sort&released&titleNumber&invoiceNumber&canAnticipate&createDateStart&createDateEnd&dueDateStart&dueDateEnd&companyCustomerId&companyId&status&organizationId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCIAL', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/customer-titles/customer-titles-report.html',
                        controller: 'CustomerTitleReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: undefined
                },
                resolve: {
                    defaultOrganization: function (Organization) {
                        return Organization.getCurrent().$promise
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customer-title');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
