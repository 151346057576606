(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BuyerPanelController', BuyerPanelController);

    function BuyerPanelController($state, GiroConstants, ProgramBuyer) {
        var vm = this;
        vm.anticipatedBalance = {};
        vm.providersBalance = [];
        vm.report = [];
        vm.currency = GiroConstants.Constants.currency;
        vm.reportButton = false;

        ProgramBuyer.getAnticipatedBalance(function (result) {
            vm.anticipatedBalance = result;
        });

        ProgramBuyer.getProviderBalance(function (result) {
            vm.providersBalance = result;
        });

        ProgramBuyer.getProviderBalanceReport(function (result) {
            vm.report = result.titles;
            vm.reportButton = true;
        });


    }
})();
