(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SettingsController', SettingsController);

    /** @ngInject **/
    function SettingsController($translate, $timeout, $scope, $state, entity, Person, $rootScope, TeamMember, User, noty) {
        var vm = this;
        vm.person = entity;
        vm.addresses = vm.person.addresses;
        vm.phones = vm.person.phones;
        vm.clear = clear;
        vm.save = save;
        vm.addAddress = addAddress;
        vm.addPhone = addPhone;
        vm.removePhone = removePhone;
        vm.removeAddress = removeAddress;

        function clear() {
            $state.go('^');
        }

        function save() {
            vm.person.phones = vm.phones;
            angular.forEach(vm.addresses, function ($a, $index) {
                if (angular.equals($a, {})) {
                    vm.addresses.splice($index, 1);
                }
            });

            vm.isSaving = true;

            Person.settings(vm.person, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('giroApiApp:personUpdate', result);
            noty.success($translate.instant('message.alert.person.update'));

            $timeout(function () {
                $state.go('home');
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = null;
        }

        function addAddress() {
            vm.addresses.push({});
        }

        function addPhone() {
            vm.phones.push({});
        }

        function removeAddress($index) {
            vm.addresses.splice($index, 1);
        }

        function removePhone($index) {
            vm.phones.splice($index, 1);
        }
    }
})();
