/**
 * Created by rene on 8/2/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('invite-provider', {
                parent: 'entity',
                url: '/invite-provider?page&sort&query',

                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-provider/invite-providers.html',
                        controller: 'InviteProviderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: undefined,
                    withUsers: 'all'
                },
                resolve: {
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            withUsers: $stateParams.withUsers,
                            query: $stateParams.query
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('invite-provider');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invite-provider-approve', {
                parent: 'entity',
                url: '/invite-provider-approve?page&sort&query',

                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-provider/invite-providers-approve.html',
                        controller: 'InviteProviderApproveController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: undefined,
                    withUsers: 'no-users'
                },
                resolve: {
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            withUsers: $stateParams.withUsers
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-provider');
                        return $translate.refresh();
                    }]
                }
            })
            .state('provider-create-account', {
                parent: 'anonymous-entity',
                url: '/{token}/accept-invite?companyName&identification&organizationName',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-provider/invite-provider-create-account.html',
                        controller: 'InviteProviderCreateAccountController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    companyName: undefined
                },
                resolve: {
                    entity: function ($stateParams) {
                        return {
                            name: undefined,
                            email: undefined,
                            identification: undefined,
                            companyName: $stateParams.companyName
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-provider');
                        $translatePartialLoader.addPart('invite-provider-create-account');
                        return $translate.refresh();
                    }]
                }
            })
            .state('provider-account-created', {
                parent: 'anonymous-entity',
                url: '/account-created',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-provider/invite-provider-account-created.html',
                        controller: 'InviteProviderAccountCreatedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-provider');
                        $translatePartialLoader.addPart('invite-provider-create-account');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invite-provider-details', {
                parent: 'entity',
                url: '/invite-provider/detail/{id}',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos',
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-provider/invite-provider-details.html',
                        controller: 'InviteProviderDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'EmailAddress', function ($stateParams, EmailAddress) {
                        return EmailAddress.getEmailAddressWithInvites({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'invite-provider',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-provider');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
