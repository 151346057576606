(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDocumentDeleteController',ImportedDocumentDeleteController);

    ImportedDocumentDeleteController.$inject = ['$translate', '$timeout', '$uibModalInstance', 'entity', 'ImportedDocument', 'noty'];

    function ImportedDocumentDeleteController($translate, $timeout, $uibModalInstance, entity, ImportedDocument, noty) {
        var vm = this;

        vm.credit = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ImportedDocument.delete({id: id},
                function () {
                    noty.alert($translate.instant('message.alert.importeddocument.delete'));
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    },150);
                });
        }
    }
})();
