(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('HomeController', HomeController);

    /** @ngInject **/
    function HomeController($scope, Principal, LoginService, $state, myPrograms, ProgramProvider, GiroConstants) {
        var vm = this;

        vm.account = undefined;
        vm.isAuthenticated = undefined;
        vm.currency = GiroConstants.Constants.currency;
        vm.login = LoginService.open;

        vm.programs = [];
        vm.buyer = [];
        vm.financial = [];
        vm.showBuyerPanel = Principal.hasAnyAuthority(["ROLE_BUYER"]);

        vm.goToCreditVizualizer = goToCreditVizualizer;

        loadData();

        vm.register = register;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        checkAuthorities();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.authenticated;
                if (!Principal.defaultCompanyDefined()) {
                    $state.go('home-company');
                }
            });
        }

        function register() {
            $state.go('register');
        }

        function goToCreditVizualizer(programProviderId, programId) {
            $state.go('credit-detail-provider', {
                providerId: programProviderId,
                programId: programId
            });
        }

        function checkAuthorities() {
            if ((Principal.hasAnyAuthority(["ROLE_PROVIDER"]) || Principal.hasAnyAuthority(["ROLE_PROVIDER_CONSULT"]) && Principal.systemParameterEnabled("PROVIDER_PANEL"))) {
                $state.go('provider-panel');
            } else if (Principal.hasAnyAuthority(["ROLE_CLIENT"]) && Principal.systemParameterEnabled("CLIENT_PANEL")) {
                $state.go('client-panel');
            }
        }

        function loadData() {
            if (Principal.hasAnyAuthority(["ROLE_BUYER", "ROLE_FINANCIAL"])) {
                vm.programs = myPrograms.get({}, function (result) {
                    vm.buyer = result.programsAsBuyer;
                    vm.financial = result.programsAsFinancial;
                });
            }

            if (Principal.hasAnyAuthority(["ROLE_PROVIDER"])) {
                ProgramProvider.resume(function (result) {
                    vm.total = result.total;
                    vm.providerPrograms = result.programs;
                });
            }
        }
    }
})();
