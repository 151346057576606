(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyAddressManageController', CompanyAddressManageController);

    /** @ngInject **/
    function CompanyAddressManageController($translate, $timeout, $scope, $state, $stateParams, entity, Company, Address, City, CityUtils, noty) {
        var vm = this;
        vm.company = entity;
        vm.isWebapp = $stateParams.isWebapp;
        vm.address = vm.company.address ? vm.company.address : {};

        vm.cities = [];
        vm.city = undefined;

        vm.clear = clear;
        vm.save = save;
        vm.loadCities = loadCities;
        vm.onCitySelected = onCitySelected;

        loadInitialCities();

        function clear() {
            if (vm.isWebapp) return;

            $state.go('home');
        }

        function save() {
            vm.isSaving = true;
            vm.company.address = vm.address;
            vm.company.address.cityId = vm.city.id;
            Address.updateCompanyAddress({id: vm.company.id}, vm.company, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            noty.success($translate.instant('message.alert.company.address.updated'));
            $timeout(function () {
                if (vm.isWebapp) return;

                $state.go('home');
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = null;
        }

        function loadInitialCities() {
            if (vm.address.city) {
                vm.city = vm.address.city;
                vm.cities.push(CityUtils.formatToCityNameAndStateAcronym(vm.city));
            } else {
                City.query({
                    page: 0,
                    size: 30,
                    sort: ['name,asc']
                }, function (result) {
                    vm.cities = CityUtils.formatListToCityNameAndStateAcronym(result.content);
                });
            }
        }

        function loadCities(query, callback) {
            City.query({
                page: 0,
                size: 30,
                sort: ['name,asc'],
                search: query
            }, function (result) {
                vm.cities = vm.cities.concat(CityUtils.formatListToCityNameAndStateAcronym(result.content));
                callback(vm.cities);
            });
        }

        function onCitySelected($id, $model) {
            vm.city = $model;
        }

    }
})();
