(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditController', CreditController);

    /** @ngInject **/
    function CreditController($stateParams, $rootScope, $timeout, $state,
                              Program, Credit, ProgramProvider,
                              paginationConstants, pagingParams,
                              ParseLinks, GiroConstants, StringUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clearFilter = clearFilter;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.status = $stateParams.status;
        vm.nfNumber = $stateParams.nfNumber;

        // Programs
        vm.programs = [];
        vm.program = undefined;
        vm.programId = $stateParams.programId;
        vm.loadPrograms = loadPrograms;
        vm.programSelected = programSelected;

        // Providers
        vm.providers = [];
        vm.provider = undefined;
        vm.providerId = $stateParams.providerId;
        vm.loadProviders = loadProviders;
        vm.providerSelected = providerSelected;
        vm.renderProvider = renderProvider;

        vm.search = search;
        initializePrograms();
        initializeProviders();


        function initializePrograms() {
            if (vm.programId) {
                Program.getSimple({id: vm.programId}, function (result) {
                    vm.programs = vm.programs.concat(result);
                    vm.program = result;
                })
            } else {
                Program.querySimple(function (result) {
                    vm.programs = vm.programs.concat(result.content);
                });
            }
        }

        function loadPrograms(query, callback) {
            Program.querySimple({
                query: query
            }, function (result) {
                vm.programs = vm.programs.concat(result.content);
                callback(vm.programs);
            }, function () {
            });
        }

        function programSelected($id, $model) {
            vm.program = $model;

            if (!$id) {
                vm.programId = undefined;
                vm.providers = [];
                vm.providerId = undefined;
                vm.provider = undefined;
                $rootScope.$broadcast('$giro.restartProviders');
                return;
            }

            vm.programId = $id;
            vm.providers = [];
            vm.providerId = undefined;
            vm.provider = undefined;
            if (vm.programId) {
                ProgramProvider.query({
                    programId: vm.programId,
                }, function (result) {
                    vm.providers = vm.providers.concat(StringUtils.formatListWithoutCanAnticipate(result));
                    $timeout(function () {
                        $rootScope.$broadcast('$giro.restartProviders');
                    }, 150);

                });
            }
        }

        function initializeProviders() {
            if (!vm.programId) {
                return;
            }
            if (vm.providerId) {
                ProgramProvider.get({id: vm.providerId}, function (result) {
                    vm.providers.push(StringUtils.formatWithoutCanAnticipate(result));
                    vm.provider = StringUtils.formatWithoutCanAnticipate(result);
                    $timeout(function () {
                        $rootScope.$broadcast('$giro.reloadProviders');
                    }, 150);
                });
            } else {
                ProgramProvider.query({
                    programId: vm.programId,
                }, function (result) {
                    vm.providers = vm.providers.concat(StringUtils.formatListWithoutCanAnticipate(result));
                });
            }
        }

        function loadProviders(searchText, callback) {
            ProgramProvider.query({
                query: searchText,
                programId: vm.programId,
            }, function (result) {
                vm.providers = StringUtils.formatListWithoutCanAnticipate(result);
                callback(result);
            }, function () {
            });
        }

        function providerSelected($id, $model) {
            vm.provider = $model;
            vm.providerId = $id ? $id : undefined;
        }

        function renderProvider($item, $escape) {
            return '<div>' + $escape($item.company.name) + ' - ' + $escape($item.program.name) + '</div>';
        }

        function loadAll(page) {
            Credit.query({
                programId: vm.programId,
                providerId: vm.providerId,
                status: vm.status,
                nfNumber: vm.nfNumber,
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.credits = data.content;
                vm.page = page;
            }

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                programId: vm.programId,
                providerId: vm.providerId,
                status: vm.status,
                nfNumber: vm.nfNumber
            });
        }

        function clearFilter() {
            $state.transitionTo($state.$current, {
                page: 1,
                sort: 'id,asc',
                programId: undefined,
                providerId: undefined,
                status: undefined,
                nfNumber: undefined
            }, {reload: true});
        }


        function search() {
            $stateParams.programId = vm.programId;
            $stateParams.providerId = vm.providerId;
            $stateParams.status = vm.status;
            $stateParams.nfNumber = vm.nfNumber;
            loadAll(pagingParams.page);
        }

        if (vm.programId) {
            search();
        }


    }
})();
