(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('bankAccountType', bankAccountType);

    function bankAccountType() {
        return function (value) {
            switch (value) {
                case 'CHECKING_ACCOUNT':
                    return "C/C";
                case 'SAVINGS_ACCOUNT':
                    return "Poupança";
            }
            return value;
        }
    }
})();
