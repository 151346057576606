(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('CompanyCustomer', CompanyCustomer);

    function CompanyCustomer($resource) {
        var resourceUrl = 'api/companies-customer/:id/:action/:type';

        return $resource(resourceUrl, {id: '@id'}, {
            'queryInviteCustomer': {
                method: 'GET',
                params: {type: 'invite', action: 'customer'}
            },
            'queryInviteCustomerToApprove': {
                method: 'GET',
                params: {type: 'invite-to-approve', action: 'customer'}
            },
            'searchSimple': {
                method: 'GET',
                params: {action: 'search', type:'simple'}
            },
            'simpleElement': {
                method: 'GET',
                params: {action: 'simple'}
            }
        });
    }
})();
