(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Holiday', Holiday);

    function Holiday ($resource) {
        var resourceUrl = 'api/holidays/:id/:first/:second/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
