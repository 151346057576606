(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .factory('AcceptTerms', AcceptTerms);

    AcceptTerms.$inject = ['$http'];

    function AcceptTerms ($http) {
        var service = {
            acceptTerms: acceptTerms
        };

        return service;

        function acceptTerms(token) {
            return $http.get('api/accept-terms', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token.data.access_token
                }
            }).success(has);

            function has(data) {
                console.log(data);
                return data
            }
        }
    }
})();
