(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyEventManageController', CompanyEventManageController);

    /** @ngInject **/
    function CompanyEventManageController($state, $stateParams, previousState, User, Company, CompanyEvent, Organization, entity, currentUser) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.companyId = $stateParams.companyId;
        vm.user = currentUser;
        vm.event = entity;
        vm.companies = [];
        vm.userRequests = [];
        vm.userReceives = [];

        if(vm.event.company) {
            vm.companies.push(vm.event.company)
        }

        if(vm.event.userRequest) {
            vm.userRequests.push(vm.event.userRequest)
        }

        if(vm.event.userReceived) {
            vm.userReceives.push(vm.event.userReceived)
        }

        if(!vm.event.userRequest && vm.user) {
            vm.user.name = vm.user.firstName;
            vm.event.userRequest = vm.user;
            vm.userRequests.push(vm.user)
        }

        function clear() {
            $state.go(previousState.name || '^', previousState.params);
        }

        function save() {
            vm.isSaving = true;

            if(vm.companyId) {
                vm.event.company = {id: vm.companyId}
            }

            if (vm.event.id) {
                CompanyEvent.update(vm.event, onSaveSuccess, onSaveError);
            } else {
                CompanyEvent.save(vm.event, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go(previousState.name || '^', $stateParams);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        loadAllCompany();

        function loadAllCompany() {
            Company.queryByName({size: 200}, function (result) {
                vm.companies = vm.companies.concat(result.content);
            });
        }

        // Company
        vm.loadCompany = function (query, callback) {
            Company.queryByName({
                q: query,
                size: 200
            }, function (result) {
                vm.companies = vm.companies.concat(result.content);
                callback(result.content);
            }, function () {
                callback();
            });
        };

        vm.companySelected = function ($id, $model) {
            vm.event.company = $model;
        };

        vm.renderCompany = function ($item, $escape) {
            var template = '<div>' + $escape($item.name) + ' - ' + $escape($item.identification) + '</div>';
            return template;
        };

        // Request User
        loadUserRequest();

        function loadUserRequest() {
            User.queryByName({size: 5}, function (result) {
                vm.userRequests = vm.userRequests.concat(result.content);
            });
        }

        vm.loadUserRequest = function (query, callback) {
            User.queryByName({q: query}, function (result) {
                vm.userRequests = vm.userRequests.concat(result.content);
                callback(result.content);
            }, function () {
                callback();
            });
        };

        vm.userRequestSelected = function ($id, $model) {
            vm.event.userRequest = $model;
        };

        vm.renderUserRequest = function ($item, $escape) {
            var template = '<div>' + $escape($item.name) + ' - ' + $escape($item.email) + '</div>';
            return template;
        };

        // User Received

        loadUserReceived();

        function loadUserReceived() {
            User.queryByName({size: 5}, function (result) {
                vm.userReceives = vm.userReceives.concat(result.content)
            })
        }

        vm.loadUserReceived = function (query, callback) {
            User.queryByName({q: query}, function (result) {
                vm.userReceives = vm.userReceives.concat(result.content);
                callback(result.content);
            }, function () {
                callback();
            });
        };

        vm.userReceivedSelected = function ($id, $model) {
            vm.event.userReceived = $model;
        };

        vm.renderUserReceived = function ($item, $escape) {
            var template = '<div>' + $escape($item.name) + ' - ' + $escape($item.email) + '</div>';
            return template;
        };

        //Selectize Organization
        vm.organizations = [];

        loadAllOrganizations();

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
                if (vm.event && vm.event.organization) {
                    vm.organization = vm.organizations.find(function (element) {
                        return element.organizationKey === vm.event.organization.organizationKey
                    })
                }

                if (vm.organizations.length === 1) {
                    vm.event.organization = vm.organizations[0];
                    vm.organization = vm.organizations[0];
                }

            }, function () {
            });
        }

        vm.loadOrganizations = function (query, callback) {
            Organization.getSimple({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result.content);
            }, function () {
            });
        };

        vm.organizationSelected = function ($id, $model) {
            vm.organization = $model;

            if ($id) {
                vm.event.organization = $model;
                vm.organization = $model;
            } else {
                vm.event.organization = undefined;
                vm.organization = undefined;
            }
        };
        //----------

    }
})();
