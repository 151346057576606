(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login/:action/:type', {}, {
            'query': {method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST'},
            'update': { method:'PUT'},
            'search': {method: 'GET', params:{action: 'search'}},
            'queryByName': {method: 'GET', params:{action: 'query'}},
            'changeStatus': {method: 'PUT', params:{action: 'change', type: 'status'}}
        });

        return service;
    }
})();
