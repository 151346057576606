(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditDetailFinancialController', CreditDetailFinancialController);

    /** @ngInject **/
    function CreditDetailFinancialController($window, $filter, $state, $translate, $stateParams, paginationConstants, pagingParams, Batch, BatchOperation, ParseLinks, GiroConstants, Program, noty, CreditInvoice) {
        var vm = this;

        vm.transition = transition;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.tabIndex = pagingParams.tabIndex;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.currency = GiroConstants.Constants.currency;
        vm.currentSearch = '';
        vm.selectEnabled = false;
        vm.allSelected = false;
        vm.batches = [];
        vm.credits = [];

        vm.search = search;
        vm.selectAll = selectAll;
        vm.batchChanged = batchChanged;
        vm.selectCredit = selectCredit;
        vm.downloadInvoice = downloadInvoice;

        vm.program = undefined;
        if ($stateParams.programId) {
            Program.getSimple({id: $stateParams.programId}, function (result) {
                vm.program = result;
                query();
            });
            Batch.getRequestedCreditToCsv({programId: $stateParams.programId}, function (result) {
                vm.credits = result.map(function ($credit) {
                    return {
                        identification: formatIdentification($credit.identification),
                        name: $credit.name,
                        invoice: $credit.invoiceNumber + ' / ' + $credit.invoiceSerial,
                        externalId: $credit.externalId,
                        requestedValue: 'R$' + $filter('number')($credit.requestedValue, 2),
                        total: 'R$' + $filter('number')($credit.total, 2),
                        dueDate: $filter('date')(new Date($credit.dueDate), 'dd/MM/yyyy', 'UTC'),
                        requestedDate: $filter('date')(new Date($credit.requestedDate), 'dd/MM/yyyy', 'UTC'),
                        observation: $credit.observation
                    };
                });
            });
        } else {
            queryAll();
        }

        function formatIdentification($identification) {
            if ($identification.length > 11) {
                return $identification.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
            } else {
                return $identification.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
            }
        }

        vm.loading = true;

        function search() {
            query();
        }

        function query() {
            Batch.financial({
                programId: vm.program.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                providerName: vm.currentSearch,
                sort: sort()
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.batches = result.content;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function queryAll() {
            Batch.financial({size: 1000}, function (result) {
                vm.batches = result.content;
            });
        }

        vm.batchQuantity = 0;

        function selectCredit($batch, $credit) {
            if ($credit.selected) {
                $credit.selected = false;
                return;
            }
            $credit.selected = true;
            if (!$batch.selected) {
                $batch.selected = true;
                vm.batchQuantity + vm.batchQuantity + 1;
            }
        }

        function batchChanged($batch) {
            if ($batch.selected) {
                $batch.selected = false;
                vm.batchQuantity = vm.batchQuantity - 1;
                $batch.credits.forEach(function ($credit) {
                    $credit.selected = false;
                });
            } else {
                $batch.selected = true;
                vm.batchQuantity = vm.batchQuantity + 1;
                $batch.credits.forEach(function ($credit) {
                    $credit.selected = true;
                });
            }
        }

        function selectAll() {
            vm.allSelected = !vm.allSelected;
            if (vm.allSelected) {
                vm.companiesList = vm.companies;
                vm.batches.forEach(function ($batch) {
                    $batch.selected = true;
                    $batch.credits.forEach(function ($credit) {
                        $credit.selected = true;
                    });
                });
                vm.batchQuantity = vm.batches.length;
            } else {
                vm.batches.forEach(function ($batch) {
                    $batch.selected = false;
                    $batch.credits.forEach(function ($credit) {
                        $credit.selected = false;
                    });
                });
                vm.batchQuantity = 0;
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                pageExecuted: vm.pageExecuted,
                programId: vm.program.id,
                currentSearch: vm.currentSearch,
                tabIndex: vm.tabIndex
            });
        }

        vm.rejectSelectedBatches = function () {
            var selected_amount = vm.batches
                .filter(function ($batch) {
                    return $batch.selected === true;
                })
                .map(function ($batch) {
                    return $batch.credits;
                })
                .flat()
                .filter(function ($credit) {
                    return $credit.selected === true;
                })
                .length;

            if (selected_amount === 0) {
                return;
            }

            var batches = vm.batches
                .filter(function ($item) {
                    return $item.selected === true;
                })
                .map(function ($batch) {
                    return {
                        id: $batch.id,
                        credits: $batch.credits
                            .filter(function ($credit) {
                                return $credit.selected === true;
                            })
                            .map(function ($credit) {
                                return $credit.id;
                            })
                    };
                });

            $state.go('credit-detail-financial.batchesReject', {
                batches: batches
            });
        };

        vm.approveBatches = function () {
            if (vm.batchQuantity === 0) {
                return;
            }

            var $batchesId = vm.batches.filter(function ($item) {
                return $item.selected === true;
            }).map(function ($batch) {
                return $batch.id;
            });

            $state.go('credit-detail-financial.request-approve', {
                batchesId: $batchesId
            });
        };

        vm.acceptSelectedBatches = function () {
            if (vm.batchQuantity === 0) {
                return;
            }

            var batches = {
                ids: vm.batches.filter(function ($item) {
                    return $item.selected === true;
                }).map(function ($batch) {
                    return $batch.id;
                })
            };

            $state.go('credit-detail-financial.acceptBatches', {
                batches: batches
            });
        };

        vm.acceptBatches = function () {
            var batches = {
                ids: vm.batches.map(function ($batch) {
                    return $batch.id;
                })
            };

            $state.go('credit-detail-financial.acceptBatches', {
                batches: batches
            });
        };

        vm.goToApprovedCredits = function () {
            $state.go('credit-approved-detail-financial', {
                programId: vm.program.id
            });
        };

        vm.downloadCnab = function (batch) {
            Batch.getCnab({id: batch.id}, function (result) {
                var fileName = result.fileName;

                if (!fileName) {
                    fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".REM";
                }

                debugBase64(result, fileName);
            });
        };

        vm.generateCnab = function (batch) {

            Batch.generateCnab({id: batch.id}, function (result) {
                var fileName = result.fileName;
                if (!fileName) {
                    fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".REM";
                }
                noty.success($translate.instant('alert.credit.cnab.generate'));

                debugBase64(result, fileName);
            }, function (error) {
                noty.error($translate.instant('alert.credit.cnab.generate.error'));
            });
        }

        function debugBase64(response, fileName) {
            var byteCharacters = atob(response.file);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }

        function downloadInvoice(credit) {
            CreditInvoice.download({id: credit}, function (response) {

                    var byteCharacters = atob(response.data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    var file = new Blob([byteArray], {type: 'text/plain'});
                    var fileUrl = $window.URL.createObjectURL(file);

                    var link = document.createElement('a');

                    link.href = fileUrl;
                    link.download = response.name;
                    link.click();

            });

        }

    }
})();
