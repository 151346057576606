(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ApprovalProfile', ApprovalProfile);

    function ApprovalProfile($resource) {
        var resourceUrl = 'api/approval-profiles/:id/:first/:second';

        return $resource(resourceUrl, {id: '@id'}, {
            'getAll': {method: 'GET'},
            'get': {method: 'GET'},
            'update': {method: 'PUT'},
            'getByProgram': {method: 'GET', params: {second: 'programs'}, isArray: true}
        });
    }
})();
