(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ClientPanelController', ClientPanelController);

    function ClientPanelController($state, GiroAccount, Organization) {
        var vm = this;

        vm.goToClientInvoiceScreen = goToClientInvoiceScreen;
        vm.goToClientTitleScreen = goToClientTitleScreen;
        vm.showCustomerPainel = false;
        vm.customerPanel = false;

        GiroAccount.user(function (result) {
            vm.showCustomerPainel = result.isCustomer;
        });

        Organization.hasCustomerPanel (function (result) {
            vm.customerPanel = result.customerPanel;
        });

        function goToClientInvoiceScreen() {
            $state.go('client-invoices');
        }

        function goToClientTitleScreen() {
            $state.go('customer-titles-report');
        }

    }
})();
