/**
 * Created by rene on 8/2/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('invite-customer', {
                parent: 'entity',
                url: '/invite-customer?page&sort&query',

                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-customer/invite-customers.html',
                        controller: 'InviteCustomerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: undefined,
                    withUsers: 'no-users'
                },
                resolve: {
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            withUsers: $stateParams.withUsers
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-customer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invite-customer-approve', {
                parent: 'entity',
                url: '/invite-customer-approve?page&sort&query',

                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-customer/invite-customer-approve.html',
                        controller: 'InviteCustomerApproveController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: undefined,
                    withUsers: 'no-users'
                },
                resolve: {
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            withUsers: $stateParams.withUsers
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-customer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('customer-create-account', {
                parent: 'anonymous-entity',
                url: '/{token}/accept-customer-invite?companyName',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-customer/invite-customer-create-account.html',
                        controller: 'InviteCustomerCreateAccountController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    companyName: undefined
                },
                resolve: {
                    entity: function ($stateParams) {
                        return {
                            name: undefined,
                            email: undefined,
                            identification: undefined,
                            companyName: $stateParams.companyName
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-customer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('customer-account-created', {
                parent: 'anonymous-entity',
                url: '/customer-created',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-customer/invite-customer-account-created.html',
                        controller: 'InviteCustomerAccountCreatedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-customer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invite-customer-details', {
                parent: 'entity',
                url: '/invite-customer/detail/{id}',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos',
                    authorities: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invite-customer/invite-customer-details.html',
                        controller: 'InviteCustomerDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'EmailAddress', function ($stateParams, EmailAddress) {
                        return EmailAddress.getCustomerEmailAddressWithInvites({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'invite-customer',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invite-customer');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
