(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditApprovedDetailProviderController', CreditApprovedDetailProviderController);

    /** @ngInject **/
    function CreditApprovedDetailProviderController(Program, $state, Credit, Batch, previousState, paginationConstants, pagingParams, backState,
                                                    $stateParams, $uibModal, ParseLinks, GiroConstants) {
        var vm = this;

        vm.enableBack = $stateParams.enableBack;
        vm.cancelRef = $stateParams.cancelRef;
        vm.reportRef = $stateParams.reportRef;
        vm.detailRef = $stateParams.detailRef;

        vm.transition = transition;
        vm.backOpenCreditScreen = backOpenCreditScreen;
        vm.currency = GiroConstants.Constants.currency;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.program = undefined;
        vm.previousState = previousState;

        if ($stateParams.programId) {
            Program.getSimple({id: $stateParams.programId}, function (result) {
                vm.program = result;
                query();
            });
        } else {
            query();
        }

        vm.loading = true;

        function query() {
            Batch.providerWithEstimate({
                providerId: $stateParams.providerId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: 'requestedDate,desc'
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.batches = result.content;
                vm.credits = vm.credits = result.content.map(function ($credit) {
                    return {
                        identification: formatIdentification($credit.identification),
                        name: $credit.name,
                        invoice: $credit.invoiceNumber,
                        externalId: $credit.externalId,
                        requestedValue: $filter('number')($credit.requestedValue, 2),
                        total: $filter('number')($credit.total, 2),
                        dueDate: $filter('date')(new Date($credit.dueDate), 'dd/MM/yyyy', 'UTC'),
                        requestedDate: $filter('date')(new Date($credit.requestedDate), 'dd/MM/yyyy', 'UTC'),
                        observation: $credit.observation
                    }
                });
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function backOpenCreditScreen() {
            if (backState) {
                $state.go(backState.name || 'home', backState.params);
            } else {
                $state.go(previousState.name || 'home', previousState.params);
            }
        }

        vm.cancel = function ($id) {
            var currentStateData = {
                name: previousState.name || 'home',
                params: previousState.params,
                url: previousState.url
            };

            $state.go(vm.cancelRef, {id: $id, backState: currentStateData});
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                programId: vm.programId,
                providerId: $stateParams.providerId
            });
        }
    }
})();
