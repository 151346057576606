(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ImportedDataDocument', ImportedDataDocument);

    function ImportedDataDocument($resource) {
        var resourceUrl = 'api/imported-data-documents/:id/:first/:second/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'process': {method: 'POST'},
            'getAll': {method: 'GET'},
            'get': {method: 'GET'},
            'searchResults': {method: 'GET', params: {first: 'imported-data-results'}},
            'resume': {method: 'GET', params: {first: 'resume'}}
        });
    }
})();
