(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ProgramBuyer', ProgramBuyer);

    ProgramBuyer.$inject = ['$resource'];


    function ProgramBuyer ($resource) {
        var resourceUrl =  'api/program-buyers/:id/:action/:first/:second/:third';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'search': { method: 'GET', isArray: true, params: {action: 'search'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAnticipatedBalance': { method: 'GET', params: {first: 'anticipated', second: 'balance'}},
            'getProviderBalance': { method: 'GET', isArray: true, params: {first: 'provider', second: 'balance'}},
            'getProviderBalanceReport': { method: 'GET', params: {first: 'anticipated', second: 'report'}},
            'getAllProviderBalance': { method: 'GET', params: {first: 'all', second: 'provider', third: 'balance'}}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ProgramBuyerByProgramId', ProgramBuyerByProgramId);
    ProgramBuyerByProgramId.$inject = ['$resource'];

    function ProgramBuyerByProgramId($resource) {
        var resourceUrl = 'api/program-buyers/program/:id/:action';
    return $resource(resourceUrl, {}, {
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        },
        'getSimple': {
            method: 'GET',
            params: {action: 'simple'},
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        }
    })
}

})();
