(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditReviewController', CreditReviewController);

    /** @ngInject **/
    function CreditReviewController($uibModal, StringUtils, CreditReview, ParseLinks, paginationConstants, pagingParams, Program, ProgramProvider, ProgramProviderByProgramId, GiroConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadPrograms = loadPrograms;
        vm.loadProviders = loadProviders;
        vm.programSelected = programSelected;

        vm.programs = [];
        vm.program = undefined;
        vm.programId = undefined;
        vm.currency = GiroConstants.Constants.currency;

        vm.providers = [];
        vm.provider = undefined;
        vm.providerId = undefined;

        loadAllPrograms();
        loadAllProviders();

        //Selectize Program
        function loadAllPrograms() {
            Program.query(function (result) {
                vm.programs = vm.programs.concat(result.content);
            }, function () {
            });
        }

        function loadPrograms(query, callback) {
            Program.query({
                query: query
            }, function (result) {
                vm.programs = vm.programs.concat(result);
                callback(result);
            }, function () {
            });
        }

        function programSelected(id, model) {
            vm.program = model;
            if (id) {
                vm.programId = id;
                loadProvidersByProgramId(id);
            } else {
                vm.programId = undefined;
                loadAllProviders();
            }
        }

        //----------

        //Selectize Provider
        function loadAllProviders() {
            ProgramProvider.query(function (result) {
                vm.providers = vm.providers.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
            }, function () {
            });
        }

        function loadProvidersByProgramId(id) {
            ProgramProviderByProgramId.get({id: id}, function (result) {
                vm.providers = StringUtils.formatToNameAndIdentificationLabel(result.content);
            }, function () {
            });
        }

        function loadProviders(searchText, callback) {
            ProgramProvider.search({
                programId: vm.programId,
                searchText: searchText
            }, function (result) {
                vm.providers = StringUtils.formatListToNameAndIdentificationLabel(result);
                callback(result);
            }, function () {
            });
        }

        vm.providerSelected = providerSelected;

        function providerSelected(id, model) {
            vm.provider = model;
            if (id) {
                vm.providerId = id;
            } else {
                vm.providerId = undefined;
            }
        }

        //------------

        function loadAll(page) {

            CreditReview.query({
                providerFilter: vm.providerId,
                programFilter: vm.programId,
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.credits = data;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            loadAll(vm.page);
        }

        vm.search = function () {
            loadAll(vm.page);
        };

        loadAll(pagingParams.page);

        vm.accept = function ($id) {
            $uibModal.open({
                templateUrl: 'app/entities/credit/credit-review-accept-dialog.html',
                controller: 'CreditReviewAcceptController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['CreditReview', function (CreditReview) {
                        return CreditReview.get({id: $id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                removeCredit($id)
            }, function () {
            });
        };

        vm.reject = function ($id) {
            $uibModal.open({
                templateUrl: 'app/entities/credit/credit-review-reject-dialog.html',
                controller: 'CreditReviewRejectController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['CreditReview', function (CreditReview) {
                        return CreditReview.get({id: $id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
            }, function () {
            });
        };

        vm.delete = function ($id) {
            $uibModal.open({
                templateUrl: 'app/entities/credit/credit-delete-dialog.html',
                controller: 'CreditDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['Credit', function (Credit) {
                        return Credit.get({id: $id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                removeCredit($id);
            }, function () {
            });
        };

        function removeCredit($id) {
            var index = vm.credits.indexOf(function ($credit) {
                return $credit.id === $id
            });
            vm.credits.splice(index, 1);
        }

    }
})();
