(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AcceptTermsController', AcceptTermsController);

    AcceptTermsController.$inject = ['$window', '$location', '$localStorage', '$rootScope', '$state', '$uibModalStack', '$stateParams', '$q', 'AcceptTerms', 'Principal'];

    function AcceptTermsController($window, $location, $localStorage, $rootScope, $state, $uibModalStack, $stateParams, $q, AcceptTerms, Principal) {
        var vm = this;

        vm.acceptTerms = acceptTerms;

        $uibModalStack.dismissAll();

        function acceptTerms() {
            var deferred = $q.defer();
            var token = JSON.parse($stateParams.token);

            AcceptTerms.acceptTerms(token).then(function () {
                    $localStorage.authenticationToken = token.data;

                    Principal.identity(true).then(function (account) {
                        deferred.resolve(token);
                        if(!Principal.defaultCompanyDefined()) {
                            $state.go('home-company', {}, {reload: true});
                        } else if ((Principal.hasAnyAuthority(["ROLE_PROVIDER"]) || Principal.hasAnyAuthority(["ROLE_PROVIDER_CONSULT"]) && Principal.systemParameterEnabled("PROVIDER_PANEL"))) {
                            $state.go('provider-panel', {}, {reload: true});
                        } else if (Principal.hasAnyAuthority(["ROLE_CLIENT"]) && Principal.systemParameterEnabled("CLIENT_PANEL")) {
                            $state.go('client-panel', {}, {reload: true});
                        } else {
                            $state.go('home', {}, {reload: true});
                        }
                    });
                }
            )
        }
    }
})();
