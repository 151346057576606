(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('OrganizationManagerController', OrganizationManagerController);

    function OrganizationManagerController($timeout, $uibModal, $scope, $stateParams, $state, $q, entity, Organization) {
        var vm = this;

        vm.organization = entity;
        vm.clear = clear;
        vm.save = save;

        function clear () {
            $state.go('organization');
        }

        function save () {
            vm.isSaving = true;
            if (vm.organization.id) {
                Organization.update(vm.organization, onSaveSuccess, onSaveError);
            } else {
                Organization.save(vm.organization, function (result) {
                    if (result.jwt) {
                        $uibModal.open({
                            templateUrl: 'app/entities/organization/organization-show-token-dialog.html',
                            controller: 'OrganizationShowTokenController',
                            controllerAs: 'vm',
                            size: 'md',
                            resolve: {
                                entity: function () {
                                    return result.jwt;
                                }
                            }
                        }).result.then(function () {
                            $state.go('organization');
                        });
                    } else {
                        onSaveSuccess(result)
                    }
                }, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $state.go('organization');
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
