/**
 * Created by rene on 8/2/18.
 */
(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Invite', Invite);

    function Invite($resource) {
        var resourceUrl = 'api/invites/:id/:action';

        return $resource(resourceUrl, {id: '@id'}, {
            'confirm': {method: 'PUT', params: {action: 'confirm'}},
            'active': {method: 'PUT', params: {action: 'active'}},
            'close': {method: 'PUT', params: {action: 'close'}},
            'getInviteByToken': {method: 'GET', params: {action: 'status'}}
        });
    }
})();
