(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AcceptBatchesDialogController', AcceptBatchesDialogController);

    /** @ngInject **/
    function AcceptBatchesDialogController($translate, $timeout, $uibModalInstance, $stateParams, BatchOperation, noty) {
        var vm = this;

        vm.batches = $stateParams.batches;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.remove = function () {
            BatchOperation.acceptBatches(vm.batches, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess(result) {
            noty.success($translate.instant('message.batch.credit.approved'));
            $timeout(function () {
                $uibModalInstance.close(result);
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError(data) {
            vm.isSaving = false;
            $uibModalInstance.close(data);
        }
    }
})();
