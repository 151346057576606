(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('giroApiApp')
        .factory('StringUtils', StringUtils);

    StringUtils.$inject = ['$filter'];

    function StringUtils($filter) {
        return {
            formatListToNameAndIdentificationLabel: formatListToNameAndIdentificationLabel,
            formatToNameAndIdentificationLabel: formatToNameAndIdentificationLabel,
            formatListWithoutCanAnticipate: formatListWithoutCanAnticipate,
            formatWithoutCanAnticipate: formatWithoutCanAnticipate
        };

        function formatListToNameAndIdentificationLabel(companies) {
            for (var i = 0; i < companies.length; i++) {
                formatToNameAndIdentificationLabel(companies[i]);
            }
            return companies;
        }

        function formatListWithoutCanAnticipate(companies) {
            for (var i = 0; i < companies.length; i++) {
                companies[i]["presentationLabel"] = stringBuilderWithoutCanAticipate(companies[i]);
            }

            return companies;
        }

        function formatWithoutCanAnticipate(company) {

            company["presentationLabel"] = stringBuilderWithoutCanAticipate(company);


            return company;
        }

        function formatToNameAndIdentificationLabel(company) {
            company["presentationLabel"] = stringBuilder(company);

            return company;
        }

        function stringBuilder(company) {
            var text = '';

            if(company.name) {
                text = text.concat(company.name + ' - ')
            }

            if(company.identification) {
                text = text.concat(
                    company.identification.length > 11 ?
                        $filter('cnpj')(company.identification) : $filter('cpf')(company.identification)
                )
            }

            text = text.concat(company.canAnticipate ? ' - Antecipa' : ' - Não Antecipa');

            return text;
        }

        function stringBuilderWithoutCanAticipate(company) {
            var text = '';

            if(company.name) {
                text = text.concat(company.name + ' - ')
            }

            if(company.identification) {
                text = text.concat(
                    company.identification.length > 11 ?
                        $filter('cnpj')(company.identification) : $filter('cpf')(company.identification)
                )
            }

            return text;
        }

    }
})();
