(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Installment', Installment);

    Installment.$inject = ['$resource', 'DateUtils'];

    function Installment($resource) {
        var resourceUrl =  'api/installments/:id/:action';

        return $resource(resourceUrl, {id : '@id'}, {
            'report': {method: 'GET', params: {action: 'report'}}
        });
    }
})();
