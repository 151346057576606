(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BatchVisualizerRequestController', BatchVisualizerRequestController);

    function BatchVisualizerRequestController($stateParams, $timeout, $uibModalInstance, noty, entity, BankAccount, BatchOperation, CreditEstimate, $q, $translate, GiroConstants) {
        var vm = this;

        vm.batch = entity;
        vm.clear = clear;
        vm.request = request;
        vm.currency = GiroConstants.Constants.currency;
        vm.accounts = undefined;
        vm.isSaving = false;

        var credits = [];

        vm.loading = true;

        angular.forEach(entity.credits, function ($credit) {
            credits.push($credit.id);
        });

        CreditEstimate.request({credits: credits}, function (result) {
            vm.estimate = result;
            vm.estimates = result.estimates;
            vm.loading = false;

        }, function (error) {
            console.log(error);
            $uibModalInstance.dismiss('error');
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function request() {
            vm.isSaving = true;
            BatchOperation.request(entity, function (result) {
                noty.success($translate.instant('message.batch.credit.request'));
                vm.isSaving = false;
                $timeout(function () {
                    $uibModalInstance.close(result);
                }, 150);
            }, function (e) {
                vm.isSaving = false;
                $uibModalInstance.dismiss('error');
            });
        }
    }
})();
