(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ClientTitle', ClientTitle);

    function ClientTitle($resource) {
        var resourceUrl = 'api/client-title/search';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET'
            }
        });
    }
})();
