(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ApprovalProfileDeleteDialogController', ApprovalProfileDeleteDialogController);

    function ApprovalProfileDeleteDialogController($translate, $timeout, $uibModalInstance, entity, ApprovalProfile) {
        var vm = this;

        vm.approvalProfile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ApprovalProfile.delete({id: id},
                function () {
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    },150);
                });
        }
    }

})();
