(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('DashboardKpiController', DashboardKpiController);

    DashboardKpiController.$inject = [];

    function DashboardKpiController() {
        var vm = this;
    }
})();
