(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('OrganizationChangeTokenController', OrganizationChangeTokenController);

    function OrganizationChangeTokenController($uibModalInstance, entity, Organization) {
        var vm = this;

        vm.organization = entity;
        vm.clear = clear;
        vm.changeToken = changeToken;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function changeToken(id) {
            Organization.changeToken({
                id: id,
                token: vm.token
            }, function (result) {
                vm.jwt = result.jwt;
            });
        }
    }
})();
