(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('TeamMemberDialogController', TeamMemberDialogController);


    function TeamMemberDialogController($translate, $rootScope, $timeout, $scope, $state, entity, TeamMember, Person, Company, noty, StringUtils) {
        var vm = this;

        vm.personSelected = personSelected;
        vm.loadPersons = loadPersons;
        vm.companySelected = companySelected;
        vm.loadCompanies = loadCompanies;
        vm.clear = clear;
        vm.save = save;

        vm.teamMember = entity;
        vm.companies = [];
        vm.persons = [];

        if (vm.teamMember.company) {
            vm.companies.push(vm.teamMember.company);
        }

        if (vm.teamMember.person) {
            vm.persons.push(vm.teamMember.person);
        }

        Person.query(function (result) {
            vm.persons = vm.persons.concat(result);
            $timeout(function () {
                $rootScope.$broadcast('update.person');
            }, 250);

        });

        Company.search(function (result) {
            vm.companies = vm.companies.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
            $timeout(function () {
                $rootScope.$broadcast('update.company');
            }, 250);
        });

        function clear() {
            $state.go('^');
        }

        function loadCompanies(searchText, callback) {
            Company.search({q: searchText, limit: 50}, function (result) {
                vm.companies = vm.companies.concat(StringUtils.formatListToNameAndIdentificationLabel(result));
                callback(vm.companies);
            }, function () {
                callback(vm.companies);
            });
        }

        function personSelected(id, $model) {
            vm.teamMember.person = $model;
        }

        function loadPersons(name, callback) {
            Person.query({q: name, limit: 50}, function (result) {
                vm.persons = vm.persons.concat(result);
                callback(vm.persons);
            }, function () {
                callback(vm.persons);
            });
        }

        function companySelected(id, $model) {
            vm.teamMember.company = $model;
        }

        function save() {
            vm.isSaving = true;
            if (vm.teamMember.id !== null) {
                TeamMember.update(vm.teamMember, onSaveSuccess, onSaveError);
            } else {
                TeamMember.save(vm.teamMember, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('giroApiApp:teamMemberUpdate', result);

            $timeout(function () {
                $state.go('^');
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
