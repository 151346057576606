(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('CreditInvoice', CreditInovice);

    function CreditInovice($resource) {
        var resourceUrl = 'api/credits/:id/download';

        return $resource(resourceUrl, {id: '@id'}, {
            download: {method: 'GET'},
        });
    }
})();
