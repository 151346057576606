(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('provider-titles-report', {
                parent: 'entity',
                url: '/provider-titles/report?page&sort&released&titleNumber&invoiceNumber&canAnticipate&emissionDateStart&emissionDateEnd&debitDateStart&debitDateEnd&paymentDateStart&paymentDateEnd&dueDateStart&dueDateEnd&programProviderId&companyId&status&organizationId&buyerSearch&subsidiary&allCompanies',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER_CONSULT', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/provider-titles/provider-titles-report.html',
                        controller: 'ProviderTitleReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: undefined,
                    enableBack: true
                },
                resolve: {
                    defaultOrganization: function (Organization) {
                        return Organization.getCurrent().$promise;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('provider-title');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dashboard/provider-titles-report', {
                parent: 'webapp-redirect',
                url: '/dashboard/provider-titles/report?page&sort&released&titleNumber&invoiceNumber&canAnticipate&emissionDateStart&emissionDateEnd&debitDateStart&debitDateEnd&paymentDateStart&paymentDateEnd&dueDateStart&dueDateEnd&programProviderId&companyId&status&organizationId&buyerSearch&subsidiary&allCompanies',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER_CONSULT', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/provider-titles/provider-titles-report.html',
                        controller: 'ProviderTitleReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: undefined,
                    enableBack: false
                },
                resolve: {
                    defaultOrganization: function (Organization) {
                        return Organization.getCurrent().$promise;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('provider-title');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
