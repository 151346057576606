(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Notification', Notification);

    function Notification($resource) {
        var resourceUrl = 'api/notifications/:id/:first/:second/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'create': {method: 'POST'},
            'update': {method: 'PUT'},
            'get': {method: 'GET'},
            'getAll': {method: 'GET'},
            'getActive': {method: 'GET', isArray: true, params: {first: 'programs', second: '@second', third: 'active'}},
            'delete': {method: 'DELETE'}
        });
    }
})();
