(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ApprovalRequested', ApprovalRequested);

    function ApprovalRequested($resource) {
        var resourceUrl = 'api/approve-requests/:id/:first/:second';

        return $resource(resourceUrl, {id: '@id'}, {
            'requestMultiples': {method: 'POST', params: {first: 'request', second: 'multiples'}},
            'getOpen': {method: 'GET', params: {first: 'open'}},
            'approve': {method: 'PUT', params: {first: 'approve'}},
            'denied': {method: 'PUT', params: {first: 'denied'}}
        });
    }
})();
