(function () {
    'use strict';

    angular.module('giroApiApp')
        .controller('CompanyBankAccountApprovalController', CompanyBankAccountApprovalController);

    function CompanyBankAccountApprovalController($state, $timeout, $translate, $uibModal, BankAccount, entity) {
        var vm = this;

        vm.company = entity;
        vm.account = vm.company.bankAccount ? vm.company.bankAccount : {};

        vm.clear = clear;
        vm.approve = approve;

        if (vm.account.approved) {
            $uibModal.open({
                templateUrl: 'app/entities/company-bank-account/company-bank-account-approved-dialog.html',
                controller: 'CompanyBankAccountApprovedController',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static'
            }).result.then(function () {
                $state.go('home');
            });
        }

        function clear() {
            $state.go('home');
        }

        function approve() {
            vm.isSaving = true;
            BankAccount.approveCompanyBankAccount(
                {
                    id: vm.account.id,
                    second: vm.company.id
                },
                {
                    id: vm.account.id
                },
                onApproveSuccess, onApproveError
            );
        }

        function onApproveSuccess() {
            $timeout(function () {
                $state.go('home');
            });
            vm.isSaving = false;
        }

        function onApproveError() {
            vm.isSaving = false;
            vm.success = false;
        }
    }
})();
