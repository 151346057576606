(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditDetailProviderController', CreditDetailProviderController);

    /** @ngInject **/
    function CreditDetailProviderController($translate, $rootScope, noty, Program, $state, $localStorage, programProvider, Credit, Batch, Notification, paginationConstants,
                                            pagingParams, $stateParams, $uibModal, ParseLinks, Principal, GiroConstants) {
        var vm = this;

        vm.transition = transition;
        vm.createBatch = createBatch;
        vm.batchChanged = batchChanged;
        vm.goToExecutedCredits = goToExecutedCredits;
        vm.markNotificationAsSeen = markNotificationAsSeen;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 100;
        vm.program = undefined;
        vm.batchEnabled = Principal.systemParameterEnabled("BATCH_ENABLED");
        vm.providerStatus = undefined;
        vm.providerCanNotAnticipate = undefined;
        vm.allSelected = false;

        if ($stateParams.programId) {
            Program.getSimple({id: $stateParams.programId}, function (result) {
                vm.program = result;
                query();
            });
            loadNotifications();
        } else {
            queryAll();
        }

        programProvider.programs.filter(function ($item) {
            return $item.programId === $stateParams.programId
        }).forEach(function ($item) {
            vm.providerStatus = $item.providerStatus;
            vm.providerCanNotAnticipate = $item.providerCanNotAnticipate;
        });

        vm.currency = GiroConstants.Constants.currency;

        vm.loading = true;

        function query() {
            Credit.provider({
                providerId: $stateParams.providerId,
                programId: vm.program.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.credits = result.content;
                vm.loading = false;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function queryAll() {
            vm.readOnly = true;
            Credit.allProvider({
                providerId: $stateParams.providerId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, function (result, headers) {
                vm.readOnly = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.credits = result.content;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                programId: vm.programId,
                providerId: $stateParams.providerId
            }, {reload: true});
        }

        function createBatch() {
            var batch = {
                credits: [],
                value: 0
            };
            angular.forEach(vm.credits, function ($credit) {
                if ($credit.batched) {
                    batch.credits.push(angular.copy($credit));
                    batch.value += $credit.value;
                }
            });

            $uibModal.open({
                templateUrl: 'app/entities/credit-visualizer/batch-visualizer-request-dialog.html',
                controller: 'BatchVisualizerRequestController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return batch;
                    }
                }
            }).result.then(function () {
                noty.success($translate.instant('message.alert.batch.save'));
                vm.page = 0;
                transition();
            }, function () {
                vm.page = 0;
                transition();
            });
        }

        vm.batchQuantity = 0;

        function goToExecutedCredits() {
            $state.go('credit-approved-detail-provider', {
                providerId: $stateParams.providerId,
                programId: $stateParams.programId
            });
        }

        function batchChanged($credit) {
            if ($credit.batched) {
                $credit.batched = false;
                vm.batchQuantity = vm.batchQuantity - 1;
            } else {
                $credit.batched = true;
                vm.batchQuantity = vm.batchQuantity + 1;
            }
        }

        vm.selectAll = function () {
            vm.allSelected = !vm.allSelected;
            if (vm.allSelected) {
                vm.companiesList = vm.companies;
                vm.credits.forEach(function ($credit) {
                    $credit.batched = true;
                });
                vm.batchQuantity = vm.credits.length;
            } else {
                vm.companiesList = [];
                vm.credits.forEach(function ($credit) {
                    $credit.batched = false;
                });
                vm.batchQuantity = 0;
            }
        };

        vm.openIntercom = function () {
            Intercom('show');
        }

        function loadNotifications() {
            Notification.getActive({second: $stateParams.programId}, onSuccess);

            function onSuccess($result) {
                vm.notifications = $result.filter(
                    function ($item) {
                        return !$localStorage.notifications.some(
                            function ($seen) {
                                return $seen === $item.id;
                            }
                        )
                    }
                );

            }
        }


        function markNotificationAsSeen($notification) {
            if (!$localStorage.notifications.some(
                function ($item) {
                    return $item === $notification.id
                }
            )) {
                $localStorage.notifications.push($notification.id);
            }
            vm.notifications = vm.notifications.filter(
                function ($item) {
                    return $item.id !== $notification.id;
                }
            )
        }


    }
})();
