(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('blocked-emails', {
                parent: 'anonymous-entity',
                url: '/blocked-emails?id&type',
                data: {
                    pageTitle: 'Giro - Central Eletrônica de Créditos'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/blocked-email/blocked-email.html',
                        controller: 'BlockedEmailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: function ($stateParams) {
                        return {
                            id: $stateParams.id

                        }
                    },
                    translatePartialLoader: function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('blockedEmail');
                        return $translate.refresh();
                    }
                }
            })
    }
})();
