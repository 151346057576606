/**
 * Created by rene on 8/2/18.
 */
(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('SessionTerm', SessionTerm);

    function SessionTerm($resource) {
        var resourceUrl = 'api/session-terms/:id/:first';

        return $resource(resourceUrl, {id: '@id'}, {
            'getSessionTermFile': {method: 'GET', params: {first: 'file'}},
            'getSessionTerms': {method: 'GET'},
            "generateSessionTerm": {method: 'PUT', params: {first: 'generate'}}
        });
    }
})();
