(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BlockedEmailController', BlockedEmailController);

    /** @ngInject **/
    function BlockedEmailController($translate, BlockedEmail, noty, $state, $stateParams) {
        var vm = this;

        vm.onSaveError = onSaveError;
        vm.clear = clear;

        vm.blockedEmail = {};

        angular.forEach(vm.blockedEmail.reason, function (result) {
            if (result) {
                noty.error($translate.instant('error.error.blocked.email.empty'));
            }
        });

        function clear() {
            $state.go('home');
        }

        vm.personId = undefined;
        vm.emailAddressId = undefined;

        vm.blocked = function () {
            if ($stateParams.type === 'USER') {
                vm.personId = $stateParams.id
            } else {
                vm.emailAddressId = $stateParams.id
            }

            BlockedEmail.blocked(
                {
                    personId: vm.personId,
                    emailAddressId: vm.emailAddressId
                }, vm.blockedEmail, function () {
                    onSaveSuccess();
                })
        };

        function onSaveSuccess() {
            noty.success($translate.instant('error.alert.blocked.email'));
            $state.go('home');
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }

})();
