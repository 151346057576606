(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditReviewAcceptController', CreditReviewAcceptController)
        .controller('CreditReviewRejectController', CreditReviewRejectController);


    /** @ngInject **/
    function CreditReviewAcceptController($uibModalInstance, entity, CreditReview) {
        var vm = this;

        vm.credit = entity;
        vm.clear = clear;
        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm(id) {
            CreditReview.accept({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }


    /** @ngInject **/
    function CreditReviewRejectController($uibModalInstance, entity, CreditReview) {
        var vm = this;

        vm.credit = entity;
        vm.clear = clear;
        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm(id) {
            CreditReview.reject({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
