(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('organization.witness', {
                parent: 'entity',
                url: '/witness/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/signer/witness-manager.html',
                        controller: 'WitnessManagerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    organization: function ($stateParams) {
                        return {id: $stateParams.id};
                    },
                    witness: function (Signer, $stateParams) {
                        return Signer.getByOrganization({second: $stateParams.id}).$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('witness-manager');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('signer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('signer-manager', {
                parent: 'entity',
                url: '/signer/{id}',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/signer/signer-manager.html',
                        controller: 'SignerManagerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    isWebapp: false
                },
                resolve: {
                    signers: function (Signer) {
                        return Signer.getByCompany().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('signer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dashboard/signer-manager', {
                parent: 'webapp-redirect',
                url: '/dashboard/signer/{id}',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/signer/signer-manager.html',
                        controller: 'SignerManagerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    isWebapp: true
                },
                resolve: {
                    signer: function (Signer) {
                        return Signer.getByCompany().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('signer');
                        return $translate.refresh();
                    }]
                }
            })

    }
})();
