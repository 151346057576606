(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT'],
                    avoid: {role: 'ROLE_ADMIN', route: 'home-admin'}
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    },
                    'buyerPanel@': {
                        templateUrl: 'app/entities/buyer-panel/buyer-panel.html',
                        controller: 'BuyerPanelController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('home-company', {
                parent: 'app',
                url: '/select-company',
                data: {
                    authorities: ['ROLE_USER'],
                    avoid: {role: 'ROLE_ADMIN', route: 'home-admin'}
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home-company.html',
                        controller: 'HomeCompanyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('home.wishAnticipation', {
                parent: 'home',
                url: '/wish/anticipation',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LAWYER', 'ROLE_CUSTOMER']
                },
                params: {
                    id: undefined
                },
                onEnter: function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/home/home-anticipation-wish.html',
                        controller: 'HomeAnticipationWishController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('wish-anticipation');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('home');
                    }, function () {
                        $state.go('home');
                    });
                }
            });
    }
})();
