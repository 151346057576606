(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Person', Person);

    function Person($resource) {
        var resourceUrl = 'api/persons/:id/:type/:action';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'search': {
                method: 'GET',
                params: {type: 'search'}
            },
            'update': {method: 'PUT'},
            'settings': {
                method: 'PUT',
                params: {type: 'settings'}
            },
            'resendActivationEmail': {
                method: 'POST',
                params: {type: 'emails', action: 'activation'}
            },
            'createPersonByInvite': {
                method: 'POST',
                params: {type: 'invite'}
            },
            'createCustomerPersonByInvite': {
                method: 'POST',
                params: {type: 'customer-invite'}
            }
        });
    }

    angular
        .module('giroApiApp')
        .factory('PersonAddress', PersonAddress);

    function PersonAddress($resource) {
        var resourceUrl = 'api/addresses/person/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'}
        });
    }

    angular
        .module('giroApiApp')
        .factory('PersonPhones', PersonPhones);

    function PersonPhones($resource) {
        var resourceUrl = 'api/phones/person/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'}
        });
    }

    angular
        .module('giroApiApp')
        .factory('PersonLogin', PersonLogin);

    function PersonLogin($resource) {
        var resourceUrl = 'api/persons/user/:login';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'}
        });
    }
})();
