(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account', 'Organization', 'Company', '$rootScope', '$state'];

    function Principal($q, Account, Organization, Company, $rootScope, $state) {
        var _identity, _parameters, _defaultCompanies = [],
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            systemParameterEnabled: systemParameterEnabled,
            hasAuthority: hasAuthority,
            identity: identity,
            authenticated: authenticated,
            isIdentityResolved: isIdentityResolved,
            defaultCompanyDefined: defaultCompanyDefined,
            setDefaultCompany: setDefaultCompany,
            getDefaultCompany: getDefaultCompany,
            haveMultipleCompanies: haveMultipleCompanies
        };

        return service;

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function defaultCompanyDefined() {
            if (!_authenticated || !_defaultCompanies || _defaultCompanies.length === 0) {
                return false;
            }
            for (var i = 0; i < _defaultCompanies.length; i++) {
                if (_defaultCompanies[i].isDefault) {
                    return true;
                }
            }
            if (_defaultCompanies.length === 1) {
                Company.companyDefault(_defaultCompanies[0], function () {
                    setDefaultCompany(_defaultCompanies[0]);
                    return true;
                }, function () {
                    return false
                });
            } else {
                return false;
            }
        }

        function haveMultipleCompanies() {
            return (_defaultCompanies !== undefined && _defaultCompanies.length > 1);
        }

        function getDefaultCompany() {
            if (!_authenticated || !_defaultCompanies || _defaultCompanies.length === 0) {
                return undefined;
            }
            for (var i = 0; i < _defaultCompanies.length; i++) {
                if (_defaultCompanies[i].isDefault) {
                    return _defaultCompanies[i];
                }
            }
        }

        function setDefaultCompany(company) {
            for(var i = 0; i < _defaultCompanies.length; i++){
                if(_defaultCompanies[i].id === company.id){
                    _defaultCompanies[i].isDefault = true;
                    break;
                }
            }
            $rootScope.$broadcast('defaultCompanyChanged', company.name);
        }

        function systemParameterEnabled(systemParameter) {
            if (!_authenticated || !_parameters) {
                return false;
            }

            switch (systemParameter) {
                case 'CLIENT_ACCESS_CONFIRMATION':
                    return _parameters.clientAccessConfirmation;
                    break;
                case 'PROVIDER_ACCESS_CONFIRMATION':
                    return _parameters.providerAccessConfirmation;
                    break;
                case 'PROVIDER_PANEL':
                    return _parameters.providerPanel;
                    break;
                case 'CLIENT_PANEL':
                    return _parameters.clientPanel;
                    break;
                case 'BATCH_ENABLED':
                    return _parameters.batchEnabled;
                    break;
            }
            return true;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }


            return this.identity().then(function (_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function () {
                return false;
            });
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.

            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            Organization.getParameters().$promise
                .then(getParametersThen)
                .catch(getParametersCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account.data;
                _authenticated = true;
                Company.userCompanies().$promise
                    .then(getDefaultCompanyThen)
                    .catch(getDefaultCompanyCatch);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }

            function getParametersThen(parameters) {
                _parameters = parameters;
            }

            function getParametersCatch() {
                _parameters = undefined;
            }

            function getDefaultCompanyThen(companies) {
                _defaultCompanies = companies;
                $rootScope.$broadcast('userCompaniesInPrincipal');

                deferred.resolve(_identity);
                if(!defaultCompanyDefined() && !hasAnyAuthority(["ROLE_ADMIN"])) {
                    $state.go('home-company');
                }
            }

            function getDefaultCompanyCatch() {
                _parameters = [];
            }

        }

        function authenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }
    }
})();
