(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('CompanyProvider', CompanyProvider);

    function CompanyProvider ($resource) {
        var resourceUrl = 'api/companies-provider/:id/:action/:type';

        return $resource(resourceUrl, {id: '@id'}, {
            'queryInviteProvider': {
                method: 'GET',
                params: {type: 'invite', action: 'provider'}
            },
            'queryInviteProviderToApprove': {
                method: 'GET',
                params: {type: 'invite-to-approve', action: 'provider'}
            },
            'authorizeAnticipate': {
                method: 'PUT',
                params: {type: 'anticipate', action: 'authorize'}
            },
            'anticipateBlocked': {
                method: 'PUT',
                params: {type: 'blocked', action: 'anticipate'}
            },
            'getCompaniesForProvider': {
                method: 'GET',
                params: {type: 'company', action: 'for'}
            },
            'getCompanies': {
                method: 'GET',
                params: {action: 'companies'}
            },
            'getCompany': {
                method: 'GET',
                params: {action: 'company'}
            },
        });
    }
})();
