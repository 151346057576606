(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDataDocumentsController', ImportedDataDocumentsController);

    /** @ngInject **/
    function ImportedDataDocumentsController($uibModal, $stateParams, $state, ImportedDataDocument, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filter = {
            search: pagingParams.search,
            type: pagingParams.type
        };
        vm.documents = [];

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;

        loadAll(pagingParams.page);

        function loadAll(page) {

            ImportedDataDocument.getAll({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filter.search,
                type: vm.filter.type
            }, onSuccess, onError);


            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.documents = data.content;
                vm.page = page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.filter.search,
                type: vm.filter.type
            });
        }

        function search() {
            transition();
        }
    }
})();
