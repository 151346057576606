(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditDetailBuyerController', CreditDetailBuyerController);

    /** @ngInject **/
    function CreditDetailBuyerController(noty, $location, Program, $state, $stateParams, pagingParams, paginationConstants, Batch, Credit, ParseLinks, GiroConstants) {
        var vm = this;

        vm.transition = transition;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.predicateExecuted = pagingParams.predicateExecuted;
        vm.reverseExecuted = pagingParams.ascendingExecuted;
        vm.currency = GiroConstants.Constants.currency;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.tabIndex = pagingParams.tabIndex;

        vm.program = undefined;
        if ($stateParams.programId) {
            Program.getSimple({id: $stateParams.programId}, function (result) {
                vm.program = result;
                query();
            });
        } else {
            queryAll();
        }

        vm.loading = true;

        function query() {
            Credit.buyer({
                programId: vm.program.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.credits = result.content;
            });
            Batch.buyer({
                programId: vm.program.id,
                page: pagingParams.pageExecuted - 1,
                size: vm.itemsPerPage,
                sort: sortExecuted()
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsExecuted = headers('X-Total-Count');
                vm.queryCount = vm.totalItemsExecuted;
                vm.pageExecuted = pagingParams.pageExecuted;
                vm.batches = result.content;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function sortExecuted() {
                var result = [vm.predicateExecuted + ',' + (vm.reverseExecuted ? 'asc' : 'desc')];
                if (vm.predicateExecuted !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function queryAll() {
            Credit.buyer({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.credits = result.content;
            });

            Batch.buyer({
                page: pagingParams.pageExecuted - 1,
                size: vm.itemsPerPage
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItemsExecuted = headers('X-Total-Count');
                vm.queryCount = vm.totalItemsExecuted;
                vm.pageExecuted = pagingParams.pageExecuted;
                vm.batches = result.content;
            });
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                pageExecuted: vm.pageExecuted,
                sortExecuted: vm.predicateExecuted + ',' + (vm.predicateExecuted ? 'asc' : 'desc'),
                programId: vm.program.id,
                tabIndex: vm.tabIndex
            });
        }
    }
})();
