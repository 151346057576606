(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SaleStatusDeleteController', SaleStatusDeleteController);

    function SaleStatusDeleteController($uibModalInstance, entity, SaleStatus) {
        var vm = this;

        vm.saleStatus = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            SaleStatus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
