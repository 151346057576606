(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProviderTitleController', ProviderTitleController);

    function ProviderTitleController($state, PrintFile, Principal, noty, ProviderTitle, ParseLinks, pagingParams, ProgramProvider, GiroConstants) {
        var vm = this;

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.backToProviderPanel = backToProviderPanel;
        vm.printPaymentVoucher = printPaymentVoucher;
        vm.goToCreditVizualizer = goToCreditVizualizer;

        vm.currency = GiroConstants.Constants.currency;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 10;
        vm.currentSearch = '';
        vm.statusSearch = 'ALL';

        loadAll(pagingParams.page);


        function printPaymentVoucher(paymentVoucher) {
            switch (paymentVoucher.bankName) {
                case 'BANCO ITAU S.A.':
                    PrintFile.printExternalFile('../../../content/paymentvouchers/itau.html', paymentVoucher);
                    break;
                case 'BANCO SANTANDER S.A.':
                    PrintFile.printExternalFile('../../../content/paymentvouchers/santander.html', paymentVoucher);
                    break;
                default:
                    PrintFile.printExternalFile('../../../content/paymentvouchers/default.html', paymentVoucher);
                    break;
            }
            noty.success('Comprovante de pagamento emitido, caso não apareça verifique se seu browser não bloqueou a abertura da nova janela.');
        }

        function loadAll(page) {
            ProviderTitle.query({
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                searchText: vm.currentSearch,
                searchForPayments: vm.statusSearch === 'ALL' ? undefined : vm.statusSearch === 'PAID'
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.providerTitles = data.content;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            loadAll(vm.page);
        }

        function search() {
            loadAll(vm.page);
        }

        function goToCreditVizualizer() {
            ProgramProvider.resume(function (result) {
                if(!result.anticipate) {
                    noty.error("Você não possuí recebíveis disponíveis para antecipar.");
                    return;
                }

                if (result.programs.length === 1) {
                    $state.go('credit-detail-provider', {
                        providerId: result.programs[0].programProviderId,
                        programId: result.programs[0].programId
                    });
                } else if(result.programs.length > 1){
                    $state.go('provider-programs');
                } else {
                    noty.error("Você não possuí recebíveis disponíveis para antecipar.");
                }
            });
        }

        function backToProviderPanel() {
            $state.go('provider-panel');
        }
    }
})();
