(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('TeamMemberDetailController', TeamMemberDetailController);

    function TeamMemberDetailController($scope, $rootScope, $stateParams, previousState, entity, TeamMember, Person, Company) {
        var vm = this;

        vm.teamMember = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('giroApiApp:teamMemberUpdate', function(event, result) {
            vm.teamMember = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
