(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InvoiceController', InvoiceController);

    function InvoiceController($state, Invoice, Principal, ParseLinks, pagingParams) {
        var vm = this;
        // if(!Principal.systemParameterEnabled("PROVIDER_PANEL")){
        //     $state.go('home');
        // }

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.backToProviderPanel = backToProviderPanel;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 20;
        vm.currentSearch = '';

        loadAll(pagingParams.page);

        function loadAll(page) {
            Invoice.query({
                isArray: false,
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                searchText: vm.currentSearch
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.invoices = data.content;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            loadAll(vm.page);
        }

        function search() {
            loadAll(vm.page);
        }

        function backToProviderPanel() {
            $state.go('provider-panel');
        }
    }
})();
