(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SignerManagerController', SignerManagerController);

    /** @ngInject **/
    function SignerManagerController($state, $stateParams, Signer, signers) {
        var vm = this;
        vm.signers = signers;
        vm.isWebapp = $stateParams.isWebapp;

        if (vm.signers.length > 0) {
            vm.signers.map(function (value) {
                value.birthday = new Date(Date.parse(value.birthday));
            });
        }

        vm.birthday = {
            datepickerOptions: {
                maxDate: new Date()
            },
            ngModelOptions: {
                timezone: 'UTC'
            }
        };

        vm.addSigner = function (editForm) {
            vm.signers.push({birthdayStatus: false});
        };

        vm.removeSigner = function ($index) {
            vm.signers.splice($index, 1);
        };

        vm.openCalendar = function ($signer) {
            $signer.birthdayStatus = true;
        };

        vm.save = function () {
            Signer.save({signers: vm.signers}, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess() {
            vm.isSaving = false;

            if (vm.isWebapp) return;
            $state.go('home');
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = null;
        }

        vm.clear = function () {
            if (vm.isWebapp) return;

            $state.go('home');
        }
    }
})();
