(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .factory('GiroAccount', GiroAccount);

    GiroAccount.$inject = ['$resource'];

    function GiroAccount ($resource) {
        var service = $resource('api/account/:action', {}, {
            'authorities': { method: 'GET', params: { action: 'authorities' }, isArray: true },
            'user': { method: 'GET', params: { action: 'resume-user' }, isArray: false },
            'defaultCompany': { method: 'GET', params: { action: 'default-company' }, isArray: false }
        });

        return service;
    }
})();
