(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ManageJob', ManageJob);

    function ManageJob($resource) {
        var resourceUrl = 'api/admin-jobs/:action';

        return $resource(resourceUrl, {}, {
            'processImportedDocument': {
                method: 'PUT',
                params: {action: 'process-imported-document'}
            },
            'expireCredits': {
                method: 'PUT',
                params: {action: 'expire-credits'}
            },
            'recalculateCredits': {
                method: 'PUT',
                params: {action: 'recalculate-credits'}
            },
            'sendDailyCreditAvailable': {
                method: 'PUT',
                params: {action: 'send-daily-credit-available'}
            },
            'updateProviderAuthorityRole': {
                method: 'PUT',
                params: {action: 'update-provider-authority-role'}
            },
            'inviteCompanyWithoutUserAndInvite': {
                method: 'PUT',
                params: {action: 'invite-companies-without-user'}
            },
            'removeImporterHash': {
                method: 'PUT',
                params: {action: 'remove-importer-hash'}
            },
            'sendWhatsappInformative': {
                method: 'PUT',
                params: {action: 'resend-whatsapp-informative'}
            },
            'reprocessImportedDocument': {
                method: 'PUT',
                params: {action: 'reprocess-imported-document'}
            },
            'expireOverdueCredit': {
                method: 'PUT',
                params: {action: 'expire-overdue-credit'}
            }
        });
    }
})();
