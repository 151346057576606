(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ExportedDataDocument', ExportedDataDocument);

    function transformDownload(data, headerGetter) {
        var headers = headerGetter();
        var $content_disposition = headers['content-disposition'];
        var $file_name = $content_disposition && $content_disposition.split('filename=')[1];
        var $content_type = headers['content-type'];

        return {data: data, fileName: $file_name, contentType: $content_type};
    }

    function ExportedDataDocument($resource) {
        var resourceUrl = 'api/exported-data-documents/:id/:first/:second/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'processConciliation': {method: 'POST', params: {first: 'conciliation'}},
            'status': {method: 'GET', params: {first: 'status'}},
            'download': {method: 'GET', responseType: 'arraybuffer', transformResponse: transformDownload, params: {first: 'download'}}
        });
    }
})();
