(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditVisualizerRejectController', CreditVisualizerRejectController);

    /** @ngInject **/
    function CreditVisualizerRejectController($translate, $timeout, $uibModalInstance, entity, noty, BatchOperation, GiroConstants) {
        var vm = this;

        vm.batch = entity;
        vm.clear = clear;
        vm.reject = reject;
        vm.reason = undefined;
        vm.currency = GiroConstants.Constants.currency;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function reject () {
            BatchOperation
                .reject({reason: vm.reason}, {id: vm.batch.id}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            noty.success($translate.instant('message.batch.credit.reject'));
            $timeout(function () {
                $uibModalInstance.close(result);
            },150);
            vm.isSaving = false;
        }

        function onSaveError (data) {
            vm.isSaving = false;
            $uibModalInstance.close(data);
        }
    }
})();
