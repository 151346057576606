/**
 * Created by rene on 12/28/17.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AnticipationPanelController', AnticipationPanelController);

    /** @ngInject **/
    function AnticipationPanelController($state, $stateParams, Program, ProgramProvider, ParseLinks, noty, pagingParams, paginationConstants, GiroConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.currency = GiroConstants.Constants.currency;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.programs = [];
        vm.program = undefined;
        vm.programId = $stateParams.programId;
        vm.showProgramOnTable = !vm.programId;

        vm.anticipations = [];

        vm.status = $stateParams.status;
        vm.sendInvite = $stateParams.sendInvite;
        vm.userActive = $stateParams.userActive;
        vm.canAnticipate = $stateParams.canAnticipate;
        vm.total = $stateParams.total;
        vm.anticipateTitlesNumber = $stateParams.anticipateTitlesNumber;
        vm.query = $stateParams.query;

        vm.datePickerStartOpenStatus = false;
        vm.datePickerEndOpenStatus = false;

        vm.datePickerDueMaxStartOpenStatus = false;
        vm.datePickerDueMaxEndOpenStatus = false;

        vm.datePickerDueMinStartOpenStatus = false;
        vm.datePickerDueMinEndOpenStatus = false;

        vm.datePickerCreditCreatedDateMaxStartOpenStatus = false;
        vm.datePickerCreditCreatedDateMaxEndOpenStatus = false;

        vm.datePickerCreditCreatedDateMinStartOpenStatus = false;
        vm.datePickerCreditCreatedDateMinEndOpenStatus = false;

        if ($stateParams.startDate) {
            vm.startDate = new Date($stateParams.startDate);
        }

        if ($stateParams.endDate) {
            vm.endDate = new Date($stateParams.endDate);
        }

        if ($stateParams.dueMaxStart) {
            vm.dueMaxStart = new Date($stateParams.dueMaxStart);
        }

        if ($stateParams.dueMaxEnd) {
            vm.dueMaxEnd = new Date($stateParams.dueMaxEnd);
        }

        if ($stateParams.dueMinStart) {
            vm.dueMinStart = new Date($stateParams.dueMinStart);
        }

        if ($stateParams.dueMinEnd) {
            vm.dueMinEnd = new Date($stateParams.dueMinEnd);
        }

        if ($stateParams.creditCreatedDateMaxStart) {
            vm.creditCreatedDateMaxStart = new Date($stateParams.creditCreatedDateMaxStart);
        }

        if ($stateParams.creditCreatedDateMaxEnd) {
            vm.creditCreatedDateMaxEnd = new Date($stateParams.creditCreatedDateMaxEnd);
        }

        if ($stateParams.creditCreatedDateMinStart) {
            vm.creditCreatedDateMinStart = new Date($stateParams.creditCreatedDateMinStart);
        }

        if ($stateParams.creditCreatedDateMinEnd) {
            vm.creditCreatedDateMinEnd = new Date($stateParams.creditCreatedDateMinEnd);
        }

        vm.searchKeyPress = function (keyEvent) {
            if (keyEvent.which === 13) {
                vm.findAnticipations();
            }
        };

        vm.findAnticipations = function () {
            if (!vm.program && !vm.query) {
                noty.error("Selecione um programa ou busque uma empresa");
                return;
            }
            transition();
        };

        function find() {
            ProgramProvider.anticipations({
                programId: vm.programId,
                creditValidateInit: vm.startDate,
                creditValidateEnd: vm.endDate,
                status: vm.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sendInvite: vm.sendInvite,
                userActive: vm.userActive,
                canAnticipate: vm.canAnticipate,
                total: vm.total,
                anticipateTitlesNumber: vm.anticipateTitlesNumber,
                dueMaxStart: vm.dueMaxStart,
                dueMaxEnd: vm.dueMaxEnd,
                dueMinStart: vm.dueMinStart,
                dueMinEnd: vm.dueMinEnd,
                creditCreatedDateMaxStart: vm.creditCreatedDateMaxStart,
                creditCreatedDateMaxEnd: vm.creditCreatedDateMaxEnd,
                creditCreatedDateMinStart: vm.creditCreatedDateMinStart,
                creditCreatedDateMinEnd: vm.creditCreatedDateMinEnd,
                search: vm.query,
                sort: sort()
            }, function (result, headers) {
                vm.anticipations = result.content;
                vm.links = ParseLinks.parse(headers('link'));
                //vm.totalItems = headers('X-Total-Count');
                vm.totalItems = result.totalElements;
                vm.queryCount = vm.totalItems;
                vm.page = result.number + 1;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        if (vm.query || vm.programId) {
            find();
        }
        if (vm.programId) {
            loadProgram();
        } else {
            loadAllPrograms();
        }

        function loadProgram() {
            Program.getSimple({id: vm.programId}, function (result) {
                vm.programs = vm.programs.concat(result);
                vm.program = result;
            });
        }

        //Selectize Program
        function loadAllPrograms() {
            Program.querySimple(function (result) {
                vm.programs = vm.programs.concat(result.content);
            }, function () {
            });
        }

        vm.loadPrograms = function (query, callback) {
            Program.querySimple({
                query: query
            }, function (result) {
                vm.programs = vm.programs.concat(result.content);
                callback(result);
            }, function () {
            });
        };

        vm.programSelected = function ($id, $model) {
            vm.program = $model;
            if ($id) {
                vm.programId = $id;
            } else {
                vm.programId = undefined;
            }
        };

        //----------

        vm.openStartCalendar = function () {
            vm.datePickerStartOpenStatus = true;
        };

        vm.openEndCalendar = function () {
            vm.datePickerEndOpenStatus = true;
        };

        vm.openDueMaxStartCalendar = function () {
            vm.datePickerDueMaxStartOpenStatus = true;
        };

        vm.openDueMaxEndCalendar = function () {
            vm.datePickerDueMaxEndOpenStatus = true;
        };

        vm.openDueMinStartCalendar = function () {
            vm.datePickerDueMinStartOpenStatus = true;
        };

        vm.openDueMinEndCalendar = function () {
            vm.datePickerDueMinEndOpenStatus = true;
        };

        vm.openCreditCreatedDateMaxStartCalendar = function () {
            vm.datePickerCreditCreatedDateMaxStartOpenStatus = true;
        };

        vm.openCreditCreatedDateMaxEndCalendar = function () {
            vm.datePickerCreditCreatedDateMaxEndOpenStatus = true;
        };

        vm.openCreditCreatedDateMinStartCalendar = function () {
            vm.datePickerCreditCreatedDateMinStartOpenStatus = true;
        };

        vm.openCreditCreatedDateMinEndCalendar = function () {
            vm.datePickerCreditCreatedDateMinEndOpenStatus = true;
        };

        vm.reset = function () {
            vm.startDate = undefined;
            vm.endDate = undefined;
            vm.status = undefined;
            vm.sendInvite = undefined;
            vm.userActive = undefined;
            vm.canAnticipate = undefined;
            vm.total = undefined;
            vm.anticipateTitlesNumber = undefined;
            vm.dueMaxStart = undefined;
            vm.dueMaxEnd = undefined;
            vm.dueMinStart = undefined;
            vm.dueMinEnd = undefined;
            vm.creditCreatedDateMaxStart = undefined;
            vm.creditCreatedDateMaxEnd = undefined;
            vm.creditCreatedDateMinStart = undefined;
            vm.creditCreatedDateMinEnd = undefined;
            vm.query = undefined;
            transition();
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                programId: vm.programId,
                startDate: vm.startDate,
                endDate: vm.endDate,
                status: vm.status,
                sendInvite: vm.sendInvite,
                userActive: vm.userActive,
                canAnticipate: vm.canAnticipate,
                total: vm.total,
                anticipateTitlesNumber: vm.anticipateTitlesNumber,
                dueMaxStart: vm.dueMaxStart,
                dueMaxEnd: vm.dueMaxEnd,
                dueMinStart: vm.dueMinStart,
                dueMinEnd: vm.dueMinEnd,
                creditCreatedDateMaxStart: vm.creditCreatedDateMaxStart,
                creditCreatedDateMaxEnd: vm.creditCreatedDateMaxEnd,
                creditCreatedDateMinStart: vm.creditCreatedDateMinStart,
                creditCreatedDateMinEnd: vm.creditCreatedDateMinEnd,
                query: vm.query
            });
        }
    }
})();
