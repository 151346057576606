(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('LoadingBtn', LoadingBtn)
        .component('loadingBtn', {
            restrict: 'E',
            bindings: {
                text: '=',
                animate: '=',
                click: '&'
            },
            templateUrl: 'app/components/loading-button/loading-buton.html',
            controller: 'LoadingBtn',
            controllerAs: 'vm'
        });

    /** @ngInject */
    function LoadingBtn() {
        var vm = this;
    }

})();
