/**
 * Created by rene on 8/6/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteProviderAccountCreatedController', InviteProviderAccountCreatedController);

    /** @ngInject **/
    function InviteProviderAccountCreatedController($state, Organization) {
        var vm = this;

        Organization.getParameters().$promise
            .then(getParametersThen)
            .catch(getParametersCatch);

        function getParametersThen(parameters) {
            if (parameters.providerAccessConfirmation) {
                vm.message = 'Obrigado por fazer seu cadastro! Faremos a validação dos seus dados e retornaremos na sequência. Você receberá outro email com dados para acesso.';
            } else {
                $state.go('login');
            }
        }

        function getParametersCatch() {
        }

    }
})();
