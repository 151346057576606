(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyDeleteController',CompanyDeleteController);

    function CompanyDeleteController($translate, $timeout, $uibModalInstance, entity, Company, noty) {
        var vm = this;

        vm.company = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Company.delete({id: id},
                function () {
                    noty.alert($translate.instant('message.alert.company.delete'));
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    },150);
                });
        }
    }
})();
