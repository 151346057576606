(function() {
    'use strict';
    angular
        .module('giroApiApp')
        .controller('HolidayManagerController', HolidayManagerController);

    function HolidayManagerController($timeout, $uibModal, $scope, $stateParams, $state, $q, entity, Holiday) {
        var vm = this;

        vm.holiday = entity;
        vm.datePickerOpenStatus = {};
        vm.clear = clear;
        vm.save = save;

        if(vm.holiday.id) {
            vm.date = new Date(vm.holiday.date)
        }

        function clear () {
            $state.go('holiday');
        }

        function save () {
            vm.isSaving = true;
            vm.holiday.date = vm.date;
            if (vm.holiday.id) {
                Holiday.update(vm.holiday, onSaveSuccess, onSaveError);
            } else {
                Holiday.save(vm.holiday, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $state.go('holiday');
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }



    }
})();
