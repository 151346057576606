(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditVisualizerRemoveController', CreditVisualizerRemoveController);

    /** @ngInject **/
    function CreditVisualizerRemoveController($translate, $timeout, $uibModalInstance, entity, batchId, BatchOperation, GiroConstants, noty) {
        var vm = this;

        vm.credit = entity;
        vm.batchId = batchId;
        vm.reason = undefined;
        vm.currency = GiroConstants.Constants.currency;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.remove = function () {
            var batch = {
                id: vm.batchId,
                credits: [{
                    id: vm.credit.id
                }],
                reason: vm.reason
            };

            BatchOperation
                .remove({id: vm.batchId}, batch, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess(result) {
            noty.success($translate.instant('message.batch.credit.remove'));
            $timeout(function () {
                $uibModalInstance.close(result);
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError(data) {
            vm.isSaving = false;
            $uibModalInstance.close(data);
        }
    }
})();
