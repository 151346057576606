(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SaleStatusDetailController', SaleStatusDetailController);

    SaleStatusDetailController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity'];

    function SaleStatusDetailController($state, $scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.saleStatus = entity;

        vm.back = function () {
            $state.go('sale-status');
        }
    }
})();
