(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyDialogController', CompanyDialogController);

    function CompanyDialogController($translate, $timeout, $rootScope, DataUtils, $scope, Bank, $state, entity, Company, Address, BankAccount, TeamMember, Organization, City, CityUtils, Tag, uuid, noty) {
        var vm = this;
        vm.company = entity;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.clear = clear;
        vm.save = save;
        vm.addAddress = addAddress;
        vm.addPhone = addPhone;
        vm.addAccount = addAccount;
        vm.addSigner = addSigner;
        vm.loadBanks = loadBanks;
        vm.bankSelected = bankSelected;
        vm.removeAccount = removeAccount;
        vm.addCompanyProvider = addCompanyProvider;
        vm.removeCompanyProvider = removeCompanyProvider;
        vm.removeSigner = removeSigner;
        vm.organizationSelected = organizationSelected;
        vm.companyOrganizationSelected = companyOrganizationSelected;
        vm.loadOrganizations = loadOrganizations;
        vm.addOrganization = addOrganization;
        vm.removeOrganization = removeOrganization;
        vm.removeAddress = removeAddress;
        vm.loadCities = loadCities;
        vm.onCitySelected = onCitySelected;

        vm.loadTags = loadTags;
        vm.onTagsSelected = onTagsSelected;

        vm.createProviderInAdvance = createProviderInAdvance;

        vm.addExternalId = addExternalId;
        vm.removeExternalId = removeExternalId;
        vm.organizationSelectedForExternalId = organizationSelectedForExternalId;

        if (entity.accounts) {
            vm.bankAccount = entity.accounts[0];
        }

        if (!vm.company.photo) {
            vm.company.photo = {};
        }

        if (!vm.bankAccount) {
            vm.bankAccount = {};
        }

        vm.banks = [];
        vm.companyOrganizations = [];
        vm.organizations = [];
        vm.cities = [];

        if (vm.company && vm.company.id) {
            vm.phones = vm.company.phones;
            vm.emailAddresses = vm.company.emailAddresses;
            vm.selection = vm.company.authorities;

            if (vm.company.accounts) {
                angular.forEach(vm.company.accounts, function ($i) {
                    if ($i.bank) {
                        vm.banks.push($i.bank);
                        vm.banks.forEach(function (it) {
                            it.presentationLabel = it.number + ' - ' + it.name;
                        });
                    }
                });
                vm.accounts = vm.company.accounts;
            } else {
                vm.accounts = [{}];
            }

            if (vm.company.addresses) {
                vm.addresses = vm.company.addresses;
                vm.addresses
                    .filter(function (address) {
                        return !address.city;
                    })
                    .forEach(function (address) {
                        address.city = undefined;
                    });

                vm.addresses
                    .filter(function (address) {
                        return address.city;
                    })
                    .forEach(function (address) {
                        address.city = CityUtils.formatToCityNameAndStateAcronym(address.city);
                        vm.cities.push(address.city);
                    });

            } else {
                vm.addresses = [];
            }

            if (vm.company.companyProviders) {
                angular.forEach(vm.company.companyProviders, function ($i) {
                    $i.identifier = uuid.v4();
                    if ($i.organization) {
                        vm.organizations.push($i.organization);
                    }
                });
                vm.companyProviders = vm.company.companyProviders;
            }

            if (vm.company.signers) {
                angular.forEach(vm.company.signers, function ($i) {
                    $i.identifier = uuid.v4();
                    $i.birthday = new Date(Date.parse($i.birthday));
                    $i.birthdayStatus = false;
                });
                vm.signers = vm.company.signers;
            }

            if (vm.company.organizations) {
                angular.forEach(vm.company.organizations, function ($organization) {
                    if ($organization) {
                        vm.organizations.push($organization);
                    }
                });
                vm.companyOrganizations = vm.company.organizations.map(function (item) {
                    Tag.filter({organizationId: item.id}, function (result) {
                        vm.companyOrganizations = vm.companyOrganizations.map(function ($it) {
                            if (!$it.organization || $it.organization.id !== item.id) return $it;
                            $it.tags = $it.tags.concat(result.content);
                            return $it;
                        });
                    });
                    return {
                        organization: item, tags: [], selectedTags: !vm.company.tags ? [] :
                            vm.company.tags.filter(function (it) {
                                return item.id === it.organization.id;
                            })
                    };
                });


            }

            if (vm.company.externalIds) {
                angular.forEach(vm.company.externalIds, function ($i) {
                    $i.identifier = uuid.v4();
                    if ($i.organization) {
                        vm.organizations.push($i.organization);
                    }
                });
                vm.externalIds = vm.company.externalIds;
            }

        } else {
            vm.company.type = "INDIVIDUAL";
            vm.addresses = [];
            vm.phones = [];
            vm.accounts = [];
            vm.emailAddresses = [];
            vm.newEmailAddresses = [];
            vm.companyProviders = [];
            vm.signers = [];
            vm.companyOrganizations = [];
            vm.externalIds = [];
            loadAllOrganizations();
        }

        function clear() {
            $state.go('^');
        }

        function save() {
            vm.company.phones = vm.phones;
            vm.company.accounts = vm.accounts;
            vm.company.addresses = vm.addresses;
            vm.company.emailAddresses = vm.emailAddresses;
            vm.company.companyProviders = vm.companyProviders;
            vm.company.signers = vm.signers;
            vm.company.organizations = vm.companyOrganizations.map(function (item) {
                return item.organization;
            });

            vm.company.tags = vm.companyOrganizations
                .map(function (item) {
                    return item.selectedTags;
                })
                .filter(function (item) {
                    return item && item.length && item.concat;
                })
                .reduce(function (one, other) {
                    return one.concat(other);
                }, []);

            if (vm.signerExists()) {
                vm.company.signer = vm.signer;
            }

            vm.company.externalIds = vm.externalIds;

            vm.isSaving = true;
            if (vm.company.id) {
                Company.update(vm.company, onSaveSuccess, onSaveError);
            } else {
                Company.save(vm.company, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('giroApiApp:personUpdate', result);

            $timeout(function () {
                $state.go('^');
            }, 150);

            vm.isSaving = false;
        }

        function onSaveError(result) {
            vm.isSaving = false;
            vm.success = null;
        }

        // Bank
        function bankSelected(id, $bank, $account) {
            $account.bank = $bank;
        }

        function loadBanks(query, callback) {
            Bank.query({
                query: query
            }, function (result) {
                result.forEach(function (it) {
                    it.presentationLabel = it.number + ' - ' + it.name;
                });
                vm.banks = vm.banks.concat(result);
                callback(result);
            }, function () {
            });
        }

        // End Bank

        // Organization
        function organizationSelected(id, $organization, $companyProvider) {
            $companyProvider.organization = $organization;
        }

        function companyOrganizationSelected(id, $organization, $companyOrganizations) {
            $companyOrganizations.organization = $organization;

            if (id) {
                Tag.filter({
                        page: 0,
                        size: 30,
                        sort: ['name,asc'],
                        search: undefined,
                        organizationId: id
                    }, function (result) {
                        vm.companyOrganizations = vm.companyOrganizations.map(function ($it) {
                            if ($it.organization.id !== id) return $it;
                            $it.tags = $it.tags.concat(result.content);
                            return $it;
                        });
                    }
                );
            }

        }

        function loadAllOrganizations() {
            Organization.getSimplePage({}, function (result) {
                vm.organizations = vm.organizations.concat(result.content);
            }, function () {
            });
        }

        function loadOrganizations(query, callback) {
            Organization.getSimplePage({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result.content);
                callback(result.content);
            }, function () {
            });
        }

        // End Organization

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        vm.setFile = function ($file, image) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        image.file = base64Data;
                        image.fileContentType = $file.type;
                    });
                });
            }
        };

        function addAddress() {
            vm.addresses.push({});
        }

        function addPhone() {
            vm.phones.push({});
        }

        vm.addEmail = function () {
            vm.emailAddresses.push({});
        };

        vm.removeEmail = function ($index) {
            vm.emailAddresses.splice($index, 1);
        };

        vm.removePhone = function ($index) {
            vm.phones.splice($index, 1);
        };

        function addCompanyProvider() {
            vm.companyProviders.push({identifier: uuid.v4()});
        }

        function removeCompanyProvider($identifier) {
            vm.companyProviders = vm.companyProviders
                .filter(function ($it) {
                    return $it.identifier !== $identifier;
                });
        }

        function addSigner() {
            vm.signers.push({identifier: uuid.v4()});
        }

        function removeSigner($identifier) {
            vm.signers = vm.signers
                .filter(function ($it) {
                    return $it.identifier !== $identifier;
                });
        }

        function addOrganization() {
            vm.companyOrganizations.push({tags: [], selectedTags: []});
        }

        function removeOrganization($index) {
            vm.companyOrganizations.splice($index, 1);
        }

        function addAccount() {
            vm.accounts.push(new BankAccount());
        }

        function removeAccount(index) {
            vm.accounts.splice(index, 1);
        }

        function removeAddress(index) {
            vm.addresses.splice(index, 1);
        }

        function loadCities(query, callback) {
            City.query({
                    page: 0,
                    size: 30,
                    sort: ['name,asc'],
                    search: query
                }, function (result) {
                    vm.cities = vm.cities.concat(CityUtils.formatListToCityNameAndStateAcronym(result.content));
                    callback(vm.cities);
                }
            );
        }

        function onCitySelected($id, $city, $address) {
            $address.city = $city;
        }

        // Signer
        vm.birthdayStatus = false;

        vm.signerBirthday = {
            datepickerOptions: {
                maxDate: new Date()
            },
            ngModelOptions: {
                timezone: 'UTC'
            }
        };

        vm.openCalendar = function ($signer) {
            $signer.birthdayStatus = true;
        };

        vm.signerExists = function () {
            if (!vm.signer) {
                return false;
            }

            return !!(vm.signer.name || vm.signer.email || vm.signer.identification || vm.signer.birthday);
        };

        // End Signer

        function loadTags(query, organizationId, callback) {
            Tag.filter({
                    page: 0,
                    size: 30,
                    sort: ['name,asc'],
                    search: query,
                    organizationId: organizationId
                }, function (result) {
                    vm.companyOrganizations = vm.companyOrganizations.map(function ($it) {
                        if ($it.organization.id !== organizationId) return $it;
                        $it.tags = $it.tags.concat(result.date);
                        callback($it.tags);
                        return $it;
                    });
                }
            );
        }

        function onTagsSelected(items, organizationId) {

            vm.companyOrganizations = vm.companyOrganizations.map(function (it) {
                if (it.organization.id !== organizationId) return it;
                it.selectedTags = it.selectedTags
                    .filter(removeUnselectedTags)
                    .concat(find_tags_to_add(it.selectedTags, it.tags));
                return it;
            });

            function removeUnselectedTags(previous_selected_tag) {
                return items
                    .filter(remove_undefined_id)
                    .find(function (selected_tag_id) {
                        return previous_selected_tag.id === selected_tag_id;
                    });
            }

            function find_tags_to_add(previous_tags, all_tags) {
                return items
                    .filter(remove_undefined_id)
                    .filter(function (selected_tag_id) {
                        return !previous_tags.find(function (previous_selected_tag) {
                            return previous_selected_tag.id === selected_tag_id;
                        });
                    })
                    .map(function (selected_tag_id) {
                        return all_tags.find(function (tag) {
                            return tag.id === selected_tag_id;
                        });
                    })
                    .filter(function (tag) {
                        return tag !== undefined && tag !== null;
                    });
            }

            function remove_undefined_id(id) {
                return id !== null && id !== undefined && id !== "";
            }
        }

        function createProviderInAdvance($email_address) {

            if (!$email_address || !$email_address.id) return;

            Company.createProviderInAdvance({
                id: vm.company.id,
                second: $email_address.id
            }, onSuccess);

            function onSuccess() {
                noty.success($translate.instant('message.alert.providerInAdvance.created'));

                vm.emailAddresses.filter(function ($it) {
                    return $it.id === $email_address.id;
                }).map(function ($it) {
                    $it.hasUser = true;
                    return $it;

                });

            }
        }

        function addExternalId() {
            vm.externalIds.push({identifier: uuid.v4()});
        }

        function removeExternalId($identifier) {
            vm.externalIds = vm.externalIds
                .filter(function ($it) {
                    return $it.identifier !== $identifier;
                });
        }

        function organizationSelectedForExternalId(id, $organization, $externalId) {
            $externalId.organization = $organization;
        }

    }
})();
