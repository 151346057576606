(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ExportedConciliationController', ExportedConciliationController);

    function ExportedConciliationController($scope, $window, $translate, $state, $uibModal, noty, ExportedDataDocument, DataUtils, Organization, defaultOrganization) {
        var vm = this;

        vm.organizations = [];
        vm.organization = undefined;
        vm.showOrganizations = false;
        vm.date = undefined;
        vm.datePickerOpenStatus = false;
        vm.processing = false;

        vm.openCalendar = openCalendar;
        vm.processConciliation = processConciliation;

        function openCalendar() {
            vm.datePickerOpenStatus = true;
        }

        vm.addUnloadEvent = function () {
            if ($window.addEventListener) {
                $window.addEventListener("beforeunload", handleUnloadEvent, {capture: true});
            } else {
                //For IE browsers
                $window.attachEvent("onbeforeunload", handleUnloadEvent);
            }
        };

        vm.removeUnloadEvent = function () {
            if ($window.removeEventListener) {
                $window.removeEventListener("beforeunload", handleUnloadEvent, {capture: true});
            } else {
                $window.detachEvent("onbeforeunload", handleUnloadEvent);
            }
        };

        function handleUnloadEvent(event) {
            return event.returnValue = "Are you sure you want to exit?";
        }


        vm.loadOrganizations = loadOrganizations;
        vm.organizationSelected = organizationSelected;

        if (defaultOrganization.organizationKey === 'giro') {
            loadAllOrganizations();
            vm.showOrganizations = true;
        } else {
            vm.showOrganizations = false;
            vm.organization = defaultOrganization;
        }

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
            }, function () {
            });
        }

        function loadOrganizations(query, callback) {
            Organization.getSimpleByProvider({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result);
            }, function () {
            });
        }

        function organizationSelected($id, $model) {
            vm.organization = $id ? $model : undefined;
        }

        function processConciliation(form) {
            if (form.processForm.$invalid || !vm.organization)
                return;

            vm.processing = true;
            ExportedDataDocument.processConciliation({date: vm.date, organization: vm.organization}, onSuccess, onError);

            function onSuccess(result) {
                vm.addUnloadEvent();
                openPollingModal(result.id);
            }

            function onError() {
                vm.processing = false;
            }
        }

        function openPollingModal(entityId) {
            $uibModal.open({
                templateUrl: 'app/entities/exported-data-document/exported-polling.html',
                controller: 'ExportedPollingController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    entity: {id: entityId},
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('exported-polling');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function ($status) {
                if ($status === 'PROCESSED') {
                    download(entityId);
                    return;
                }
                vm.removeUnloadEvent();
                vm.processing = false;
                noty.error($translate.instant('exported-conciliation.error.' + $status));
            });
        }

        function download(entityId) {
            ExportedDataDocument.download({id: entityId}, onSuccess, onError);

            function onSuccess(result) {
                vm.processing = false;
                vm.removeUnloadEvent();
                createDynamicLink(result.data, result.fileName, result.contentType);
            }

            function onError() {
                vm.processing = false;
                vm.removeUnloadEvent();
            }
        }


        function createDynamicLink(data, fileName, contentType) {
            var blob = new Blob([data], {type: contentType});
            var url = $window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
})();
