(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyEventsController', CompanyEventsController);

    /** @ngInject **/
    function CompanyEventsController($uibModal, $stateParams, $state, CompanyEvent, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.companyId = $stateParams.companyId;

        loadAll();

        function loadAll () {
            CompanyEvent.getAll({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                companyId: vm.companyId
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                //vm.totalItems = headers('X-Total-Count');
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.companyEvents = data.content;
                vm.page = data.number + 1;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                companyId: vm.companyId
            });
        }

        vm.detail = function (event) {
            $uibModal.open({
                templateUrl: 'app/entities/company-event/company-event-detail-dialog.html',
                controller: 'CompanyEventDetailDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: ['CompanyEvent', function (CompanyEvent) {
                        return CompanyEvent.get({id: event.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
        }

    }
})();
