(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyFilesController', CompanyFilesController);

    function CompanyFilesController($window, Company, File, StringUtils) {
        var vm = this;

        vm.company = undefined;
        vm.companies = [];
        vm.files = [];

        vm.loadCompanies = loadCompanies;
        vm.companySelected = companySelected;
        vm.searchFiles = searchFiles;
        vm.downloadFile = downloadFile;

        function loadCompanies(query, callback) {
            Company.search({q: query, limit: 50},
                function (result) {
                    vm.companies = vm.companies.concat(StringUtils.formatListWithoutCanAnticipate(result));
                    callback(vm.companies);
                }, function () {
                    callback(vm.companies);
                }
            );
        }

        function companySelected($id, $model) {
            vm.company = $model;
            searchFiles();
        }

        function searchFiles() {
            File.getCompanyFiles({id: vm.company.id},
                function (result) {
                    vm.files = result;
                }
            );
        }

        function downloadFile($file) {
            File.download({id: $file.id}, function (response) {
                debugBase64(response.data, response.name);
            });
        }

        function debugBase64(data, fileName) {
            var byteCharacters = atob(data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }
    }
})();
