(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('payment-voucher-santander', {
                parent: 'no-navbar',
                url: '/payment-voucher/santander/{id}?organizationKey',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER_CONSULT', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment-voucher/santander.html',
                        controller: 'PaymentVoucherSantanderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-voucher-itau', {
                parent: 'no-navbar',
                url: '/payment-voucher/itau/{id}?organizationKey',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER_CONSULT', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment-voucher/itau.html',
                        controller: 'PaymentVoucherItauController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-voucher-default', {
                parent: 'no-navbar',
                url: '/payment-voucher/default/{id}?organizationKey',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER_CONSULT', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment-voucher/default.html',
                        controller: 'PaymentVoucherDefaultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
