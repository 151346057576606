(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDocumentDetailController', ImportedDocumentDetailController);

    /** @ngInject **/
    function ImportedDocumentDetailController(entity, previousState, GiroConstants) {
        var vm = this;

        vm.currency = GiroConstants.Constants.currency;
        vm.document = entity;
        vm.previousState = previousState.name;

    }
})();
