(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyFilesManageController', CompanyFilesManageController);

    /** @ngInject **/
    function CompanyFilesManageController($scope, $state, $timeout, $translate, $window, $stateParams, previousState, entity, DataUtils, File, noty) {
        var vm = this;
        vm.company = entity;
        vm.isWebapp = $stateParams.isWebapp;
        vm.files = vm.company.files ? vm.company.files : [{}];

        vm.selectFiles = selectFiles;
        vm.removeFile = removeFile;
        vm.downloadFile = downloadFile;

        function selectFiles($files) {
            if ($files && $files.length) {
                $files
                    .filter(function ($file) {
                        return $file.$error !== 'pattern';
                    })
                    .forEach(function ($file) {
                        DataUtils.toBase64($file, function (base64Data) {
                            $scope.$apply(function () {
                                vm.files.push({data: base64Data, name: $file.name, type: 'DOCUMENT', status: 'NEW'});
                            });
                        });
                    });
            }
        }

        vm.saveFile = function ($file, $index) {
            $file.status = 'SAVING';
            vm.isSaving = true;
            File.saveCompanyFile({id: vm.company.id}, $file,
                function (result) {
                    result.status = undefined;
                    vm.isSaving = false;
                    vm.files.splice($index, 1, result);
                }, function () {
                    $file.status = 'NEW';
                    vm.isSaving = false;
                }
            );
        };

        function removeFile($file, $index) {
            File.deleteCompanyFile(
                {id: $file.id},
                function () {
                    vm.files.splice($index, 1);
                    vm.isSaving = false;
                }, function () {
                    vm.isSaving = false;
                }
            );
        }

        function downloadFile($file) {
            File.download({id: $file.id}, function (response) {
                debugBase64(response.data, response.name);
            });
        }

        function debugBase64(data, fileName) {
            var byteCharacters = atob(data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }

        vm.clear = function () {
            if (vm.isWebapp) return;

            $state.go("home");
        };
    }
})();
