(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditVisualizerRemoveConfirmedCreditSimpleController', CreditVisualizerRemoveConfirmedCreditSimpleController);

    /** @ngInject **/
    function CreditVisualizerRemoveConfirmedCreditSimpleController($translate, $timeout, $uibModalInstance, backState, creditId, batchId, Batch, noty) {
        var vm = this;

        vm.credit = creditId;
        vm.batchId = batchId;
        vm.backState = backState;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.remove = function () {
            Batch.removeCredit({id: vm.batchId, type: creditId}, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess(result) {
            noty.success($translate.instant('message.batch.credit.remove'));
            $timeout(function () {
                $uibModalInstance.close({backState: vm.backState});
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError(data) {
            vm.isSaving = false;
            $uibModalInstance.close({backState: vm.backState});
        }
    }
})();
