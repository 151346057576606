(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ApprovalProfileManageController', ApprovalProfileManageController);

    function ApprovalProfileManageController($translate, $timeout, DataUtils, $scope, $state, entity, Program, ApprovalProfile, Person, ProgramProvider) {
        var vm = this;
        vm.approvalProfile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.programs = [];
        vm.programProviders = [];
        vm.programProvidersProfile = [];
        vm.people = [];
        vm.peopleProfile = [];
        vm.program = undefined;
        vm.programId = undefined;

        if (vm.approvalProfile.id) {
            vm.program = vm.approvalProfile.program;
            vm.programId = vm.approvalProfile.program.id;
            vm.programs.push(vm.approvalProfile.program);

            if (vm.approvalProfile.people.length > 0) {
                angular.forEach(vm.approvalProfile.people, function ($organization) {
                    if ($organization) {
                        vm.people.push($organization);
                    }
                });
                vm.peopleProfile = vm.approvalProfile.people.map(function (item) {
                    return {person: item}
                });
            } else {
                loadAllPeople();
            }

            if (vm.approvalProfile.programProviders.length > 0) {
                var $programProviders = vm.approvalProfile.programProviders.map(
                    function (item) {
                        return {
                            id: item.id,
                            name: item.name + " - " + item.identification
                        }
                    });

                angular.forEach($programProviders, function ($organization) {
                    if ($organization) {
                        vm.programProviders.push($organization);
                    }
                });
                vm.programProvidersProfile = $programProviders.map(function (item) {
                    return {programProvider: item}
                });
            }
        } else {
            loadAllPrograms();
            loadAllPeople();
        }

        function save() {
            vm.isSaving = true;
            vm.approvalProfile.program = vm.program;

            vm.approvalProfile.people = vm.peopleProfile
                .filter(
                    function (item) {
                        return item.person && item.person.id
                    }
                )
                .map(
                    function (item) {
                        return {id: item.person.id}
                    }
                );

            vm.approvalProfile.programProviders = vm.programProvidersProfile
                .filter(
                    function (item) {
                        return item.programProvider && item.programProvider
                    }
                )
                .map(
                    function (item) {
                        return {id: item.programProvider.id}
                    }
                );

            if (vm.approvalProfile.id) {
                ApprovalProfile.update(vm.approvalProfile, onSaveSuccess, onSaveError);
            } else {
                ApprovalProfile.save(vm.approvalProfile, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess(result) {
                $timeout(function () {
                    $state.go('^');
                }, 150);

                vm.isSaving = false;
            }

            function onSaveError(result) {
                vm.isSaving = false;
                vm.success = null;
            }
        }

        function clear() {
            $state.go('^');
        }

        //Selectize Program
        function loadAllPrograms() {
            Program.querySimple(function (result) {
                vm.programs = vm.programs.concat(result.content);
            }, function () {
            });
        }

        vm.loadPrograms = function (query, callback) {
            Program.querySimple({
                query: query
            }, function (result) {
                vm.programs = result.content;

                callback(result.content);
            }, function () {
            });
        };

        vm.programSelected = function ($id, $model) {
            vm.program = $model;
            if ($id) {
                vm.programId = $id;
            } else {
                vm.programId = undefined;
            }
        };

        //----------

        //Selectize Person
        vm.addPerson = function () {
            vm.peopleProfile.push({});
        };

        vm.removePerson = function (index) {
            vm.peopleProfile.splice(index, 1);
        };

        vm.personSelected = function (id, $person, $personProfile) {
            $personProfile.person = $person;
        };

        function loadAllPeople() {
            Person.search({}, function (result) {
                vm.people = vm.people.concat(result.content);
            }, function () {
            });
        }

        vm.loadPeople = function (query, callback) {
            Person.search({
                searchText: query
            }, function (result) {
                vm.people = vm.people.concat(result.content);
                callback(result.content);
            }, function () {
            });
        };
        // End Person

        //Selectize ProgramProvider
        vm.addProgramProvider = function () {
            vm.programProvidersProfile.push({});
        };

        vm.removeProgramProvider = function (index) {
            vm.programProvidersProfile.splice(index, 1);
        };

        vm.programProviderSelected = function (id, $programProvider, $programProviderProfile) {
            $programProviderProfile.programProvider = $programProvider;
        };

        vm.loadProgramProviders = function (query, callback) {
            ProgramProvider.findByProgramQuery({
                q: query,
                id: vm.programId
            }, function (result) {
                var $programProviders = result.content.map(
                    function (item) {
                        return {
                            id: item.id,
                            name: item.company.name + " - " + item.company.identification
                        }
                    });

                vm.programProviders = vm.programProviders.concat($programProviders);

                callback($programProviders);
            }, function () {
            });
        };
        // End ProgramProvider

    }

})();
