(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('exported-conciliation', {
                parent: 'entity',
                url: '/exported-data-documents/conciliation',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/exported-data-document/exported-conciliation.html',
                        controller: 'ExportedConciliationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    defaultOrganization: function (Organization) {
                        return Organization.getCurrent().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('exported-conciliation');
                        return $translate.refresh();
                    }]
                }
            })

        ;
    }
})();
