(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('program', {
            parent: 'entity',
            url: '/program?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN']
                // pageTitle: 'Giro - Central Eletrônica de Créditos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/program/programs.html',
                    controller: 'ProgramController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('program');
                    return $translate.refresh();
                }]
            }
        })
        .state('program-detail', {
            parent: 'entity',
            url: '/program/{id}?category',
            data: {
                authorities: ['ROLE_ADMIN']
                // pageTitle: 'Giro - Central Eletrônica de Créditos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/program/program-detail.html',
                    controller: 'ProgramDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                category: null
            },
            resolve: {
                entity: ['$stateParams', 'Program', function($stateParams, Program) {
                    return Program.get({id : $stateParams.id}).$promise
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'program',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                pageParams: ['$stateParams', function ($stateParams) {
                    return $stateParams.category
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('program-detail');
                    return $translate.refresh();
                }]
            }
        })
        .state('program-detail.edit', {
            parent: 'program-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/program/program-dialog.html',
                    controller: 'ProgramDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Program', function(Program) {
                            return Program.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('header');
                            $translatePartialLoader.addPart('footer');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('program.new', {
            parent: 'program',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
              'content@': {
                  templateUrl: 'app/entities/program/program-dialog.html',
                  controller: 'ProgramDialogController',
                  controllerAs: 'vm'
              }
            },
            resolve: {
                entity: function ($stateParams, Program) {
                    if ($stateParams.id) {
                        return Program.get({id : $stateParams.id}).$promise;
                    } else {
                        return {
                            name: undefined,
                            platformRemuneration: undefined,
                            status: undefined,
                            programConfiguration: {
                                daysToPay: 1,
                                expireCreditIn: 0,
                                sendEmailInRequest: true,
                                sendAllRequestEmail: false,
                                defaultProviderStatus: 'INACTIVATED',
                                addProviderManually: false,
                                anticipationMaxTime: undefined
                            },
                            id: undefined
                        };
                    }
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('program-dialog');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'program',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },

        })
        .state('program.edit', {
            parent: 'program',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/program/program-dialog.html',
                    controller: 'ProgramDialogController',
                    controllerAs: 'vm',
                    resolve: {
                        entity: ['Program', function(Program) {
                            return Program.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }
            },
            resolve: {
                entity: function (Program, $stateParams) {
                    if ($stateParams.id) {
                        return Program.get({id : $stateParams.id}).$promise;
                    } else {
                        return {
                            name: null,
                            platformRemuneration: null,
                            status: null,
                            id: null
                        };
                    }
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'program',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('program-dialog');
                    return $translate.refresh();
                }]
            }

        })
        .state('program.delete', {
            parent: 'program',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/program/program-delete-dialog.html',
                    controller: 'ProgramDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Program', function(Program) {
                            return Program.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('header');
                            $translatePartialLoader.addPart('footer');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('program-delete-dialog');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('program', null, { reload: 'program' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
