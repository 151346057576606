(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditDetailController', CreditDetailController);


    /** @ngInject **/
    function CreditDetailController($scope, $rootScope, $state, previousState, entity, GiroConstants) {
        var vm = this;

        vm.currency = GiroConstants.Constants.currency;
        vm.credit = entity;
        vm.previousState = previousState.name;

        vm.clear = function () {
            $state.go(previousState.name || '^', previousState.params);
        };

        var unsubscribe = $rootScope.$on('giroApiApp:creditUpdate', function(event, result) {
            vm.credit = result;
        });
        $scope.$on('$destroy', unsubscribe);
        console.log(vm.credit);
    }
})();
