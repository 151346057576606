(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BatchVisualizerCancelController', BatchVisualizerCancelController);

    /** @ngInject **/
    function BatchVisualizerCancelController($translate, $timeout, $uibModalInstance, entity, backState, BatchOperation, noty) {
        var vm = this;

        vm.batch = entity;
        vm.backState = backState;
        vm.clear = clear;
        vm.confirmCancel = confirmCancel;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCancel () {
            BatchOperation.cancel({id: vm.batch.id}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            noty.success($translate.instant('message.batch.credit.cancel'));
            $timeout(function () {
                $uibModalInstance.close({backState: vm.backState});
            },150);
            vm.isSaving = false;
        }

        function onSaveError (data) {
            vm.isSaving = false;
            $uibModalInstance.close({backState: vm.backState});
        }
    }
})();
