(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .constant('GiroConstants', {
            Constants: {
                currency: 'R$',
                locale: 'pt-br'
            }
        });
})();
