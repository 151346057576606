(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ExportedPollingController', ExportedPollingController);

    function ExportedPollingController($uibModalInstance, $timeout, noty, ExportedDataDocument, entity) {
        var vm = this;

        vm.entityId = entity.id;
        vm.errorCount = 0;

        checkStatus();

        function checkStatus() {
            ExportedDataDocument.status({id: vm.entityId}, onSuccess, onError);

            function onSuccess(result) {
                if (result.status === 'NEW') {
                    $timeout(checkStatus, 1000);
                    return;
                }

                $uibModalInstance.close(result.status);
            }

            function onError() {
                if (vm.errorCount > 2)
                    return;
                vm.errorCount++;
                checkStatus();
            }
        }
    }
})();
