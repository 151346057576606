(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ApprovalProfileDetailController', ApprovalProfileDetailController);

    function ApprovalProfileDetailController(previousState, entity) {
        var vm = this;

        vm.approvalProfile = entity;
        vm.previousState = previousState.name;
    }

})();
