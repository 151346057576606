(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CustomerTitleReportController', CustomerTitleReportController);

    function CustomerTitleReportController($state, $stateParams, $uibModal, $filter, paginationConstants, pagingParams, previousState, defaultOrganization, GiroConstants,
                                           noty, CompanyCustomer, ClientTitle, ClientInstallment, Principal, ParseLinks, StringUtils, SubscriptionCode, Organization) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.backToPanel = backToPanel;
        vm.printBoleto = printBoleto;

        if (defaultOrganization.organizationKey !== "giro") {
            vm.defaultOrganization = defaultOrganization;
            vm.organization = vm.defaultOrganization;
            vm.organizationId = vm.defaultOrganization.id;
        } else {
            vm.organizationId = $stateParams.organizationId;
        }

        vm.currency = GiroConstants.Constants.currency;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.companyCustomerId = $stateParams.companyCustomerId;
        vm.companyId = $stateParams.companyId;
        vm.customer = undefined;
        vm.customers = [];

        vm.query = $stateParams.query;
        vm.dueDateStart = $stateParams.dueDateStart;
        vm.dueDateEnd = $stateParams.dueDateEnd;
        vm.status = $stateParams.status;
        vm.titleNumber = $stateParams.titleNumber;
        vm.invoiceNumber = $stateParams.invoiceNumber;

        vm.datePickerDueDateStartOpenStatus = false;
        vm.datePickerDueDateEndOpenStatus = false;

        if ($stateParams.dueDateStart) {
            vm.dueDateStart = new Date($stateParams.dueDateStart);
        }

        if ($stateParams.dueDateEnd) {
            vm.dueDateEnd = new Date($stateParams.dueDateEnd);
        }

        vm.findCustomerTitles = function () {
            if (Principal.hasAnyAuthority(["ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT']) && !vm.customer) {
                noty.error("Selecione um cliente!");
                return;
            }

            transition();
        };

        function find() {
            ClientTitle.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                dueDateStart: vm.dueDateStart,
                dueDateEnd: vm.dueDateEnd,
                status: vm.status,
                titleNumber: vm.titleNumber,
                invoiceNumber: vm.invoiceNumber,
                companyId: vm.companyId,
                organizationId: vm.organizationId
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.customerTitles = data.content;
                vm.links = ParseLinks.parse(headers('link'));
                // vm.totalItems = headers('X-Total-Count');
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.page = data.number + 1;
            }

            function onError(error) {
            }
        }

        if (Principal.hasAnyAuthority(["ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT'])) {
            if (vm.companyId) {
                find();
                loadCustomer()
            } else {
                loadAllCustomers();
            }

            if (vm.organizationId) {
                loadOrganization();
            } else {
                loadAllOrganizations();
            }
        } else {
            vm.customer = Principal.getDefaultCompany();
        }

        if (Principal.hasAnyAuthority(["ROLE_CLIENT"])) {
            find();
        }

        function loadCustomer() {
            CompanyCustomer.simpleElement({id: vm.companyCustomerId}, function (result) {
                vm.customer = StringUtils.formatWithoutCanAnticipate(result);
                vm.customers = vm.customers.concat(StringUtils.formatListWithoutCanAnticipate(result));
            })
        }

        //Selectize Customer
        function loadAllCustomers() {
            CompanyCustomer.searchSimple(function (result) {
                vm.customers = vm.customers.concat(StringUtils.formatListWithoutCanAnticipate(result.content));
            }, function () {
            });
        }

        vm.loadCustomers = function (query, callback) {
            CompanyCustomer.searchSimple({
                q: query
            }, function (result) {
                vm.customers = vm.customers.concat(StringUtils.formatListWithoutCanAnticipate(result.content));
                callback(result.content);
            }, function () {
            });
        };

        vm.customerSelected = function ($id, $model) {
            vm.customer = $model;

            if (vm.customer) {
                vm.companyId = vm.customer.companyId;
            } else {
                vm.companyId = undefined;
            }

            if ($id) {
                vm.companyCustomerId = $id;
            } else {
                vm.companyCustomerId = undefined;
            }
        };
        //----------

        //Selectize Organization
        vm.organizations = [];

        function loadOrganization() {
            Organization.getSimpleById({id: vm.organizationId}, function (result) {
                vm.organization = result;
                vm.organizations = vm.organizations.concat(result);
            })
        }

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
            }, function () {
            });
        }

        vm.loadOrganizations = function (query, callback) {
            Organization.getSimple({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result);
            }, function () {
            });
        };

        vm.organizationSelected = function ($id, $model) {
            if ($id) {
                vm.organization = $model;
                vm.organizationId = $id;
            } else {
                vm.organization = undefined;
                vm.organizationId = undefined;
            }
        };
        //----------

        vm.openDueDateStartOpen = function () {
            vm.datePickerDueDateStartOpenStatus = true;
        };

        vm.openDueDateEndOpen = function () {
            vm.datePickerDueDateEndOpenStatus = true;
        };

        function printBoleto(boletoNumber, urlBank) {
            $uibModal.open({
                templateUrl: 'app/components/clipboard-modal/clipboard-modal.html',
                controller: 'ClipboardModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: {boletoNumber: boletoNumber, urlBank: urlBank},
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('clipboard-modal');
                        return $translate.refresh();
                    }]
                }
            });
        }

        vm.reset = function () {
            vm.query = undefined;
            vm.invoiceNumber = undefined;
            vm.titleNumber = undefined;
            vm.status = undefined;
            vm.dueDateStart = undefined;
            vm.dueDateEnd = undefined;
            transition();
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                page: vm.page,
                titleNumber: vm.titleNumber,
                invoiceNumber: vm.invoiceNumber,
                companyCustomerId: vm.companyCustomerId,
                dueDateStart: vm.dueDateStart,
                dueDateEnd: vm.dueDateEnd,
                companyId: vm.companyId,
                organizationId: vm.organizationId,
                status: vm.status
            });
        }

        function backToPanel() {
            $state.go(previousState.name || 'home', $stateParams);
        }

        vm.report = function () {
            if (Principal.hasAnyAuthority(["ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT']) && !vm.customer) {
                noty.error("Selecione um fornecedor!");
                return;
            }

            var organizationKey = undefined;

            if (defaultOrganization.organizationKey !== "giro") {
                organizationKey = vm.organization.organizationKey
            }

            ClientInstallment.report({
                companyId: vm.companyId,
                status: vm.status,
                invoiceNumber: vm.invoiceNumber,
                titleNumber: vm.titleNumber,
                dueDateStart: vm.dueDateStart,
                dueDateEnd: vm.dueDateEnd,
                organizationKey: organizationKey
            }, function (result) {
                var doc = new jsPDF({
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a4'
                });

                //Title
                createHeader(doc, result.organizationName);
                createFiltersLabel(doc);
                createFiltersValue(doc);
                createTableHeader(doc, 58);

                var start = 65;
                result.subsidiaries.forEach(function ($subsidiary) {
                    addFilial($subsidiary.code, doc, start); // + 7

                    $subsidiary.entryDates.forEach(function ($entryDate) {
                        start = plusStart(start, 7);

                        $entryDate.installments.forEach(function ($installment) {
                            start = addTableCell($installment, doc, start);
                            if ($installment.observation) {
                                var split = doc.splitTextToSize($installment.observation.replace(/(\r\n|\n|\r)/gm, ""), 250);

                                var heightDim = doc.getTextDimensions(split).h;
                                start = plusStart(start, heightDim + 11);
                            } else {
                                start = plusStart(start, 7);
                            }
                        });

                        addTotalByDate($entryDate.total, $entryDate.entryDate, doc, start); // + 7
                        start = plusStart(start, 8);
                    });

                    addTotalByFilial($subsidiary.total, $subsidiary.code, doc, start); // + 8
                    start = plusStart(start, 8);
                });
                addTotal(result.total, doc, start); // + 8
                start = plusStart(start, 7);

                doc.save('relatorio-faturas.pdf'); // height = 208

                function plusStart($start, $size) {
                    if ($start + $size < 200) {
                        return $start + $size;
                    } else {
                        doc.addPage();
                        return 10;
                    }
                }
            });
        };

        function createHeader($doc, $organization) {
            $doc.setFontSize(20);
            $doc.setFontStyle('bold');
            $doc.text($organization, 10, 13);

            $doc.setFontStyle('normal');
            $doc.setFontSize(18);
            $doc.text('Relatório de Faturas', 150, 15, null, null, 'center');

            $doc.setFontSize(14);
            $doc.text('Portal de Clientes', 15, 18);

            $doc.line(10, 23, 288, 23);
        }

        function createFiltersLabel($doc) {
            var firstWidth = 10;
            var secondWidth = 160;
            var startWidth = 30;
            $doc.setFontStyle('bold');
            $doc.setFontSize(10);
            $doc.text('Cliente:', firstWidth, startWidth);

            $doc.text('Situação do título:', firstWidth, startWidth + 5);
            $doc.text('Número NF:', firstWidth, startWidth + 10);

            $doc.text('Número título:', secondWidth, startWidth + 5);
            $doc.text('Data de Vencimento:', secondWidth, startWidth + 10);

            $doc.line(10, 45, 288, 45);
        }

        function createFiltersValue($doc) {
            var firstWidth = 10;
            var secondWidth = 160;
            var startWidth = 30;
            var status = vm.status === 'true' ? 'Pagos' : 'Em aberto';

            $doc.setFontStyle('normal');
            $doc.setFontSize(10);
            $doc.text(vm.customer ? vm.customer.name + ' - ' + vm.customer.identification : '', firstWidth + 15, startWidth);

            $doc.text(vm.status ? status : 'Todos', firstWidth + 32, startWidth + 5);
            $doc.text(vm.invoiceNumber ? vm.invoiceNumber : '', firstWidth + 22, startWidth + 10);

            $doc.text(vm.titleNumber ? vm.titleNumber : '', secondWidth + 26, startWidth + 5);
            $doc.text(buildFilterDate(vm.dueDateStart, vm.dueDateEnd), secondWidth + 36, startWidth + 10);
        }

        function buildFilterDate($start, $end) {
            var result = '';
            if ($start) {
                result = $filter('date')(new Date($start), 'dd/MM/yy');
            }

            if ($start && $end) {
                result = result + ' à ';
            }

            if ($end) {
                result = result + $filter('date')(new Date($end), 'dd/MM/yy')
            }

            return result;
        }

        function addFilial($value, $doc, startWidth) {
            $doc.setFontSize(10);
            var firstWidth = 11;

            $doc.setFontStyle('bold');
            $doc.text('Filial:', firstWidth, startWidth);

            $doc.setFontStyle('normal');
            var label = '' + $value + ' - ' + SubscriptionCode.castrolanda[$value];
            $doc.text(label, firstWidth + 10, startWidth);
        }

        function createTableHeader($doc, startWidth) {
            var firstWidth = 11;
            $doc.setFontSize(10);
            $doc.setFontStyle('bold');
            $doc.text('Dt. Entrada', firstWidth, startWidth);
            $doc.text('Dt. Emissão', firstWidth + 23, startWidth);
            $doc.text('Título', firstWidth + 47, startWidth);
            $doc.text('Nf-Serie/Parcela', firstWidth + 79, startWidth);
            $doc.text('Dt. Vencimento \nOriginal', firstWidth + 114, startWidth - 2);
            $doc.text('Dt. vencimento \nAjustada', firstWidth + 144, startWidth - 2);
            $doc.text('Valor', firstWidth + 176, startWidth);

            $doc.text('Situação', firstWidth + 204, startWidth);
            $doc.text('Atraso', firstWidth + 225, startWidth);
            $doc.text('Dt. Pagamento', firstWidth + 245, startWidth);
        }

        function addTableCell($installment, $doc, startWidth) {
            var firstWidth = 11;
            $doc.setFontSize(10);
            $doc.setFontStyle('normal');
            $doc.text($filter('date')(new Date($installment.entryDate), 'dd/MM/yy', 'UTC'), firstWidth, startWidth);
            $doc.text($installment.emissionDate ? $filter('date')(new Date($installment.emissionDate), 'dd/MM/yy', 'UTC') : '', firstWidth + 23, startWidth);
            $doc.text('' + $installment.titleNumber, firstWidth + 47, startWidth);
            $doc.text('' + $installment.invoiceNumber + '-' + $installment.serial + '/' + $installment.installmentNumber, firstWidth + 79, startWidth);
            $doc.text($filter('date')(new Date($installment.expirationDate), 'dd/MM/yy', 'UTC'), firstWidth + 114, startWidth);
            $doc.text($filter('date')(new Date($installment.expirationChanged ? $installment.expirationChanged : $installment.expirationDate), 'dd/MM/yy', 'UTC'), firstWidth + 144, startWidth);
            $doc.text($filter('number')($installment.value, 2), firstWidth + 176, startWidth);

            $doc.text($filter('translate')('giroApiApp.customerTitle.installmentStatus.' + $installment.status), firstWidth + 204, startWidth);
            $doc.text($installment.paymentLate ? '' + $installment.paymentLate : '0', firstWidth + 225, startWidth);
            $doc.text($installment.paymentDate ? $filter('date')(new Date($installment.paymentDate), 'dd/MM/yy', 'UTC') : '', firstWidth + 245, startWidth);

            if ($installment.observation) {
                startWidth = addObservation($installment.observation, $doc, startWidth);
            }

            return startWidth;
        }

        function addObservation($observation, $doc, $startWidth) {
            var split = $doc.splitTextToSize($observation ? $observation.replace(/(\r\n|\n|\r)/gm, "") : '', 250);

            var heightDim = $doc.getTextDimensions(split).h;

            if ($startWidth + heightDim + 8 > 200) {
                $doc.addPage();
                $startWidth = 10;
            }

            var firstWidth = 11;
            var startWidth = $startWidth + 5;
            $doc.setFontSize(10);
            $doc.setFontStyle('normal');

            $doc.text(split, firstWidth + 23, startWidth);

            $doc.setFontStyle('bold');
            $doc.text('Observação: ', firstWidth, startWidth);

            return $startWidth;
        }

        function addTotalByDate($total, $date, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total na data ' + $filter('date')(new Date($date), 'dd/MM/yy', 'UTC') + ':', firstWidth - 30, startWidth, null, null, 'right');
        }

        function addTotalByFilial($total, $code, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([2.5]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);


            var label = '' + $code + ' - ' + SubscriptionCode.castrolanda[$code];

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total para a Filial '+ label + ': ', firstWidth - 29, startWidth, null, null, 'right');
        }

        function addTotal($total, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Valor Total: ', firstWidth - 30, startWidth, null, null, 'right');
        }

    }
})();
