(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('TagManageController', TagManageController);

    /** @ngInject **/
    function TagManageController($state, $stateParams, previousState, Tag, CompanyEvent, Organization, entity) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.tag = entity;

        function clear() {
            $state.go(previousState.name || '^', previousState.params);
        }

        function save(form) {
            if (form.editForm.$invalid || !vm.organization) {
                return;
            }

            vm.isSaving = true;

            if (vm.tag.id) {
                Tag.update(vm.tag, onSaveSuccess, onSaveError);
            } else {
                Tag.create(vm.tag, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go(previousState.name || '^', $stateParams);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.organizations = [];

        loadAllOrganizations();

        function loadAllOrganizations() {
            Organization.getSimple(function (result) {
                vm.organizations = vm.organizations.concat(result);
                if (vm.tag && vm.tag.organization) {
                    vm.organization = vm.organizations.find(function (element) {
                        return element.organizationKey === vm.tag.organization.organizationKey
                    })
                }

                if (vm.organizations.length === 1) {
                    vm.tag.organization = vm.organizations[0];
                    vm.organization = vm.organizations[0];
                }

            }, function () {
            });
        }

        vm.loadOrganizations = function (query, callback) {
            Organization.getSimple({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result.content);
            }, function () {
            });
        };

        vm.organizationSelected = function ($id, $model) {
            vm.organization = $model;

            if ($id) {
                vm.tag.organization = $model;
                vm.organization = $model;
            } else {
                vm.tag.organization = undefined;
                vm.organization = undefined;
            }
        };

    }
})();
