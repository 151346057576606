(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ManageJobConfirmController', ManageJobConfirmController);

    function ManageJobConfirmController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            $uibModalInstance.close(true);
        }
    }
})();
