(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditVisualizerSimpleRejectController', CreditVisualizerSimpleRejectController);

    /** @ngInject **/
    function CreditVisualizerSimpleRejectController($translate, $timeout, $uibModalInstance, $stateParams, noty, BatchOperation) {
        var vm = this;

        vm.batches = $stateParams.batches;
        vm.amount = vm.batches
            .map(function ($batch) {
                return $batch.credits;
            })
            .flat()
            .length;

        vm.clear = clear;
        vm.reject = reject;
        vm.reason = undefined;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function reject() {
            var batchesReject = {
                batches: vm.batches,
                reason: vm.reason
            };

            BatchOperation.removeBatchCredits(batchesReject, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            noty.success($translate.instant('message.batch.credit.reject'));
            $timeout(function () {
                $uibModalInstance.close(result);
            }, 150);
            vm.isSaving = false;
        }

        function onSaveError(data) {
            vm.isSaving = false;
            $uibModalInstance.close(data);
        }
    }
})();
