(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProgramDetailController', ProgramDetailController);

    /** @ngInject **/
    function ProgramDetailController($scope, $rootScope, $stateParams, previousState, entity, Program, ProgramBuyer,
                                     ProgramProviderCategory, ProgramProvider, ProgramProviderByProgramId,
                                     ProgramFinancialByProgramId, Principal, pageParams, GiroConstants) {
        var vm = this;

        vm.program = entity;
        vm.category = pageParams;

        vm.currency = GiroConstants.Constants.currency;

        vm.isAdmin = false;
        Principal.identity().then(function (account) {
            vm.account = account;
            angular.forEach(vm.account.authorities, function (authority) {
                if (authority == "ROLE_ADMIN") {
                    vm.isAdmin = true;
                }
            });
        });

        vm.previousState = previousState.name;

        ProgramProviderByProgramId.get({id: vm.program.id}, function (result) {
                vm.providers = result.content;
            }
        );
        vm.financials = ProgramFinancialByProgramId.get({id: vm.program.id});

        var unsubscribe = $rootScope.$on('giroApiApp:programUpdate', function (event, result) {
            vm.program = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
