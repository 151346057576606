(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('company-bank-account-manage', {
                parent: 'entity',
                url: '/company-bank-account-manage',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_FINANCIAL']
                },
                params: {
                    isWebapp: false
                },
                resolve: {
                    entity: function (Company) {
                        return Company.getDefaultCompanyBankAccount().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('company-bank-account-manage');
                        $translatePartialLoader.addPart('favored-type');
                        return $translate.refresh();
                    }]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company-bank-account/company-bank-account-manage.html',
                        controller: 'CompanyBankAccountManageController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('dashboard/company-bank-account-manage', {
                parent: 'webapp-redirect',
                url: '/dashboard/company-bank-account-manage',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_FINANCIAL']
                },
                params: {
                    isWebapp: true
                },
                resolve: {
                    entity: function (Company) {
                        return Company.getDefaultCompanyBankAccount().$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('company-bank-account-manage');
                        $translatePartialLoader.addPart('favored-type');
                        return $translate.refresh();
                    }]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company-bank-account/company-bank-account-manage.html',
                        controller: 'CompanyBankAccountManageController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('company-bank-account-approval', {
                parent: 'entity',
                url: '/bank-account-approval?companyId&bankAccountId',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_FINANCIAL']
                },
                resolve: {
                    entity: ['$stateParams', 'Company', function ($stateParams, Company) {
                        return Company.getCompanyBankAccount({
                            id: $stateParams.companyId,
                            second: $stateParams.bankAccountId
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('company-bank-account-approval');
                        return $translate.refresh();
                    }]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company-bank-account/company-bank-account-approval.html',
                        controller: 'CompanyBankAccountApprovalController',
                        controllerAs: 'vm'
                    }
                }
            })
    }

})();
