(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('TagDetailDialogController', TagDetailDialogController);

    /** @ngInject **/
    function TagDetailDialogController($uibModalInstance, entity) {
        var vm = this;

        vm.tag = entity;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
