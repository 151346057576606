(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyBankAccountRequestController', CompanyBankAccountRequestController);

    /** @ngInject **/
    function CompanyBankAccountRequestController($uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.confirm = confirm;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            $uibModalInstance.close();
        }
    }
})();
