(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Batch', Batch);

    function Batch($resource) {
        var resourceUrl = 'api/batch-credits/:id/:action/:type/:third';

        return $resource(resourceUrl, {id: '@id', type: '@type'}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'getSimple': {method: 'GET', params:{action:'simple'}},
            'getCnab': {method: 'GET', params: {action: 'cnab'}},
            'generateCnab': {method: 'POST', params: {action: 'generate-cnab'}},
            'getFinancial': {method: 'GET', params: {action: 'financial'}},
            'getFinancialWithCredit': {method: 'GET', params: {action: 'financial', type:'credits'}},
            'getBatchDetail': {method: 'GET', params: {action: 'credits', type:'requested'}},
            'getRequestedCreditToCsv': {
                method: 'GET',
                params: {action: 'programs', type: 'financials', third: 'csv'},
                isArray: true
            },
            'buyer': {
                method: 'GET', params: {action: 'programs', type: 'buyers'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        return data
                    }
                    return data;
                }
            },
            'provider': {
                method: 'GET', params: {action: 'programs', type: 'providers'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        return data
                    }
                    return data;
                }
            },
            'providerWithEstimate': {
                method: 'GET', params: {action: 'programs', type: 'providers-with-estimate'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        return data
                    }
                    return data;
                }
            },
            'financial': {
                method: 'GET', params: {action: 'programs', type: 'financials'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        return data
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},

            'removeCredit': {
                method: 'PUT',
                params: { action: 'credit', third: 'remove'},
                // batchid/credit/creditid/remove
            },
        });
    }
})();
