/**
 * Created by rene on 1/4/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('EmailAddressHistoryController', EmailAddressHistoryController);

    /** @ngInject **/
    function EmailAddressHistoryController($state, $stateParams, ParseLinks, EmailAddressHistory, pagingParams, paginationConstants, previousState) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.emailAddressId = $stateParams.emailAddressId;

        loadAll();

        function loadAll() {
            EmailAddressHistory.getByEmailAddress({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                emailAddressId: vm.emailAddressId
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.histories = data.content;
                vm.page = pagingParams.page;
            }

            function onError(error) {
            }
        }

        vm.back = function () {
            $state.go(previousState.name, previousState.params);
        };

        vm.openEmail = function ($content) {
            var wnd = window.open("about:blank", "", "_blank");
            wnd.document.open();

            wnd.document.write($content);

            wnd.document.close();
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                emailAddressId: vm.emailAddressId
            });
        }
    }
})();
