/**
 * Created by rene on 8/6/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteProviderDetailsController', InviteProviderDetailsController);

    /** @ngInject **/
    function InviteProviderDetailsController($state, $uibModal, $window, $translate, entity, previousState, EmailAddress, noty) {
        var vm = this;

        vm.emailAddress = entity;
        vm.copyToClipboard = copyToClipboard;
        vm.showInviteUrl = undefined;

        function load() {
            EmailAddress.getEmailAddressWithInvites({id: vm.emailAddress.id},
                function (result) {
                    vm.emailAddress = result;
                })
        }

        vm.back = function () {
            $state.go(previousState.name || '^', previousState.params);
        };

        vm.invite = function (email, companyId, emailId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-provider/invite-provider-dialog.html',
                controller: 'InviteProviderDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            companyId: companyId,
                            emailId: emailId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    load();
                }
            }, function () {
            });
        };

        vm.cancel = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-provider/invite-provider-cancel-dialog.html',
                controller: 'InviteProviderCancelDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    load();
                }
            }, function () {
            });
        };

        vm.confirm = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-provider/invite-provider-confirm-dialog.html',
                controller: 'InviteProviderConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    load();
                }
            }, function () {
            });
        };

        vm.edit = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-provider/invite-provider-update-email-dialog.html',
                controller: 'InviteProviderUpdateEmailDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    load();
                }
            }, function () {
            });
        };

        function copyToClipboard($value, $id) {
            if (!$value) return;

            var copyElement = $window.document.createElement("span");
            copyElement.appendChild($window.document.createTextNode($value));
            copyElement.id = 'tempCopyToClipboard';
            angular.element($window.document.body.append(copyElement));

            var range = $window.document.createRange();
            range.selectNode(copyElement);
            $window.getSelection().removeAllRanges();
            $window.getSelection().addRange(range);

            try {
                var result = $window.document.execCommand('copy');
                if (result) {
                    noty.success($translate.instant('message.alert.inviteLink.copy.success'));
                } else {
                    noty.error($translate.instant('message.alert.inviteLink.copy.error'));
                    vm.showInviteUrl = $id;
                }
            } catch (e) {
                noty.error($translate.instant('message.alert.inviteLink.copy.error'));
                vm.showInviteUrl = $id;
            }

            $window.getSelection().removeAllRanges();
            copyElement.remove();
        }
    }
})();
