(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditDeleteController',CreditDeleteController);


    /** @ngInject **/
    function CreditDeleteController($translate, $timeout, $uibModalInstance, entity, Credit, noty) {
        var vm = this;

        vm.credit = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Credit.delete({id: id},
                function () {
                    noty.success($translate.instant('message.alert.credit.delete'));
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    },150);
                });
        }
    }
})();
