(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('giroApiApp')
        .factory('PrintFile', PrintFile);

    PrintFile.$inject = ['$http', '$rootScope', '$compile', '$timeout', '$q'];

    function PrintFile($http, $rootScope, $compile, $timeout, $q) {

        var service = {
            printExternalFile: printExternalFile,
            printHtml: printHtml
        };

        return service;

        function printExternalFile(templateUrl, data) {
            $http.get(templateUrl).success(function (template) {
                var printScope = angular.extend($rootScope.$new(), data);
                var element = $compile($('<div>' + template + '</div>'))(printScope);
                var waitForRenderAndPrint = function () {
                    if (printScope.$$phase || $http.pendingRequests.length) {
                        $timeout(waitForRenderAndPrint);
                    } else {
                        var htmlContent = "<!doctype html>" +
                            "<html>" +
                            '<body onload="printAndRemove();">' +
                            element.html() +
                            '</body>' +
                            "</html>";
                        var newWin = window.open(htmlContent, "_blank");
                        newWin.document.write(htmlContent);
                        printScope.$destroy();
                    }
                };
                waitForRenderAndPrint();
            });
        }

        function printHtml(html) {

            var deferred = $q.defer();
            var hiddenFrame = $('<iframe id="printFrame" style="display: none"></iframe>').appendTo('body')[0];

            hiddenFrame.contentWindow.printAndRemove = function () {
                hiddenFrame.contentWindow.print();
                $(hiddenFrame).remove();
            };

            var htmlContent = "<!doctype html>" +
                "<html>" +
                '<body onload="printAndRemove();">' +
                html +
                '</body>' +
                "</html>";

            var doc = hiddenFrame.contentWindow.document.open("text/html", "replace");
            doc.write(htmlContent);
            deferred.resolve();
            doc.close();
            return deferred.promise;
        };
    }
})();
