/**
 * Created by rene on 1/3/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider.state('email-address-history', {
            parent: 'entity',
            url: '/email-address-history?emailAddressId&page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                // pageTitle: 'Giro - Central Eletrônica de Créditos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/email-address-history/email-address-histories.html',
                    controller: 'EmailAddressHistoryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                },
                previousState: function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'entity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('email-address-history');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
