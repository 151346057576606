(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('File', File);

    function File($resource) {
        var resourceUrl = 'api/files/:id/:first/:second';

        return $resource(resourceUrl, {id: '@id'}, {
            saveCompanyFile: {method: 'POST', params: {first: 'company'}},
            download: {method: 'GET', params: {first: 'download'}},
            getCompanyFiles: {method: 'GET', params: {first: 'company'}, isArray: true},
            deleteCompanyFile: {method: 'DELETE', params: {first: 'company'}}
        });
    }
})();
