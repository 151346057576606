(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('WitnessManagerController', WitnessManagerController);

    /** @ngInject **/
    function WitnessManagerController($state, Signer, witness, organization) {
        var vm = this;
        vm.witnessList = witness;

        if (vm.witnessList.length > 0) {
            vm.witnessList.map(function (value) {
                value.birthday = new Date(Date.parse(value.birthday));
            });
        }

        vm.birthday = {
            datepickerOptions: {
                maxDate: new Date()
            },
            ngModelOptions: {
                timezone: 'UTC'
            }
        };

        vm.addWitness = function (editForm) {
            vm.witnessList.push({birthdayStatus: false});
        };

        vm.removeWitness = function ($index) {
            vm.witnessList.splice($index, 1);
        };

        vm.openCalendar = function ($witness) {
            $witness.birthdayStatus = true;
        };

        vm.save = function () {
            Signer.createWitness({witness: vm.witnessList, organization: organization}, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess(result) {
            $state.go('organization');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            vm.success = null;
        }

        vm.clear = function () {
            $state.go('organization');
        }
    }
})();
