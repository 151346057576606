(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProviderTitleReportController', ProviderTitleReportController);

    function ProviderTitleReportController($filter, $state, $stateParams, ParseLinks, pagingParams, previousState, paginationConstants, noty, defaultOrganization, PrintFile,
                                           ProviderTitle, ProgramProvider, StringUtils, Installment, SubscriptionCode, Principal, Company, CompanyProvider, GiroConstants, Organization) {
        var vm = this;

        vm.enableBack = $stateParams.enableBack;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.printPaymentVoucher = printPaymentVoucher;
        vm.goToCreditVizualizer = goToCreditVizualizer;
        vm.backToProviderPanel = backToProviderPanel;
        vm.mapSubsidiary = mapSubsidiary;
        vm.showOrganizations = true;

        if (defaultOrganization.organizationKey !== "giro") {
            vm.defaultOrganization = defaultOrganization;
            vm.organization = vm.defaultOrganization;
            vm.organizationId = vm.defaultOrganization.id;
            loadSubsidiaries();
        } else {
            vm.organizationId = $stateParams.organizationId;
        }

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.currency = GiroConstants.Constants.currency;

        vm.companyId = $stateParams.companyId;
        vm.programProvider = undefined;
        vm.programProviders = [];

        vm.query = $stateParams.query;
        vm.released = $stateParams.released;
        vm.titleNumber = $stateParams.titleNumber;
        vm.invoiceNumber = $stateParams.invoiceNumber;
        vm.canAnticipate = $stateParams.canAnticipate;
        vm.status = $stateParams.status;
        vm.emissionDateStart = $stateParams.emissionDateStart;
        vm.emissionDateEnd = $stateParams.emissionDateEnd;
        vm.debitDateStart = $stateParams.debitDateStart;
        vm.debitDateEnd = $stateParams.debitDateEnd;
        vm.paymentDateStart = $stateParams.paymentDateStart;
        vm.paymentDateEnd = $stateParams.paymentDateEnd;
        vm.dueDateStart = $stateParams.dueDateStart;
        vm.dueDateEnd = $stateParams.dueDateEnd;
        vm.buyerSearch = $stateParams.buyerSearch;
        vm.subsidiary = $stateParams.subsidiary;
        vm.allCompanies = 'true' === $stateParams.allCompanies;
        vm.showProviderOnCard = true === vm.allCompanies;

        vm.datePickerEmissionDateStartOpenStatus = false;
        vm.datePickerEmissionDateEndOpenStatus = false;
        vm.datePickerDebitDateStartOpenStatus = false;
        vm.datePickerDebitDateEndOpenStatus = false;
        vm.datePickerPaymentDateStartOpenStatus = false;
        vm.datePickerPaymentDateEndOpenStatus = false;
        vm.datePickerDueDateStartOpenStatus = false;
        vm.datePickerDueDateEndOpenStatus = false;

        if ($stateParams.emissionDateStart) {
            vm.emissionDateStart = new Date($stateParams.emissionDateStart);
        }
        if ($stateParams.emissionDateEnd) {
            vm.emissionDateEnd = new Date($stateParams.emissionDateEnd);
        }
        if ($stateParams.debitDateStart) {
            vm.debitDateStart = new Date($stateParams.debitDateStart);
        }
        if ($stateParams.debitDateEnd) {
            vm.debitDateEnd = new Date($stateParams.debitDateEnd);
        }
        if ($stateParams.paymentDateStart) {
            vm.paymentDateStart = new Date($stateParams.paymentDateStart);
        }
        if ($stateParams.paymentDateEnd) {
            vm.paymentDateEnd = new Date($stateParams.paymentDateEnd);
        }
        if ($stateParams.dueDateStart) {
            vm.dueDateStart = new Date($stateParams.dueDateStart);
        }
        if ($stateParams.dueDateEnd) {
            vm.dueDateEnd = new Date($stateParams.dueDateEnd);
        }

        vm.subsidiaries = [];

        vm.findProviderTitles = function () {
            if (Principal.hasAnyAuthority(["ROLE_FINANCIAL", "ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT']) && !vm.programProvider) {
                noty.error("Selecione um fornecedor!");
                return;
            }

            transition();
        };

        function find() {
            ProviderTitle.filter({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                number: vm.number,
                companyId: vm.companyId,
                status: vm.status,
                invoiceNumber: vm.invoiceNumber,
                titleNumber: vm.titleNumber,
                canAnticipate: vm.canAnticipate,
                released: vm.released,
                emissionDateStart: $filter('date')(vm.emissionDateStart, 'yyyy-MM-dd'),
                emissionDateEnd: $filter('date')(vm.emissionDateEnd, 'yyyy-MM-dd'),
                debitDateStart: $filter('date')(vm.debitDateStart, 'yyyy-MM-dd'),
                debitDateEnd: $filter('date')(vm.debitDateEnd, 'yyyy-MM-dd'),
                paymentDateStart: $filter('date')(vm.paymentDateStart, 'yyyy-MM-dd'),
                paymentDateEnd: $filter('date')(vm.paymentDateEnd, 'yyyy-MM-dd'),
                dueDateStart: $filter('date')(vm.dueDateStart, 'yyyy-MM-dd'),
                dueDateEnd: $filter('date')(vm.dueDateEnd, 'yyyy-MM-dd'),
                buyerSearch: vm.organization.organizationKey === 'castrolanda' ? vm.subsidiary : vm.buyerSearch,
                allCompanies: vm.allCompanies,
                organizationId: vm.organizationId,
                sort: sort()
            }, function (result, headers) {
                vm.providerTitles = mapInstallments(result.content);
                vm.links = ParseLinks.parse(headers('link'));
                //vm.totalItems = headers('X-Total-Count');
                vm.totalItems = result.totalElements;
                vm.queryCount = vm.totalItems;
                vm.page = result.number + 1;
            });

            function mapInstallments(providerTitles) {
                if (!providerTitles) return [];

                return providerTitles.map(function (pt) {
                    if (!pt.installments) return pt;
                    pt.installments = pt.installments.map(function (it) {
                        if (it.status !== 'OFFSET' || !it.additionalInformation) return it;
                        var offsetInfo = it.additionalInformation
                            .find(function (ai) {
                                return ai.name === 'OFFSET_NUMBER' && ai.value !== null && ai.value !== undefined;
                            });
                        if (offsetInfo !== null && offsetInfo !== undefined) {
                            it.offsetInfo = offsetInfo.value;
                        }
                        return it;
                    });
                    return pt;
                });
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        if (vm.organizationId) {
            loadOrganization();
        } else {
            loadAllOrganizations();
        }

        function loadProgramProvider() {
            CompanyProvider.getCompany({id: vm.companyId}, function (result) {
                vm.programProvider = StringUtils.formatWithoutCanAnticipate(result);
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListWithoutCanAnticipate(result));
            });
        }

        //Selectize ProgramProvider
        function loadAllProgramProviders() {
            CompanyProvider.getCompanies(function (result) {
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListWithoutCanAnticipate(result.content));
            }, function () {
            });
        }

        vm.loadProgramProviders = function (query, callback) {
            CompanyProvider.getCompanies({
                q: query
            }, function (result) {
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListWithoutCanAnticipate(result.content));
                callback(result.content);
            }, function () {
            });
        };

        vm.programProviderSelected = function ($id, $model) {
            vm.programProvider = $model;

            if ($id) {
                vm.companyId = $id;
            } else {
                vm.companyId = undefined;
            }
        };

        //----------

        function loadDefault() {
            if (Principal.hasAnyAuthority(["ROLE_FINANCIAL", "ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT'])) {
                if (vm.companyId) {
                    find();
                    loadProgramProvider();
                } else {
                    loadAllProgramProviders();
                }

            } else {
                vm.programProvider = Principal.getDefaultCompany();
            }

            if (Principal.hasAnyAuthority(["ROLE_PROVIDER_CONSULT"])) {
                find();
            }
        }

        //Selectize Organization
        vm.organizations = [];

        function loadOrganization() {
            Organization.getSimpleById({id: vm.organizationId}, function (result) {
                vm.organization = result;
                vm.organizations = vm.organizations.concat(result);

                loadDefault();
                loadSubsidiaries();
            });
        }

        function loadAllOrganizations() {
            Organization.getSimpleByProvider(function (result) {
                vm.organizations = vm.organizations.concat(result);
                if (vm.organizations.length === 1) {
                    vm.organization = vm.organizations[0];
                    vm.organizationId = vm.organizations[0].id;
                    vm.showOrganizations = false;
                    loadSubsidiaries();
                }

                loadDefault();
            }, function () {
            });
        }

        vm.loadOrganizations = function (query, callback) {
            Organization.getSimpleByProvider({
                query: query
            }, function (result) {
                vm.organizations = vm.organizations.concat(result);
                callback(result);
            }, function () {
            });
        };

        vm.organizationSelected = function ($id, $model) {
            if ($id) {
                vm.organization = $model;
                vm.organizationId = $id;
            } else {
                vm.organization = undefined;
                vm.organizationId = undefined;
            }
            loadSubsidiaries();
        };
        //----------

        vm.openEmissionDateStartOpen = function () {
            vm.datePickerEmissionDateStartOpenStatus = true;
        };

        vm.openEmissionDateEndOpen = function () {
            vm.datePickerEmissionDateEndOpenStatus = true;
        };

        vm.openDebitDateStartOpen = function () {
            vm.datePickerDebitDateStartOpenStatus = true;
        };

        vm.openDebitDateEndOpen = function () {
            vm.datePickerDebitDateEndOpenStatus = true;
        };

        vm.openPaymentDateStartOpen = function () {
            vm.datePickerPaymentDateStartOpenStatus = true;
        };

        vm.openPaymentDateEndOpen = function () {
            vm.datePickerPaymentDateEndOpenStatus = true;
        };

        vm.openDueDateStartOpen = function () {
            vm.datePickerDueDateStartOpenStatus = true;
        };

        vm.openDueDateEndOpen = function () {
            vm.datePickerDueDateEndOpenStatus = true;
        };

        vm.reset = function () {
            vm.query = undefined;
            vm.invoiceNumber = undefined;
            vm.released = undefined;
            vm.titleNumber = undefined;
            vm.canAnticipate = undefined;
            vm.status = undefined;
            vm.emissionDateStart = undefined;
            vm.emissionDateEnd = undefined;
            vm.debitDateStart = undefined;
            vm.debitDateEnd = undefined;
            vm.paymentDateStart = undefined;
            vm.paymentDateEnd = undefined;
            vm.dueDateStart = undefined;
            vm.dueDateEnd = undefined;
            vm.buyerSearch = undefined;
            vm.subsidiary = undefined;
            vm.allCompanies = false;
            transition();
        };

        function loadSubsidiaries() {
            if (!vm.organization || !SubscriptionCode[vm.organization.organizationKey]) {
                vm.subsidiaries = [];
                return;
            }

            vm.subsidiaries = Object.keys(SubscriptionCode[vm.organization.organizationKey])
                .map(function ($key) {
                    return {id: $key, name: SubscriptionCode[vm.organization.organizationKey][$key]};
                });
        }

        function mapSubsidiary($subsidiary) {
            if (!vm.organization) return "";
            if (vm.organization.organizationKey !== 'castrolanda') return "";
            return SubscriptionCode[vm.organization.organizationKey][$subsidiary];
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function printPaymentVoucher(paymentVoucher) {
            switch (paymentVoucher.bankName) {
                case 'BANCO ITAU S.A.':
                    var url = $state.href('payment-voucher-itau', {
                        id: paymentVoucher.id,
                        organizationKey: vm.organization.organizationKey
                    });
                    window.open(url, '_blank');
                    break;
                case 'BANCO SANTANDER S.A.':
                    var url = $state.href('payment-voucher-santander', {
                        id: paymentVoucher.id,
                        organizationKey: vm.organization.organizationKey
                    });
                    window.open(url, '_blank');
                    break;
                default:
                    var url = $state.href('payment-voucher-default', {
                        id: paymentVoucher.id,
                        organizationKey: vm.organization.organizationKey
                    });
                    window.open(url, '_blank');
                    break;
            }

            noty.success('Comprovante de pagamento emitido, caso não apareça verifique se seu browser não bloqueou a abertura da nova janela.');
        }

        function goToCreditVizualizer() {
            ProgramProvider.resume(function (result) {
                if (!result.anticipate) {
                    noty.error("Você não possuí recebíveis disponíveis para antecipar.");
                    return;
                }

                if (result.programs.length === 1) {
                    $state.go('credit-detail-provider', {
                        providerId: result.programs[0].programProviderId,
                        programId: result.programs[0].programId
                    });
                } else if (result.programs.length > 1) {
                    $state.go('provider-programs');
                } else {
                    noty.error("Você não possuí recebíveis disponíveis para antecipar.");
                }
            });
        }

        function backToProviderPanel() {
            $state.go(previousState.name || 'home', $stateParams);
        }

        vm.report = function () {
            if (Principal.hasAnyAuthority(["ROLE_FINANCIAL", "ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT']) && !vm.programProvider) {
                noty.error("Selecione um fornecedor!");
                return;
            }

            if (!vm.organization) {
                noty.error("Selecione uma Organização!");
                return;
            }

            var organizationKey = vm.organization.organizationKey;

            Installment.report({
                companyId: vm.companyId,
                status: vm.status,
                invoiceNumber: vm.invoiceNumber,
                titleNumber: vm.titleNumber,
                canAnticipate: vm.canAnticipate,
                released: vm.released,
                emissionDateStart: $filter('date')(vm.emissionDateStart, 'yyyy-MM-dd'),
                emissionDateEnd: $filter('date')(vm.emissionDateEnd, 'yyyy-MM-dd'),
                debitDateStart: $filter('date')(vm.debitDateStart, 'yyyy-MM-dd'),
                debitDateEnd: $filter('date')(vm.debitDateEnd, 'yyyy-MM-dd'),
                paymentDateStart: $filter('date')(vm.paymentDateStart, 'yyyy-MM-dd'),
                paymentDateEnd: $filter('date')(vm.paymentDateEnd, 'yyyy-MM-dd'),
                dueDateStart: $filter('date')(vm.dueDateStart, 'yyyy-MM-dd'),
                dueDateEnd: $filter('date')(vm.dueDateEnd, 'yyyy-MM-dd'),
                buyerSearch: vm.organization.organizationKey === 'castrolanda' ? vm.subsidiary : vm.buyerSearch,
                allCompanies: vm.allCompanies,
                organizationKey: organizationKey
            }, function (result) {
                var doc = new jsPDF({
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a4'
                });

                //Title
                createHeader(doc, result.organizationName);
                var amount = result.providers.length;

                result.providers.forEach(function ($provider, $index) {
                    if ($index !== 0) {
                        doc.addPage();
                        doc.line(10, 23, 288, 23);
                    }
                    createProviderReport(
                        doc,
                        $provider,
                        $index,
                        result.organizationName,
                        amount === 1,
                        amount && amount === $index + 1,
                        result.total
                    );
                });

                doc.save('relatorio-faturas.pdf'); // height = 208
                //

            });
        };

        function createProviderReport($doc, $provider, $index, organizationName, $isUnique, $isLast, $total) {
            createFiltersLabel($doc, $provider, $index);
            createFiltersValue($doc, $provider, $index);

            var has_aggregate_id = $provider.subsidiaries
                .map(function ($it) {
                    return $it.entryDates;
                })
                .flat()
                .map(function ($it) {
                    return $it.installments;
                })
                .flat()
                .some(function ($it) {
                    return $it && $it.aggregateId;
                })
            ;

            createTableHeader($doc, $index === 0 ? 58 : 44, $provider.canAnticipate, has_aggregate_id);

            var start = $index === 0 ? 65 : 51;
            $provider.subsidiaries.forEach(function ($subsidiary) {
                addFilial($subsidiary, $doc, start); // + 7

                $subsidiary.entryDates.forEach(function ($entryDate) {
                    start = plusStart(start, 7);

                    if ($entryDate.offsets) {
                        $entryDate.offsets.forEach(function ($offset) {
                            addOffset($offset, $doc, start);
                            start = plusStart(start, 7);

                            if ($offset.installments) {
                                $offset.installments.forEach(function ($installment) {
                                    addTableCell($installment, $doc, start, $provider.canAnticipate, has_aggregate_id);
                                    if ($installment.observation) {
                                        start = plusStart(start, 14);
                                    } else {
                                        start = plusStart(start, 7);
                                    }
                                    addVoucherObservation($installment, $doc, has_aggregate_id, start)
                                });
                            }

                            start = plusStart(start, 4);
                        })
                    }

                    if ($entryDate.installments) {
                        $entryDate.installments.forEach(function ($installment) {
                            addTableCell($installment, $doc, start, $provider.canAnticipate, has_aggregate_id);

                            if ($installment.observation) {
                                start = plusStart(start, 14);

                            } else {
                                start = plusStart(start, 7);
                            }
                        });
                    }

                    addTotalByDate($entryDate.total, $entryDate.entryDate, $doc, start); // + 7
                    start = plusStart(start, 8);
                });

                addTotalByFilial($subsidiary.total, $subsidiary, $doc, start); // + 8
                start = plusStart(start, 8);
            });
            //

            if (!$isUnique) {
                addTotalByProvider($provider.total, $provider, $doc, start); // + 8
            }

            if ($isLast) {
                if (!$isUnique) {
                    start = plusStart(start, 8);
                }
                addTotal($total, $doc, start); // + 8
            }
            if ($provider.canAnticipate && $provider.totalAnticipate) {
                start = plusStart(start, 7);
                addAnticipationTotal($provider.totalAnticipate, $doc, start);
            }

            if ($provider.canAnticipate && $provider.totalAnticipateCredit > 0) {
                $doc.addPage();
                createAnticipationMessage($doc, 10, $provider.totalAnticipateCredit, $provider.estimateAnticipate, $provider.investorRemuneration,
                    $provider.platformRemuneration, $provider.programProviderId, $provider.programId, $provider.url, organizationName);
            }


            function plusStart($start, $size) {
                if ($start + $size < 200) {
                    return $start + $size;
                } else {
                    $doc.addPage();
                    return 10;
                }
            }


        }

        function createAnticipationMessage($doc, $start, $totalAnticipate, $estimateAnticipate, $investorRemuneration,
                                           $platformRemuneration, $programProviderId, $programId, $url, $organization) {
            $doc.rect(10, $start, 278, 80);
            $doc.setFontSize(15);
            $doc.setFontStyle('bold');
            $doc.text('Programa de antecipação de recebíveis ' + $organization, 12, $start + 8);

            $doc.setFontSize(12).setFontStyle('normal');

            var text1 = '';
            $doc.splitTextToSize(''.concat(
                'A ', (vm.programProvider ? vm.programProvider.name : ''),
                ' participa do programa de antecipação de pagamentos a fornecedores da ',
                $organization + '. '), 288)
                .forEach(function ($value) {
                    text1 = text1.concat($value);
                });

            var text2 = ''.concat('As empresas selecionadas para o programa podem antecipar o pagamento das faturas em aberto ao custo de ',
                $filter('number')($investorRemuneration, 2), ' ao mês + ', $filter('number')($platformRemuneration, 2),
                ' fixo por transação. As solicitações</br><b>aprovadas até as 12h são pagas no mesmo dia*</b> e após esse horário são pagas no próximo dia útil.');

            var text3 = '';
            $doc.splitTextToSize(''.concat('Você possui um total de <b>R$ ', $filter('number')($totalAnticipate, 2),
                '</b> em recebíveis que podem ser antecipados, efetuando a solicitação na data de hoje (', $filter('date')(Date.now(), 'dd/MM/yyyy'),
                ') o valor pago com descontos é de R$ ', $filter('number')($estimateAnticipate, 2), '.'), 310).forEach(function ($value) {
                text3 = text3.concat('</br>', $value);
            });

            var html = '<p style="margin: 0!important;">' + text1 + '</p>' +
                '<p style="margin: 0!important;">' + text2 + '</p>' +
                '<p style="margin: 0!important;">' + text3 + '</p>';

            $doc.fromHTML(html, 12, $start + 15);

            $doc.setFontSize(10)
                .text('* Transferências abaixo de R$5.000,00 são feitas via DOC e caem na conta no próximo dia útil.', 12, $start + 78);

            $doc.setDrawColor(8, 124, 205);
            $doc.setLineWidth(0.6);
            $doc.roundedRect(125, $start + 56, 44, 14, 3, 3);
            $doc.link(125, $start + 56, 44, 14, {url: $url + '?providerId='.concat($programProviderId, '&programId=', $programId)});

            $doc.setTextColor(8, 124, 205);
            $doc.setFontSize(12);
            $doc.setFontStyle('bold');
            $doc.text('SOLICITE AGORA', 147, $start + 64.5, null, null, 'center');
        }

        function createHeader($doc, $organization) {
            $doc.setFontSize(20);
            $doc.setFontStyle('bold');
            $doc.text($organization, 10, 13);

            $doc.setFontStyle('normal');
            $doc.setFontSize(18);
            $doc.text('Relatório de Faturas', 150, 15, null, null, 'center');

            $doc.setFontSize(14);
            $doc.text('Portal de Fornecedores', 15, 18);

            $doc.line(10, 23, 288, 23);
        }

        function createFiltersLabel($doc, $provider, $index) {
            var firstWidth = 10;
            var secondWidth = 125;
            var thirdWidth = 210;
            var startWidth = 30;
            $doc.setFontStyle('bold');
            $doc.setFontSize(10);
            $doc.text('Fornecedor:', firstWidth, startWidth);
            if ($index !== 0) {
                $doc.line(10, 34, 288, 34);
                return;
            }
            $doc.text('Situação do título:', firstWidth, startWidth + 5);
            $doc.text('Número NF:', firstWidth, startWidth + 10);
            $doc.text('Número título:', firstWidth, startWidth + 15);

            if (vm.organization.organizationKey === 'castrolanda') {
                $doc.text('Permite antecipação:', secondWidth, startWidth + 5);
            }
            $doc.text('Data de Emissão:', secondWidth, startWidth + 10);
            $doc.text('Data de Vencimento:', secondWidth, startWidth + 15);

            if (vm.organization.organizationKey === 'castrolanda') {
                $doc.text('Liberado:', thirdWidth, startWidth + 5);
            }
            $doc.text('Data Ajustada:', thirdWidth, startWidth + 10);
            $doc.text('Data da Baixa:', thirdWidth, startWidth + 15);

            $doc.line(10, 50, 288, 50);
        }

        function createFiltersValue($doc, $provider, $index) {
            var firstWidth = 10;
            var secondWidth = 125;
            var thirdWidth = 210;
            var startWidth = 30;
            var canAnticipate = vm.canAnticipate === 'true' ? 'Sim' : 'Não';
            var released = vm.released === 'true' ? 'Sim' : 'Não';
            var status = vm.status === 'true' ? 'Pagos' : 'Em aberto';

            $doc.setFontStyle('normal');
            $doc.setFontSize(10);
            $doc.text($provider.providerName + ' - ' + $provider.providerIdentification, firstWidth + 22, startWidth);

            if ($index !== 0) {
                $doc.line(10, 34, 288, 34);
                return;
            }

            $doc.text(vm.status ? status : 'Todos', firstWidth + 32, startWidth + 5);
            $doc.text(vm.invoiceNumber ? vm.invoiceNumber.toString() : '', firstWidth + 22, startWidth + 10);
            $doc.text(vm.titleNumber ? vm.titleNumber.toString() : '', firstWidth + 26, startWidth + 15);

            if (vm.organization.organizationKey === 'castrolanda') {
                $doc.text(vm.canAnticipate ? canAnticipate : 'Todos', secondWidth + 38, startWidth + 5);
            }
            $doc.text(buildFilterDate(vm.emissionDateStart, vm.emissionDateEnd), secondWidth + 30, startWidth + 10);
            $doc.text(buildFilterDate(vm.dueDateStart, vm.dueDateEnd), secondWidth + 36, startWidth + 15);

            if (vm.organization.organizationKey === 'castrolanda') {
                $doc.text(vm.released ? released : 'Todos', thirdWidth + 18, startWidth + 5);
            }
            $doc.text(buildFilterDate(vm.debitDateStart, vm.debitDateEnd), thirdWidth + 26, startWidth + 10);
            $doc.text(buildFilterDate(vm.paymentDateStart, vm.paymentDateEnd), thirdWidth + 26, startWidth + 15);

            $doc.line(10, 50, 288, 50);
        }

        function buildFilterDate($start, $end) {
            var result = '';
            if ($start) {
                result = $filter('date')(new Date($start), 'dd/MM/yy');
            }

            if ($start && $end) {
                result = result + ' à ';
            }

            if ($end) {
                result = result + $filter('date')(new Date($end), 'dd/MM/yy');
            }

            return result;
        }

        function createTableHeader($doc, startHeight, $canAnticipate, $has_aggregate) {
            var firstWidth = 11;
            var anticipateWidth = 0;

            if ($canAnticipate) {
                anticipateWidth = 5;
            }

            $doc.setFontSize(10);
            $doc.setFontStyle('bold');
            $doc.text('Dt. Entrada', firstWidth, startHeight);
            $doc.text('Dt. Emissão', firstWidth + 23, startHeight);
            $doc.text('Título', firstWidth + 47, startHeight);
            $doc.text('Nf-Serie/Parcela', firstWidth + 79, startHeight);
            $doc.text('Vencimento\nData Original', firstWidth + 114, startHeight - 2);
            $doc.text('Vencimento\nData Ajustada', firstWidth + 140, startHeight - 2);
            $doc.text('Valor', firstWidth + 168, startHeight);
            $doc.text('Data\nPagamento', firstWidth + 196 - anticipateWidth, startHeight - 2);

            if (vm.organization.organizationKey === 'castrolanda') {
                $doc.text('Atraso', firstWidth + 228 - anticipateWidth, startHeight);
                $doc.text('Liberado', firstWidth + 241 - anticipateWidth, startHeight);
                if ($canAnticipate) {
                    $doc.text('Permite \nAntecipação', firstWidth + 260, startHeight - 2);
                }
            } else {
                $doc.text('Valor Pago', firstWidth + 220, startHeight);
                if ($has_aggregate) {
                    $doc.text('Identificador\nPagamento', firstWidth + 253, startHeight - 2);
                }
            }
        }

        function addFilial($subsidiary, $doc, startWidth) {
            $doc.setFontSize(10);
            var firstWidth = 11;

            $doc.setFontStyle('bold');
            $doc.text('Filial:', firstWidth, startWidth);

            $doc.setFontStyle('normal');
            var label = getSubscriptionCode($subsidiary);
            $doc.text(label, firstWidth + 10, startWidth);
        }

        function addOffset($offset, $doc, startWidth) {
            $doc.setFontSize(10);
            var firstWidth = 11;

            $doc.setFontStyle('bold');
            $doc.text('Número Compensação:', firstWidth, startWidth);

            $doc.setFontStyle('normal');
            $doc.text($offset.number, firstWidth + 43, startWidth);
        }

        function getSubscriptionCode($subsidiary) {
            var value = '';

            switch (vm.organization.organizationKey) {
                case 'castrolanda':
                    value = '' + $subsidiary.code + ' - ' + SubscriptionCode.castrolanda[$subsidiary.code];
                    break;
                default:
                    value = '' + $subsidiary.buyerName + ' - ' + $subsidiary.buyerIdentification;
            }

            return value;
        }

        function addTableCell($installment, $doc, startWidth, $canAnticipate, $has_aggregate_id) {
            var firstWidth = 11;
            var anticipateWidth = 0;

            if ($canAnticipate) {
                anticipateWidth = 5;
            }

            $doc.setFontSize(10);
            $doc.setFontStyle('normal');
            if ($installment.entryDate) {
                $doc.text($filter('date')(new Date($installment.entryDate), 'dd/MM/yy', 'UTC'), firstWidth, startWidth);
            }
            if ($installment.emissionDate) {
                $doc.text($filter('date')(new Date($installment.emissionDate), 'dd/MM/yy', 'UTC'), firstWidth + 23, startWidth);
            }
            $doc.text('' + $installment.titleNumber, firstWidth + 47, startWidth);

            if ($installment.serial) {
                $doc.text('' + $installment.invoiceNumber + '-' + $installment.serial + '/' + $installment.installmentNumber, firstWidth + 79, startWidth);
            } else {
                $doc.text($installment.installmentNumber, firstWidth + 79, startWidth);
            }

            $doc.text($filter('date')(new Date($installment.expirationDate), 'dd/MM/yy', 'UTC'), firstWidth + 114, startWidth);
            $doc.text($filter('date')(new Date($installment.expirationChanged ? $installment.expirationChanged : $installment.expirationDate), 'dd/MM/yy', 'UTC'), firstWidth + 140, startWidth);
            $doc.text($filter('number')($installment.value, 2), firstWidth + 168, startWidth);

            if (vm.organization.organizationKey === 'castrolanda') {
                var paymentDate = $installment.paymentDate ? $filter('date')(new Date($installment.paymentDate), 'dd/MM/yy', 'UTC') : 'Sim';
                $doc.text($installment.payed ? paymentDate : 'Não', firstWidth + 196 - anticipateWidth, startWidth);
                $doc.text($installment.paymentLate ? '' + $installment.paymentLate : '0', firstWidth + 228 - anticipateWidth, startWidth);
                $doc.text($installment.paymentBlocked ? 'Não' : 'Sim', firstWidth + 244 - anticipateWidth, startWidth);

                if ($canAnticipate) {
                    $doc.text($installment.canAnticipate ? 'Sim' : 'Não', firstWidth + 263, startWidth);
                }
            } else {
                var paymentDate = $installment.paymentDate ? $filter('date')(new Date($installment.paymentDate), 'dd/MM/yy', 'UTC') : '-';
                $doc.text(paymentDate, firstWidth + 196, startWidth);

                $doc.text($filter('number')($installment.paymentValue, 2), firstWidth + 220, startWidth);

                if ($has_aggregate_id && $installment.aggregateId) {
                    $doc.text('' + $installment.aggregateId, firstWidth + 253, startWidth);
                }
            }

            if (vm.organization.organizationKey === 'neodent' && $installment.voucherTitleNumber != null) {
                $doc.text('Nº Pgto:' + $installment.voucherTitleNumber, firstWidth + 242, startWidth);
            }

            if ($installment.observation) {
                addObservation($installment.observation, $doc, startWidth);
            }
        }

        function addVoucherObservation($installment, $doc, $has_aggregate_id, $startWidth) {
            var firstWidth = 11;
            var startWidth = $startWidth;
            $doc.setFontSize(10);
            $doc.setFontStyle('normal');

            $doc.text('Número de Compensação: ' + $installment.voucherTitleNumber, firstWidth, startWidth);
            if ($installment.voucherInstallmentNumber) {
                $doc.text('Data da Compensação: ' + moment($installment.voucherInstallmentNumber, "YYYYMMDD")
                    .format('DD/MM/YYYY'), firstWidth + 70, startWidth);
            }
        }

        function addObservation($observation, $doc, $startWidth) {
            var firstWidth = 11;
            var startWidth = $startWidth + 7;
            $doc.setFontSize(10);
            $doc.setFontStyle('normal');
            $doc.text($observation ? $observation : '', firstWidth + 23, startWidth);

            $doc.setFontStyle('bold');
            $doc.text('Observação: ', firstWidth, startWidth);
        }

        function addTotalByDate($total, $date, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total na data ' + $filter('date')(new Date($date), 'dd/MM/yy', 'UTC') + ':', firstWidth - 30, startWidth, null, null, 'right');
        }

        function addTotalByFilial($total, $subsidiary, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([2.5]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);


            var label = getSubscriptionCode($subsidiary);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total para a Filial ' + label + ': ', firstWidth - 29, startWidth, null, null, 'right');
        }

        function addTotalByProvider($total, $provider, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([2.5]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total para o Fornecedor ' + $provider.providerName + ' - ' + $provider.providerIdentification + ': ', firstWidth - 29, startWidth, null, null, 'right');
        }

        function addTotal($total, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Valor Total: ', firstWidth - 30, startWidth, null, null, 'right');
        }

        function addAnticipationTotal($total, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total que permite Antecipação: ', firstWidth - 30, startWidth, null, null, 'right');
        }

        function transition() {
            $state.transitionTo($state.$current, {
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                page: vm.page,
                released: vm.released,
                titleNumber: vm.titleNumber,
                invoiceNumber: vm.invoiceNumber,
                canAnticipate: vm.canAnticipate,
                emissionDateStart: vm.emissionDateStart,
                emissionDateEnd: vm.emissionDateEnd,
                debitDateStart: vm.debitDateStart,
                debitDateEnd: vm.debitDateEnd,
                paymentDateStart: vm.paymentDateStart,
                paymentDateEnd: vm.paymentDateEnd,
                dueDateStart: vm.dueDateStart,
                dueDateEnd: vm.dueDateEnd,
                companyId: vm.companyId,
                organizationId: vm.organizationId,
                status: vm.status,
                buyerSearch: vm.buyerSearch,
                subsidiary: vm.subsidiary,
                allCompanies: vm.allCompanies
            });
        }

    }
})
();
