(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AnticipationPanelInviteProviderController', AnticipationPanelInviteProviderController);

    /** @ngInject **/
    function AnticipationPanelInviteProviderController($uibModalInstance, entity, Company) {
        var vm = this;

        vm.companyId = entity.companyId;
        vm.emailId = entity.emailId;
        vm.email = entity.email;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.invite = function () {
            Company.companyInviteProvider({emailId: vm.emailId}, {id: vm.companyId},
                function () {
                    $uibModalInstance.close(true);
                },

                function () {
                    $uibModalInstance.close(false);
                }
            )
        }
    }
})();
