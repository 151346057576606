/**
 * Created by rene on 8/6/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteProviderCreateAccountController', InviteProviderCreateAccountController);

    /** @ngInject **/
    function InviteProviderCreateAccountController($uibModal, $translate, Person, entity, $stateParams, $rootScope, noty, $scope, $state, Auth, Organization, Invite) {
        var vm = this;

        vm.person = entity;
        Invite.getInviteByToken({id: $stateParams.token, companyName: $stateParams.companyName, identification: $stateParams.identification}, function (status) {
            switch (status.status) {
                case'CONFIRMED':
                    noty.success($translate.instant('message.alert.create.account'));
                    $state.go('login');
                    break;
                case 'WAITING_CONFIRMATION':
                    noty.alert($translate.instant('message.alert.invite.waiting.confirmation'));
                    $state.go('login');
                    break;
                case 'CLOSED':
                    $uibModal.open({
                        templateUrl: 'app/entities/invite-provider/invite-provider-closed-dialog.html',
                        controller: 'InviteProviderClosedDialogController',
                        controllerAs: 'vm',
                        size: 'md'
                    }).result.then(function () {
                        $state.go('login');
                    });
                    break;
            }
        }, function (result) {
            $state.go('login');
        });

        vm.token = $stateParams.token;

        vm.companyName = $stateParams.companyName;
        vm.organizationName = $stateParams.organizationName;
        vm.identification = $stateParams.identification;

        vm.password = undefined;
        vm.confirmPassword = undefined;

        vm.error = null;
        vm.errorUserExists = null;
        vm.save = save;

        function save() {
            vm.person.password = vm.password;

            Person.createPersonByInvite({action: vm.token}, vm.person, onSaveSuccess, onSaveError);

            vm.isSaving = true;
        }

        function onSaveSuccess(result) {
            if(result && result.messageStatus === 'alert.invite.confirmed') {
                noty.success($translate.instant('message.alert.invite.confirmed'));
                $state.go('login');
                return;
            }

            Organization.getParameters().$promise
                .then(function (parameters) {
                    if (parameters.providerAccessConfirmation) {
                        $state.go('provider-account-created');
                    } else {
                        noty.success($translate.instant('message.alert.create.account'));
                        Auth.login({
                            username: vm.person.email,
                            password: vm.password,
                            rememberMe: vm.rememberMe
                        }).then(function () {
                            if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                                $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                                $state.go('home');
                            }

                            $rootScope.$broadcast('authenticationSuccess');

                            if (Auth.getPreviousState()) {
                                var previousState = Auth.getPreviousState();
                                Auth.resetPreviousState();
                                $state.go(previousState.name, previousState.params);
                            }
                            $window.location.reload();
                        }).catch(function () {
                        });
                    }
                })
                .catch(function () {
                    noty.success($translate.instant('message.alert.create.account'));
                    $state.go('login');
                });

            vm.isSaving = false;
        }

        function onSaveError(result) {
            vm.isSaving = false;
            vm.success = null;
            $state.go('login');
        }

        vm.validateForm = function ($editForm) {
            if (vm.isSaving) {
                return true
            }

            return $editForm.$invalid;
        };

        vm.passwordMatch = function ($editForm) {
            var match = true;
            if ($editForm.confirmPassword.$error.minlength || $editForm.confirmPassword.$error.maxlength || $editForm.confirmPassword.$error.required) {
                $editForm.confirmPassword.$error.notmatch = false;
                return false
            }

            if ($editForm.password.$error.minlength || $editForm.password.$error.maxlength) {
                $editForm.confirmPassword.$error.notmatch = false;
                $editForm.confirmPassword.$invalid = false;
                return false
            }

            if (vm.password !== vm.confirmPassword) {
                $editForm.confirmPassword.$error.notmatch = true;
                $editForm.confirmPassword.$invalid = true;

                match = false
            } else {
                $editForm.confirmPassword.$error.notmatch = false;
                $editForm.confirmPassword.$invalid = false;

            }

            return match
        }
    }
})();
