/**
 * Created by rene on 1/3/18.
 */
(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider.state('invite-provider-email-send', {
            parent: 'entity',
            url: '/invite-provider-email-send?emailAddressId&anticipationId',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
                // pageTitle: 'Giro - Central Eletrônica de Créditos'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/email-address/send-invite-provider-email-dialog.html',
                    controller: 'SendInviteProviderEmailDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function (EmailAddress, $stateParams) {
                    return EmailAddress.get({id: $stateParams.emailAddressId}).$promise;
                },
                body: function (EmailAddress, $stateParams) {
                    return EmailAddress.getInviteProviderEmailBody({emailAddressId: $stateParams.emailAddressId, providerId: $stateParams.anticipationId}).$promise;
                },
                previousState: function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'entity',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
