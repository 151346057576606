(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('SaleStatusManagerController', SaleStatusManagerController);

    function SaleStatusManagerController($timeout, $uibModal, $scope, $stateParams, $state, $q, entity, SaleStatus) {
        var vm = this;

        vm.saleStatus = entity;
        vm.clear = clear;
        vm.save = save;

        function clear() {
            $state.go('sale-status');
        }

        function save() {
            vm.isSaving = true;

            if (!vm.saleStatus.considersOnSale) {
                vm.saleStatus.considersOnSale = false;
            }

            if (vm.saleStatus.id) {
                SaleStatus.update(vm.saleStatus, onSaveSuccess, onSaveError);
            } else {
                SaleStatus.save(vm.saleStatus, function (result) {
                    onSaveSuccess(result)
                }, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $state.go('sale-status');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
