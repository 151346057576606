/**
 * Created by rene on 12/28/17.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('company-event', {
                parent: 'entity',
                url: '/company-event?page&sort&search&companyId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company-event/company-events.html',
                        controller: 'CompanyEventsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'eventCreated,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('company-events');
                        $translatePartialLoader.addPart('company-event-type');
                        return $translate.refresh();
                    }]
                }
            })
            .state('company-event.new', {
                parent: 'company-event',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company-event/company-event-manage.html',
                        controller: 'CompanyEventManageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('company-event-type');
                        return $translate.refresh();
                    }],
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'company-event',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    entity: function() {
                        return {};
                    },
                    currentUser: function (GiroAccount) {
                        return GiroAccount.user().$promise;
                    }
                }
            })
            .state('company-event.edit', {
                parent: 'company-event',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company-event/company-event-manage.html',
                        controller: 'CompanyEventManageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function($stateParams, $state, CompanyEvent) {
                        return CompanyEvent.get({id : $stateParams.id}).$promise;
                    },
                    previousState: function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'company-event',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('company-event-type');
                        return $translate.refresh();
                    }],
                    currentUser: function () {
                        return undefined;
                    }
                }
            })
            .state('company-event-type-detail-dialog', {
                parent: 'anticipation-panel',
                url: '/{id}/company/event/detail',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company-event/company-event-detail-dialog.html',
                        controller: 'CompanyEventDetailDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CompanyEvent', function (CompanyEvent) {
                                return CompanyEvent.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('anticipation-panel', null, {reload: 'anticipation-panel'});
                    }, function () {
                        $state.go('anticipation-panel');
                    });
                }]
            })
            .state('company-event.delete', {
                parent: 'company-event',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company-event/company-event-delete-dialog.html',
                        controller: 'CompanyEventDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CompanyEvent', function (CompanyEvent) {
                                return CompanyEvent.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('company-event', null, {reload: 'company-event'});
                    }, function () {
                        $state.go('company-event');
                    });
                }]
            });
    }
})();
