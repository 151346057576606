(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$translate', '$timeout', 'Auth', 'Principal', 'noty'];

    function RequestResetController($translate, $timeout, Auth, Principal, noty) {
        var vm = this;

        vm.error = null;
        vm.errorEmailNotExists = null;
        vm.errorUserNotActive = false;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function (){angular.element('#email').focus();});

        function requestReset () {

            vm.error = null;
            vm.errorEmailNotExists = null;

            Principal.identity().then(function (account) {
                vm.account = account;
            });


            Auth.resetPasswordInit(vm.resetAccount.email).then(function () {
                vm.success = 'OK';
            }).catch(function (response) {
                vm.success = null;
                console.log(response.headers('x-giroapiapp-error'));
                console.log(response);
                if(response.data === 'error.user.notactive') {
                    vm.errorEmailNotExists = null;
                    vm.errorUserNotActive = true
                } else if (response.status === 400 && response.data === 'e-mail address not registered') {
                    noty.error($translate.instant('error.error.user.emailnotregistered'));
                } else {
                    vm.errorUserNotActive = false;
                    vm.errorEmailNotExists = null;
                    vm.error = 'ERROR';
                }
            });
        }
    }
})();
