(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('entity', {
                abstract: true,
                parent: 'app'
            })
            .state('anonymous-entity', {
                abstract: true,
                parent: 'anonymous'
            })
            .state('webapp-redirect', {
                abstract: true,
                parent: 'no-navbar'
            });
    }
})();
