(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteProviderClosedDialogController', InviteProviderClosedDialogController);

    /** @ngInject **/
    function InviteProviderClosedDialogController($uibModalInstance) {
        var vm = this;

        vm.close = close;

        function close() {
            $uibModalInstance.close(true);
        }
    }
})();
