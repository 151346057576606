(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ProviderPanelController', ProviderPanelController);

    function ProviderPanelController($state, Principal, noty, ProgramProvider, CompanyEvent, Organization, GiroAccount) {
        var vm = this;

        vm.goToInvoiceScreen = goToInvoiceScreen;
        vm.goToProviderTitleScreen = goToProviderTitleScreen;
        vm.goToCreditVizualizer = goToCreditVizualizer;
        vm.showProviderPainel = false;
        vm.hasProviderPanel = false;

        GiroAccount.user(function (result) {
            vm.showProviderPainel = result.isProvider;
        });

        Organization.hasProviderPanel(function (result) {
            vm.hasProviderPanel = result.providerPanel;
        });

        GiroAccount.user(function (result) {
            vm.showCustomerPainel = result.isCustomer;
            vm.showProviderPainel = result.isProvider;
        });

        function goToCreditVizualizer() {
            ProgramProvider.resume(function (result) {
                if(!result.anticipate) {
                    openWishAnticipation();
                    return;
                }

                if (result.programs.length === 1) {
                    $state.go('credit-detail-provider', {
                        providerId: result.programs[0].programProviderId,
                        programId: result.programs[0].programId
                    });
                } else if (result.programs.length > 1) {
                    $state.go('provider-programs');
                } else {
                    openWishAnticipation()
                }
            });
        }

        function openWishAnticipation() {
            var company = Principal.getDefaultCompany();
            CompanyEvent.clickAnticipateProgram({"company": company});
            $state.go('home.wishAnticipation');
        }

        function goToInvoiceScreen() {
            $state.go('invoices');
        }

        function goToProviderTitleScreen() {
            $state.go('provider-titles-report');
        }
    }
})();
