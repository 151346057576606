(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Company', Company);

    function Company($resource) {
        var resourceUrl = 'api/companies/:id/:first/:second/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': {method: 'GET', isArray: true},
            'search': {method: 'GET', params: {first: 'search'}, isArray: true},
            'all': {method: 'GET', params: {first: 'all'}},
            'queryByName': {method: 'GET', params: {first: 'query'}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'queryInviteProvider': {
                method: 'GET',
                params: {second: 'invite', first: 'provider'}
            },
            'companyInviteProvider': {
                method: 'PUT',
                params: {first: 'provider', second: 'invite'}
            },
            'companyInviteCustomer': {
                method: 'PUT',
                params: {first: 'customer', second: 'invite'}
            },
            'queryCompanyInviteCustomer': {
                method: 'GET',
                params: {first: 'customer', second: 'invite'}
            },
            'userCompanies': {
                method: 'GET',
                params: {first: 'user-companies'},
                isArray: true
            },
            'getProvidersFromUser': {
                method: 'GET',
                params: {first: 'users', second: 'providers'},
                isArray: true
            },
            'getCompaniesForProgram': {
                method: 'GET',
                params: {first: 'for', second: 'program'}
            },
            'companyDefault': {
                method: 'PUT',
                params: {first: 'company-default'}
            },
            'findAllCompaniesNotAnticipate': {
                method: 'GET',
                params: {first: 'not-anticipate'}
            },
            'getDefaultCompanyAddress': {
                method: 'GET',
                params: {first: 'default', second: 'address'}
            },
            'getDefaultCompanyBankAccount': {
                method: 'GET',
                params: {first: 'default', second: 'bank-account'}
            },
            'getCompanyBankAccount': {
                method: 'GET',
                params: {first: 'bank-account'}
            },
            'getDefaultFiles': {
                method: 'GET',
                params: {first: 'default', second: 'files'}
            },
            'createProviderInAdvance': {
                method: 'POST',
                params: {id: '@id', first: 'email-addresses', second: '@second', third: 'provider-in-advance'}
            }
        });
    }
})();
