(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('PaymentVoucherSantanderController', PaymentVoucherSantanderController);

    function PaymentVoucherSantanderController($stateParams, PaymentVoucher) {
        var vm = this;

        PaymentVoucher.query({organizationKey: $stateParams.organizationKey}, {id: $stateParams.id}, function (result) {
            vm.paymentVoucher = result;
        })
    }
})();
