(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('TagDeleteController', TagDeleteController);

    function TagDeleteController($uibModalInstance, entity, Tag) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Tag.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
