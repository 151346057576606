/**
 * Created by rene on 12/28/17.
 */
(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider
            .state('anticipation-panel', {
                parent: 'entity',
                url: '/anticipation-panel?page&sort&search&programId&startDate&endDate&status&sendInvite&userActive&total&anticipateTitlesNumber&dueMaxStart&dueMaxEnd&dueMinStart&dueMinEnd&&creditCreatedDateMaxStart&creditCreatedDateMaxEnd&creditCreatedDateMinStart&creditCreatedDateMinEnd&canAnticipate&query',

                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/anticipation-panel/anticipation-panel.html',
                        controller: 'AnticipationPanelController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('anticipation-panel');
                        $translatePartialLoader.addPart('company-event-type');
                        return $translate.refresh();
                    }]
                }
            })
            .state('anticipation-panel-detail', {
                parent: 'entity',
                url: '/anticipation-panel-detail/{id}?sort&programId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUYER', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/anticipation-panel/anticipation-panel-detail.html',
                        controller: 'AnticipationPanelDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    entity: function ($stateParams, ProgramProvider) {
                        return ProgramProvider.anticipation({id: $stateParams.id}).$promise;
                    },
                    credit: function ($stateParams, Credit) {
                        return Credit.anticipation({id: $stateParams.id}).$promise;
                    },
                    anticipationByMonth: function ($stateParams, Credit) {
                        return Credit.anticipationByMonth({id: $stateParams.id}).$promise;
                    },
                    pagingParams: function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            programId: $stateParams.programId,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('anticipation-panel');
                        $translatePartialLoader.addPart('company-event-type');
                        $translatePartialLoader.addPart('batch-visualizer-request-dialog');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
