(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('InterestStatus', InterestStatus);

    function InterestStatus($resource) {
        var resourceUrl = 'api/interest-status/:id/:first/:second/:third';

        return $resource(resourceUrl, {id: '@id'}, {
            'create': {method: 'POST'},
            'update': {method: 'PUT'},
            'get': {method: 'GET'},
            'getAll': {method: 'GET'},
            'delete': {method: 'DELETE'}
        });
    }
})();
