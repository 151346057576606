(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ClientTitleController', ClientTitleController);

    function ClientTitleController($state, ClientTitle, Principal, ParseLinks, pagingParams, $uibModal, GiroConstants) {
        var vm = this;

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.backToPanel = backToPanel;
        vm.printBoleto = printBoleto;

        vm.currency = GiroConstants.Constants.currency;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 10;
        vm.currentSearch = '';
        vm.statusSearch = 'ALL';

        loadAll(pagingParams.page);

        function loadAll(page) {
            ClientTitle.query({
                page: page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                searchText: vm.currentSearch,
                searchStatus: vm.statusSearch === 'ALL' ? undefined : vm.statusSearch
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.clientTitles = data.content;
                vm.page = page;
            }

            function onError(error) {
            }
        }

        function printBoleto(boletoNumber, urlBank) {
            $uibModal.open({
                templateUrl: 'app/components/clipboard-modal/clipboard-modal.html',
                controller: 'ClipboardModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: {boletoNumber: boletoNumber, urlBank: urlBank},
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('clipboard-modal');
                        return $translate.refresh();
                    }]
                }
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            loadAll(vm.page);
        }

        function search() {
            loadAll(vm.page);
        }

        function backToPanel() {
            if (Principal.hasAnyAuthority(["ROLE_PROVIDER"])) {
                $state.go('provider-panel');
            } else {
                $state.go('client-panel');
            }
        }
    }
})();
