(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ExternalCredit', ExternalCredit);


    /** @ngInject **/
    function ExternalCredit($resource, DateUtils) {
        var resourceUrl = 'api/credits/:first/:id/:second';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', params: {second: 'get'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.paidDate = DateUtils.convertDateTimeFromServer(data.paidDate);
                        data.requestedDate = DateUtils.convertDateTimeFromServer(data.requestedDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'save': {method: 'POST', params: {first: 'create'}},
            'update': {method: 'PUT', params: {first: 'update'}},
            'expire': {method: 'PUT', params: {id: '@id', second: 'expire'}},
            'list': {
                method: 'GET', params: {first: 'list'}, transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                    }
                    return data;
                }
            }
        });
    }
})();
