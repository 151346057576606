(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('FooterController', FooterController);

    function FooterController() {
        var vm = this;

        vm.openIntercom = function() {
            Intercom('show');
        }
    }
})();
