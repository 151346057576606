(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('addressType', addressType);

    function addressType() {
        return function (value) {
            switch (value) {
                case 'HOME':
                    return "Residência";
                case 'PROFESSIONAL':
                    return "Trabalho";
                case 'OTHER':
                    return "Outro";
            }
            return value;
        }
    }
})();
