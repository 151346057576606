(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('giroApiApp')
        .factory('CityUtils', CityUtils);

    function CityUtils() {
        return {
            formatListToCityNameAndStateAcronym: formatListToCityNameAndStateAcronym,
            formatToCityNameAndStateAcronym: formatToCityNameAndStateAcronym
        };

        function formatListToCityNameAndStateAcronym(cities) {
            return cities.map(function (city) {
                return formatToCityNameAndStateAcronym(city);
            });
        }

        function formatToCityNameAndStateAcronym(city) {
            city["presentationLabel"] = city.name + ' - ' + city.stateAcronym;
            return city;
        }

    }
})();
