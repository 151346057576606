(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyBankAccountApprovedController', CompanyBankAccountApprovedController);

    /** @ngInject **/
    function CompanyBankAccountApprovedController($uibModalInstance) {
        var vm = this;
        vm.confirm = confirm;

        function confirm() {
            $uibModalInstance.close();
        }
    }
})();
