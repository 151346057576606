(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('PersonDetailController', PersonDetailController);

    PersonDetailController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity'];

    function PersonDetailController($state, $scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.person = entity;

        var unsubscribe = $rootScope.$on('giroApiApp:personUpdate', function(event, result) {
            vm.person = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.back = function() {
            $state.go(previousState.name || '^', previousState.params);
        }
    }
})();
