(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Signer', Signer);

    function Signer($resource) {
        var resourceUrl = 'api/signers/:id/:first/:second';

        return $resource(resourceUrl, {id: '@id'}, {
            'createWitness': {
                method: 'POST',
                params: {first: 'witness'}
            },
            'getByOrganization': {
                method: 'GET',
                params: {first: 'organization'},
                isArray: true
            },
            'getByCompany': {
                method: 'GET',
                isArray: true
            },
            'update': {method: 'PUT'}
        });
    }
})();
