(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AnticipationReportController', AnticipationReportController);

    function AnticipationReportController($filter, $state, $stateParams, Principal, Company, previousState, ProgramProvider, Report, StringUtils) {
        var vm = this;

        vm.backToProviderPanel = backToProviderPanel;

        vm.programProviderId = undefined;

        vm.programProvider = undefined;
        vm.company = undefined;
        vm.programProviders = [];
        vm.providers = [];

        vm.approvedDateStart = undefined;
        vm.approvedDateEnd = undefined;

        vm.datePickerApprovedDateStartOpenStatus = false;
        vm.datePickerApprovedDateEndOpenStatus = false;

        if (Principal.hasAnyAuthority(["ROLE_FINANCIAL", "ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT'])) {
            if (vm.programProvider && vm.programProvider.id) {
                find();
                loadProgramProvider()
            } else {
                loadAllProgramProviders();
            }
        }

        getProvidersFromUser();

        function getProvidersFromUser() {
            Company.getProvidersFromUser(
                function (result) {
                    vm.providers = result;
                }
            )
        }

        //Selectize ProgramProvider
        function loadProgramProvider() {
            ProgramProvider.simpleElement({id: vm.programProviderId}, function (result) {
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListWithoutCanAnticipate(result));
            })
        }

        function loadAllProgramProviders() {
            ProgramProvider.searchSimple(function (result) {
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListWithoutCanAnticipate(result.content));
            }, function () {
            });
        }

        vm.loadProgramProviders = function (query, callback) {
            ProgramProvider.searchSimple({
                q: query
            }, function (result) {
                vm.programProviders = vm.programProviders.concat(StringUtils.formatListWithoutCanAnticipate(result.content));
                callback(result.content);
            }, function () {
            });
        };

        vm.programProviderSelected = function ($id, $model) {
            if ($model) {
                vm.programProvider = {
                    id: $model.companyId,
                    name: $model.name,
                    identification: $model.identification
                };
            }

            if ($id) {
                vm.programProviderId = $id;
            } else {
                vm.programProviderId = undefined;
            }
        };
        //----------

        vm.openApprovedDateStartOpen = function () {
            vm.datePickerApprovedDateStartOpenStatus = true;
        };

        vm.openApprovedDateEndOpen = function () {
            vm.datePickerApprovedDateEndOpenStatus = true;
        };

        vm.reset = function () {
            vm.approvedDateStart = undefined;
            vm.approvedDateEnd = undefined;
            vm.programProviderId = undefined;
        };

        vm.parseCompany = function () {
            vm.programProvider = JSON.parse(vm.company)
        };

        function backToProviderPanel() {
            $state.go(previousState.name || 'home', previousState.params);
        }

        vm.reportCsv = function() {
            if (Principal.hasAnyAuthority(["ROLE_FINANCIAL", "ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT']) && !vm.programProvider) {
                noty.error("Selecione um fornecedor!");
                return;
            }

            Report.getAnticipationReport({
                companyId: vm.programProvider ? vm.programProvider.id : undefined,
                approvedDateStart: vm.approvedDateStart,
                approvedDateEnd: vm.approvedDateEnd
            }, function (result) {
                var credits = [];
                result.organizations.forEach(function ($organization) {

                    $organization.companies.forEach(function ($company) {

                        $company.anticipatedDates.forEach(function ($anticipatedDate) {

                            $anticipatedDate.credits.forEach(function ($credit) {
                                credits.push({
                                    identification: $company.identification,
                                    invoice: $credit.invoiceNumber,
                                    externalId: $credit.externalId,
                                    requestedValue: $filter('number')($credit.requestedValue, 2),
                                    total: $filter('number')($credit.total, 2),
                                    dueDate: $filter('date')(new Date($credit.dueDate), 'dd/MM/yyyy', 'UTC'),
                                    requestedDate: $filter('date')(new Date($credit.requestedDate), 'dd/MM/yyyy', 'UTC'),
                                    observation: $credit.observation
                                });
                            });

                        });

                    });

                });
            });
        }
        vm.report = function () {
            if (Principal.hasAnyAuthority(["ROLE_FINANCIAL", "ROLE_ADMIN", "ROLE_ORGANIZATION_SUPPORT", 'ROLE_GIRO_SUPPORT']) && !vm.programProvider) {
                noty.error("Selecione um fornecedor!");
                return;
            }

            Report.getAnticipationReport({
                companyId: vm.programProvider ? vm.programProvider.id : undefined,
                approvedDateStart: vm.approvedDateStart,
                approvedDateEnd: vm.approvedDateEnd
            }, function (result) {
                var doc = new jsPDF({
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a4'
                });

                //Title
                createHeader(doc, result.organizationName);
                createFiltersLabel(doc);
                createFiltersValue(doc);
                createTableHeader(doc, 58);

                var start = 65;
                result.organizations.forEach(function ($organization) {

                    if (result.organizations.length > 1 || !result.organizationKey === result.organizations[0].organizationKey) {
                        addOrganization($organization, doc, start); // + 7
                        start = plusStart(start, 7);
                    }

                    $organization.companies.forEach(function ($company) {
                        addCompany($company, doc, start); // + 7

                        $company.anticipatedDates.forEach(function ($anticipatedDate) {
                            start = plusStart(start, 7);

                            $anticipatedDate.credits.forEach(function ($credits) {
                                addTableCell($credits, doc, start);
                                start = plusStart(start, 7);
                            });

                            addTotalByDate($anticipatedDate.total, $anticipatedDate.anticipatedDate, doc, start); // + 7
                            start = plusStart(start, 8);
                        });

                        addTotalByCompany($company.total, $company, doc, start); // + 8
                        start = plusStart(start, 8);
                    });

                    if (result.organizations.length > 1 || !result.organizationKey === result.organizations[0].organizationKey) {
                        addTotalByOrganization($organization.organizationTotal, $organization, doc, start);
                        start = plusStart(start, 8);
                    }
                });

                var setLine = true;

                if (result.organizations.length > 1 || !result.organizationKey === result.organizations[0].organizationKey) {
                    var companiesTotal = result.organizations
                        .map(function ($organization) {
                            return $organization.companies
                        })
                        .map(function ($company) {
                            return {
                                name: $company[0].companyName,
                                identification: $company[0].identification
                            }
                        })
                        .filter(function (value, index, self) {
                            var company = self.find(function ($it) {
                                return $it.identification === value.identification
                            });

                            return self.indexOf(company) === index
                        });

                    companiesTotal
                        .forEach(function ($company) {
                            $company.total =
                                result.organizations
                                    .map(function ($organization) {
                                        return $organization.companies
                                    })
                                    .filter(function ($it) {
                                        return $it[0].identification === $company.identification
                                    })
                                    .map(function ($it) {
                                        return $it[0].total
                                    })
                                    .reduce(function (a, b) {
                                        return a + b
                                    })
                        });

                    companiesTotal.forEach(function ($company) {
                        addCompanyTotal($company, doc, start); // + 8
                        start = plusStart(start, 8);
                        setLine = false;
                    });
                }

                addTotal(result.total, doc, start, setLine); // + 8

                doc.save('relatorio-faturas.pdf'); // height = 208

                function plusStart($start, $size) {
                    if ($start + $size < 200) {
                        return $start + $size;
                    } else {
                        doc.addPage();
                        return 10;
                    }
                }
            });
        };

        function addCompany($company, $doc, startWidth) {
            $doc.setFontSize(10);
            var firstWidth = 11;

            $doc.setFontStyle('bold');
            $doc.text('Empresa:', firstWidth, startWidth);

            $doc.setFontStyle('normal');
            $doc.text($company.companyName + ' - ' + $company.identification, firstWidth + 17, startWidth);
        }

        function addOrganization($organization, $doc, startWidth) {
            $doc.setFontSize(10);
            var firstWidth = 11;

            $doc.setFontStyle('bold');
            $doc.text('Organização:', firstWidth, startWidth);

            $doc.setFontStyle('normal');
            $doc.text($organization.organizationName, firstWidth + 24, startWidth);
        }

        function createHeader($doc, organizationName) {
            $doc.setFontSize(20);
            $doc.setFontStyle('bold');
            $doc.text(organizationName, 10, 13);

            $doc.setFontStyle('normal');
            $doc.setFontSize(18);
            $doc.text('Relatório de Antecipações', 150, 15, null, null, 'center');

            $doc.setFontSize(14);
            $doc.text('Portal de Fornecedores', 15, 18);

            $doc.line(10, 23, 288, 23);
        }

        function createFiltersLabel($doc) {
            var firstWidth = 10;
            var startWidth = 30;
            $doc.setFontStyle('bold');
            $doc.setFontSize(10);
            $doc.text('Fornecedor:', firstWidth, startWidth);
            $doc.text('Data de aprovação da antecipação:', firstWidth, startWidth + 5);

            $doc.line(10, 50, 288, 50);
        }

        function createFiltersValue($doc) {
            var firstWidth = 10;
            var startWidth = 30;

            $doc.setFontStyle('normal');
            $doc.setFontSize(10);
            $doc.text(vm.programProvider ? vm.programProvider.name + ' - ' + vm.programProvider.identification : 'Todos', firstWidth + 22, startWidth);
            $doc.text(buildFilterDate(vm.approvedDateStart, vm.approvedDateEnd), firstWidth + 60, startWidth + 5);

            $doc.line(10, 50, 288, 50);
        }

        function buildFilterDate($start, $end) {
            var result = '';
            if ($start) {
                result = $filter('date')(new Date($start), 'dd/MM/yy');
            }

            if ($start && $end) {
                result = result + ' à ';
            }

            if ($end) {
                result = result + $filter('date')(new Date($end), 'dd/MM/yy')
            }

            return result;
        }

        function createTableHeader($doc, startWidth) {
            var firstWidth = 11;

            $doc.setFontSize(10);
            $doc.setFontStyle('bold');
            $doc.text('Dt. Antecipação', firstWidth, startWidth);
            $doc.text('Dt. Vencimento', firstWidth + 43, startWidth);
            $doc.text('NF/Serie', firstWidth + 85, startWidth);
            $doc.text('Valor Original', firstWidth + 130, startWidth);
            $doc.text('Desconto', firstWidth + 174, startWidth);
            $doc.text('Valor antecipação', firstWidth + 214, startWidth);
        }

        function addTableCell($credit, $doc, startWidth) {
            var firstWidth = 11;

            $doc.setFontSize(10);
            $doc.setFontStyle('normal');

            $doc.text($filter('date')(new Date($credit.anticipatedDate), 'dd/MM/yy', 'UTC'), firstWidth, startWidth);
            $doc.text($filter('date')(new Date($credit.expirationDate), 'dd/MM/yy', 'UTC'), firstWidth + 43, startWidth);
            $doc.text('' + $credit.nfNumber + '/' + $credit.nfSerial, firstWidth + 85, startWidth);
            $doc.text($filter('number')($credit.originalValue, 2), firstWidth + 130, startWidth);
            $doc.text($filter('number')($credit.discountedValue, 2), firstWidth + 174, startWidth);
            $doc.text($filter('number')($credit.anticipatedValue, 2), firstWidth + 214, startWidth);
        }

        function addTotalByDate($total, $date, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total na data ' + $filter('date')(new Date($date), 'dd/MM/yy', 'UTC') + ':', firstWidth - 30, startWidth, null, null, 'right');
        }

        function addTotalByOrganization($total, $organization, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            // $doc.setLineDash([2.5]);
            // $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);

            var label = '' + $organization.organizationName;

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total para a Organização ' + label + ': ', firstWidth - 27, startWidth, null, null, 'right');
        }

        function addTotalByCompany($total, $company, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([2.5]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);

            var label = '' + $company.companyName + ' - ' + $company.identification;

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total para a Empresa ' + label + ': ', firstWidth - 29, startWidth, null, null, 'right');
        }

        function addTotal($total, $doc, startWidth, setLine) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            if (setLine) {
                $doc.setLineDash([]);
                $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);
            }

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Valor Total: ', firstWidth - 30, startWidth, null, null, 'right');
        }

        function addCompanyTotal($company, $doc, startWidth) {
            var firstWidth = 287;
            $doc.setFontSize(10);

            $doc.setLineDash([]);
            $doc.line(10, startWidth - 5, firstWidth, startWidth - 5);

            var label = '' + $company.name + ' - ' + $company.identification;

            $doc.setFontStyle('normal');
            $doc.text($filter('number')($company.total, 2), firstWidth, startWidth, null, null, 'right');

            $doc.setFontStyle('bold');
            $doc.text('Total para a Empresa ' + label + ': ', firstWidth - 29, startWidth, null, null, 'right');
        }

    }
})();
