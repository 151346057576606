(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('BatchCreditDialogController', BatchCreditDialogController);

    function BatchCreditDialogController ($scope, $uibModalInstance, entity, Batch, Program, Credit, ProgramProvider, ProgramBuyer, ProgramFinancial) {
        var vm = this;

        vm.batchCredit = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.programs = Program.query();
        vm.credits = Credit.query();
        vm.programproviders = ProgramProvider.query();
        vm.programbuyers = ProgramBuyer.query();
        vm.programfinancials = ProgramFinancial.query();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.batchCredit.id !== null) {
                Batch.update(vm.batchCredit, onSaveSuccess, onSaveError);
            } else {
                Batch.save(vm.batchCredit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('giroApiApp:batchCreditUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.paidDate = false;
        vm.datePickerOpenStatus.requestedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
