(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ClipboardModalController', ClipboardModalController);

    /** @ngInject **/
    function ClipboardModalController(entity, $uibModalInstance) {
        var vm = this;

        vm.boletoNumber = entity.boletoNumber;
        vm.urlBank = entity.urlBank;

        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
