(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ApprovalRequestDialogController', ApprovalRequestDialogController);

    function ApprovalRequestDialogController($translate, $timeout, $uibModalInstance, programId, batchesId, ApprovalProfile, ApprovalRequested) {
        var vm = this;

        vm.programId = programId;
        vm.batchesId = batchesId;
        vm.approvalProfiles = [];

        findApprovalProfiles();

        function findApprovalProfiles() {
            ApprovalProfile.getByProgram({first: vm.programId}, function (result) {
                vm.approvalProfiles = result;
            })
        }

        vm.approvedProfileChanged = function ($approvalProfile) {
            if ($approvalProfile.selected) {
                $approvalProfile.selected = false;
            } else {
                $approvalProfile.selected = true;
            }
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.request = function () {
            var $batches = vm.batchesId
                .map(function (item) {
                    return {id: item}
                });

            var $approvalProfiles = vm.approvalProfiles
                .filter(function (value) {
                    return value.selected
                });

            var requests = {
                approvalProfiles: $approvalProfiles,
                batches: $batches
            };

            ApprovalRequested.requestMultiples(requests,
                function (result) {
                    $timeout(function () {
                        $uibModalInstance.close(true);
                    }, 150);
                })
        }
    }
})();
