
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CreditApprovedDetailController', CreditApprovedDetailController);

    /** @ngInject **/
    function CreditApprovedDetailController($filter, $scope, $window, $stateParams, $state, previousState, Batch, SessionTerm) {
        var vm = this;
        vm.batchId = $stateParams.batchId;

        Batch.getBatchDetail({id:vm.batchId}, function (result) {
            vm.batch = result;
            vm.batch.credits.forEach(function (credit){
                credit.enableRemoveButton =  credit.status !== 'CONFIRMED' || credit.status !== 'PAID' || credit.status !==  'WAIT_CONFIRMATION' || credit.status !== 'FINANCIAL';
            })
        });

        vm.downloadSessionTerm = function (batch) {
            SessionTerm.getSessionTermFile({id: batch.sessionTerm.id}, function (result) {
                var fileName = batch.programProvider.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".pdf";
                debugBase64(result, fileName);
            })
        };

        vm.downloadCnab = function (batch) {
            Batch.getCnab({id: batch.id}, function (result) {
                var fileName = result.fileName;

                if (!fileName) {
                    fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".REM";
                }

                debugBase64(result, fileName);
            })
        };


        function debugBase64(response, fileName) {
            var byteCharacters = atob(response.file);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            var file = new Blob([byteArray], {type: 'text/plain'});
            file.name = fileName;
            var fileUrl = $window.URL.createObjectURL(file);

            var link = document.createElement('a');

            link.href = fileUrl;
            link.download = fileName;
            link.click();
        }

        vm.generateCnab = function (batch) {
            Batch.generateCnab({id: batch.id}, function (result) {
                var fileName = result.fileName;
                if (!fileName) {
                    fileName = batch.programProvider.company.identification + $filter('date')(batch.acceptedDate, 'ddMMyyHHmmss') + ".REM";
                }

                debugBase64(result, fileName);
            });
        };

    }
})();
