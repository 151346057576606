(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES', 'GiroConstants'];

    function translationStorageProvider($cookies, $log, LANGUAGES, GiroConstants) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                $cookies.putObject(name, GiroConstants.Constants.locale);
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }
    }
})();
