(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ExternalCreditDetailController', ExternalCreditDetailController);


    /** @ngInject **/
    function ExternalCreditDetailController($scope, $rootScope, $state, previousState, entity, GiroConstants) {
        var vm = this;

        vm.currency = GiroConstants.Constants.currency;
        vm.credit = entity;
        vm.previousState = previousState.name;

        vm.clear = function () {
            $state.go(previousState.name || '^', previousState.params);
        };
    }
})();
