/**
 * Created by rene on 1/3/18.
 */
(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    /** @ngInject **/
    function stateConfig($stateProvider) {
        $stateProvider.state('dashboard-kpi', {
            parent: 'entity',
            url: '/dashboard-kpi',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUYER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dashboard-chart/dashboard-kpi.html',
                    controller: 'DashboardKpiController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('header');
                    $translatePartialLoader.addPart('footer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
