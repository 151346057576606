/**
 * Created by rene on 8/2/18.
 */
(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InviteCustomerController', InviteCustomerController);

    /** @ngInject **/
    function InviteCustomerController($state, $scope, $timeout, $uibModal, Company, Invite, ParseLinks, paginationConstants, noty, pagingParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 10;
        vm.query = pagingParams.search;
        vm.withUsers = pagingParams.withUsers;
        vm.search = search;

        vm.companies = [];

        vm.validateForm = function (searchForm) {
            if (vm.isSaving) {
                return true
            }

            if (!vm.query || vm.query.length < 3) {
                return true
            }

            return searchForm.$invalid;
        };

        function loadAll() {
            if (!vm.query) {
                return;
            }

            Company.queryCompanyInviteCustomer({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                withUsers: vm.withUsers,
                q: vm.query
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.companies = data.content;
                vm.page = data.number + 1;
            }

            function onError(error) {
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.query,
                withUsers: vm.withUsers,
                q: vm.query
            });
        }

        function search() {
            loadAll(vm.page);
        }

        vm.invite = function (email, companyId, emailId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-dialog.html',
                controller: 'InviteCustomerDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            companyId: companyId,
                            emailId: emailId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    loadAll();
                }
            }, function () {
            });
        };

        vm.cancel = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-cancel-dialog.html',
                controller: 'InviteCustomerCancelDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    loadAll();
                }
            }, function () {
            });
        };

        vm.confirm = function (email, inviteId) {
            $uibModal.open({
                templateUrl: 'app/entities/invite-customer/invite-customer-confirm-dialog.html',
                controller: 'InviteCustomerConfirmDialogController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: function () {
                        return {
                            email: email,
                            inviteId: inviteId
                        };
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    loadAll();
                }
            }, function () {
            });
        };
    }
})();
