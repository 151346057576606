(function () {
    'use strict';

    angular.module('giroApiApp')
        .filter('bytes', function () {
            return function (bytes, precision) {
                if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
                if (typeof precision === 'undefined') precision = 1;
                var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
                    number = Math.floor(Math.log(bytes) / Math.log(1024));
                return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
            }
        })
        .filter('carPlate', function () {
            return function (input) {
                var str = input + '';
                str = str.replace(/^([A-Z]{3})([0-9]{4}$)/, "$1-$2");
                return str;
            };
        })
        .filter('cep', function () {
            return function (input) {
                var str = input + '';
                str = str.replace(/\D/g, '');
                str = str.replace(/^(\d{2})(\d{3})(\d)/, "$1.$2-$3");
                return str;
            };
        })
        .filter('tel', function () {
            return function (input) {
                var str = input + '';
                str = str.replace(/\D/g, '');
                if (str.length === 11) {
                    str = str.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
                } else {
                    str = str.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
                }
                return str;
            };
        })
        .filter('cnpj', function () {
            return function (input) {
                // regex créditos Matheus Biagini de Lima Dias
                var str = input + '';
                str = str.replace(/\D/g, '');
                str = str.replace(/^(\d{2})(\d)/, '$1.$2');
                str = str.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
                str = str.replace(/\.(\d{3})(\d)/, '.$1/$2');
                str = str.replace(/(\d{4})(\d)/, '$1-$2');
                return str;
            };
        })
        .filter('cpf', function () {
            return function (input) {
                var str = input + '';
                str = str.replace(/\D/g, '');
                str = str.replace(/(\d{3})(\d)/, "$1.$2");
                str = str.replace(/(\d{3})(\d)/, "$1.$2");
                str = str.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
                return str;
            };
        })
        .filter('realbrasileiro', function () {
            return function (input) {
                return 'R$ ' + formatReal(input);
            };
        });

    function formatReal(int) {
        var tmp = int.toString().indexOf('.') !== -1 ? int + '' : int + '.00';
        var res = tmp.replace('.', '');
        tmp = res.replace(',', '');
        var neg = false;
        if (tmp.indexOf('-') === 0) {
            neg = true;
            tmp = tmp.replace('-', '');
        }
        if (tmp.length === 1) {
            tmp = '0' + tmp;
        }
        tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
        if (tmp.length > 6) {
            tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
        }
        if (tmp.length > 9) {
            tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, '.$1.$2,$3');
        }
        if (tmp.length > 12) {
            tmp = tmp.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, '.$1.$2.$3,$4');
        }
        if (tmp.indexOf('.') === 0) {
            tmp = tmp.replace('.', '');
        }
        if (tmp.indexOf(',') === 0) {
            tmp = tmp.replace(',', '0,');
        }
        return (neg ? '-' + tmp : tmp);
    }
})();
