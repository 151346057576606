(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Invoice', Invoice);

    function Invoice($resource) {
        var resourceUrl = 'api/invoice/search';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET'
            }
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('ProviderTitle', ProviderTitle);

    function ProviderTitle($resource) {
        var resourceUrl = 'api/provider-title/:action';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {action: 'search'}
            },
            'filter': {
                method: 'GET',
                params: {action: 'filter'}
            }
        });
    }
})();
