(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('InterestStatusManageController', InterestStatusManageController);

    /** @ngInject **/
    function InterestStatusManageController($state, $stateParams, previousState, InterestStatus, entity) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.interest = entity;


        function clear() {
            $state.go(previousState.name || '^', previousState.params);
        }

        function save(form) {
            if (form.editForm.$invalid) {
                return;
            }
            vm.isSaving = true;
            if (vm.interest.id) {
                InterestStatus.update(vm.interest, onSaveSuccess, onSaveError);
            } else {
                InterestStatus.create(vm.interest, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess(result) {
                $state.go(previousState.name || '^', $stateParams);
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        }

    }
})();
