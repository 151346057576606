(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('Bank', Bank);

    function Bank($resource) {
        var resourceUrl = 'api/banks/:id/:first';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {method: 'GET'},
            'update': {method: 'PUT'},
            'querySimple': {method: 'GET', params: {first: 'simple'}}
        });
    }
})();
