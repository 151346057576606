(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ImportedDataResultsController', ImportedDataResultsController);

    /** @ngInject **/
    function ImportedDataResultsController($uibModal, $stateParams, $state, ImportedDataDocument, entity, previousState, ParseLinks, paginationConstants, pagingParams, AlertService) {
        var vm = this;

        vm.document = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filter = {
            search: pagingParams.search,
            status: pagingParams.status,
            isDuplicate: pagingParams.isDuplicate,
            message: pagingParams.message
        };
        vm.results = [];

        vm.search = search;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.back = back;

        loadAll(pagingParams.page);

        function loadAll(page) {

            ImportedDataDocument.searchResults({
                id: $stateParams.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filter.search,
                status: vm.filter.status,
                isDuplicate: vm.filter.isDuplicate,
                message: vm.filter.message
            }, onSuccess, onError);


            function sort() {


                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = data.totalElements;
                vm.queryCount = vm.totalItems;
                vm.results = data.content;
                vm.page = page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: $stateParams.id,
                page: vm.page,
                search: vm.filter.search,
                status: vm.filter.status,
                isDuplicate: vm.filter.isDuplicate,
                message: vm.filter.message,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        }

        function search() {
            transition();
        }

        function back() {
            $state.go(previousState.name || '^', previousState.params);
        }

    }
})();
