(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('client-panel', {
                parent: 'entity',
                url: '/client-panel',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/client-panel/client-panel.html',
                        controller: 'ClientPanelController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('client-panel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('client-invoices', {
                parent: 'entity',
                url: '/client-invoice?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/client-panel/client-invoices.html',
                        controller: 'ClientInvoiceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'emissionDate,desc',
                        squash: true
                    },
                    search: undefined
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('client-invoice');
                        return $translate.refresh();
                    }]
                }
            })
            .state('client-titles', {
                parent: 'entity',
                url: '/client-titles?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CLIENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/client-panel/client-titles.html',
                        controller: 'ClientTitleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: undefined
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('client-title-invoice');
                        $translatePartialLoader.addPart('clipboard-modal');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
