(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('accessType', accessType);

    function accessType() {
        return function (value) {
            switch (value) {
                case 'OWNER':
                    return "Proprietário";
                case 'LEGAL_RESPONSIBLE':
                    return "Responsável Legal";
                case 'OPERATOR':
                    return "Operador";
            }
            return value;
        }
    }
})();
