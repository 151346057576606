(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('ManageJobController', ManageJobController);

    function ManageJobController($translate, $location, ManageJob, $uibModal, noty) {
        var vm = this;

        // Functions
        vm.processImportedDocument = processImportedDocument;
        vm.expireCredits = expireCredits;
        vm.recalculateCredits = recalculateCredits;
        vm.sendWhatsappInformative = sendWhatsappInformative;

        vm.currentUrl = $location.host();


        function processImportedDocument() {
            ManageJob.processImportedDocument({}, function () {
                noty.success($translate.instant('message.alert.processImportedDocument.executed'));
            });
        }

        function expireCredits() {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.expireCredits({}, function () {
                    noty.success($translate.instant('message.alert.expireCredits.executed'));
                });
            });
        }

        function sendWhatsappInformative() {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.sendWhatsappInformative({}, function () {
                    noty.sucess($translate.instant('message.alert.resendWhatsappInformative.executed'))
                })
            });
        }

        vm.sendDailyCreditAvailable = function () {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.sendDailyCreditAvailable({}, function () {
                    noty.success($translate.instant('message.alert.sendDailyCreditAvailable.executed'));
                });
            });
        };

        function recalculateCredits() {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.recalculateCredits({}, function () {
                    noty.success($translate.instant('message.alert.recalculateCredits.executed'));
                });
            });
        }

        vm.reprocessImportedDocument = function () {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.reprocessImportedDocument({}, function () {
                    noty.success($translate.instant('message.alert.reprocessImportedDocument.executed'));
                });
            });
        };

        vm.updateProviderAuthorityRole = function () {
            ManageJob.updateProviderAuthorityRole({}, function () {
                noty.success($translate.instant('message.alert.updateProviderAuthorityRole.executed'));
            });
        };

        vm.inviteCompanyWithoutUserAndInvite = function () {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.inviteCompanyWithoutUserAndInvite({}, function () {
                    noty.success($translate.instant('message.alert.inviteCompanyWithoutUserAndInvite.executed'));
                });
            });
        };

        vm.removeImporterHash = function () {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.removeImporterHash({}, function () {
                    noty.success($translate.instant('message.alert.removeImporterHash.executed'));
                });
            });
        };

        vm.expireOverdueCredit = function () {
            $uibModal.open({
                templateUrl: 'app/admin/manage-job/manage-job-confirm-dialog.html',
                controller: 'ManageJobConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {
                ManageJob.expireOverdueCredit({}, function () {
                    noty.success($translate.instant('message.alert.removeImporterHash.executed'));
                });
            });
        };
    }
})();
