(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('CompanyEvent', CompanyEvent);

    function CompanyEvent($resource) {
        var resourceUrl = 'api/company-events/:id/:action/:type';

        return $resource(resourceUrl, {id: '@id'}, {
            'update': {method: 'PUT'},
            'clickAnticipateProgram': {method: 'POST', params: {action: 'click', type: 'anticipate'}},
            'wishAnticipateProgram': {method: 'POST', params: {action: 'wish', type: 'anticipate'}},
            'get': {method: 'GET'},
            'getAll': {method: 'GET'},
            'saveFromAnticipationPanel': {method: 'POST', params: {action: 'anticipation', type: 'panel'}}
        });
    }
})();
