(function() {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('programStatus', programStatus)
        .filter('programStrategy', programStrategy);

    function programStatus() {
        return function (value) {
            switch (value) {
                case 'ACTIVE':
                    return "Ativo";
                case 'PAUSED':
                    return "Pausado";
                case 'FINISHED':
                    return "Finalizado";
            }
            return value;
        }
    }

    function programStrategy() {
        return function (value) {
            switch (value) {
                case 'AUTO_IMPORT':
                    return "Automático";
                case 'NEED_REVIEW':
                    return "Após revisão";
            }
            return value;
        }
    }
})();
