(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('OrganizationShowTokenController', OrganizationShowTokenController);

    function OrganizationShowTokenController($uibModalInstance, entity) {
        var vm = this;

        vm.jwt = entity;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.close();
        }
    }
})();
