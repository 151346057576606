(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('credit-visualizer', {
                parent: 'entity',
                url: '/credit-visualizer?page&search&providerId&program&category&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_PROVIDER', 'ROLE_BUYER', 'ROLE_FINANCIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-visualizers.html',
                        controller: 'CreditVizualizerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    search: undefined,
                    program: undefined,
                    category: undefined,
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            program: $stateParams.program,
                            category: $stateParams.category
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail-provider', {
                parent: 'entity',
                url: '/credit-detail-provider?page&search&providerId&programId&sort',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-detail-provider.html',
                        controller: 'CreditDetailProviderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    search: undefined,
                    sort: {
                        value: 'expireDate,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    programProvider: ['$stateParams', 'ProgramProvider', function ($stateParams, ProgramProvider) {
                        return ProgramProvider.resume().$promise
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        $translatePartialLoader.addPart('credit-detail-provider');
                        $translatePartialLoader.addPart('batch-visualizer-request-dialog');
                        $translatePartialLoader.addPart('message');

                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-approved-detail-provider', {
                parent: 'entity',
                url: '/credit-approved-detail-provider?page&search&providerId&programId&sort',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-approved-detail-provider.html',
                        controller: 'CreditApprovedDetailProviderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    search: undefined,
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    backState: undefined,
                    enableBack: true,
                    cancelRef: 'credit-provider-cancel',
                    reportRef: 'anticipation-report',
                    detailRef: 'credit-approved-detail'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    backState: ['$stateParams', function ($stateParams) {
                        return $stateParams.backState;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        $translatePartialLoader.addPart('credit-approved-detail-provider');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dashboard/credit-approved-detail-provider', {
                parent: 'webapp-redirect',
                url: '/dashboard/credit-approved-detail-provider?page&search&providerId&programId&sort',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_ADMIN', 'ROLE_ORGANIZATION_SUPPORT', 'ROLE_GIRO_SUPPORT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-approved-detail-provider.html',
                        controller: 'CreditApprovedDetailProviderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    search: undefined,
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    backState: undefined,
                    enableBack: false,
                    cancelRef: 'dashboard/credit-provider-cancel',
                    reportRef: 'dashboard/anticipation-report',
                    detailRef: 'dashboard/credit-approved-detail'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    backState: ['$stateParams', function ($stateParams) {
                        return $stateParams.backState;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        $translatePartialLoader.addPart('credit-approved-detail-provider');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail-buyer', {
                parent: 'entity',
                url: '/credit-detail-buyer?providerId&programId&sort&page&search&',
                data: {
                    authorities: ['ROLE_BUYER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-detail-buyer.html',
                        controller: 'CreditDetailBuyerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    search: undefined,
                    tabIndex: 0,
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    pageExecuted: {
                        value: '1',
                        squash: true
                    },
                    sortExecuted: {
                        value: 'createdDate,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            pageExecuted: PaginationUtil.parsePage($stateParams.pageExecuted),
                            sortExecuted: $stateParams.sortExecuted,
                            predicateExecuted: PaginationUtil.parsePredicate($stateParams.sortExecuted),
                            ascendingExecuted: PaginationUtil.parseAscending($stateParams.sortExecuted),
                            tabIndex: $stateParams.tabIndex,
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-approved-detail', {
                parent: 'entity',
                url: '/credit-approved-detail?batchId',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-approved-detail.html',
                        controller: 'CreditApprovedDetailController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-approved-detail');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dashboard/credit-approved-detail', {
                parent: 'webapp-redirect',
                url: '/dashboard/credit-approved-detail?batchId',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_ADMIN', 'ROLE_FINANCIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-approved-detail.html',
                        controller: 'CreditApprovedDetailController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('credit-approved-detail');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail-financial', {
                parent: 'entity',
                url: '/credit-detail-financial?page&currentSearch&providerId&programId&sort',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-detail-financial.html',
                        controller: 'CreditDetailFinancialController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    currentSearch: undefined,
                    sort: {
                        value: 'requestedDate,asc',
                        squash: true
                    },
                    tabIndex: 0,
                    pageExecuted: {
                        value: '1',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            currentSearch: $stateParams.currentSearch,
                            programId: $stateParams.programId,
                            pageExecuted: PaginationUtil.parsePage($stateParams.pageExecuted),
                            sortExecuted: $stateParams.sortExecuted,
                            tabIndex: $stateParams.tabIndex
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        $translatePartialLoader.addPart('credit-detail-financial');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-approved-detail-financial', {
                parent: 'entity',
                url: '/credit-approved-detail-financial?page&currentSearch&providerId&programId&sort',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-detail/credit-approved-detail-financial.html',
                        controller: 'CreditApprovedDetailFinancialController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    currentSearch: undefined,
                    sort: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    tabIndex: 0,
                    pageExecuted: {
                        value: '1',
                        squash: true
                    },
                    backState: undefined
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('account');
                        $translatePartialLoader.addPart('credit-detail-financial');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail-financial.request-approve', {
                parent: 'credit-detail-financial',
                url: '/{programId}/request/approve',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                params: {
                    batchesId: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/approval-requested/approval-request-dialog.html',
                        controller: 'ApprovalRequestDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            programId: function () {
                                return $stateParams.programId;
                            },
                            batchesId: function () {
                                return $stateParams.batchesId;
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('approval-request-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    }, function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    });
                }]
            })
            .state('credit-provider-cancel', {
                parent: 'credit-approved-detail-provider',
                url: '/{id}/cancel',
                data: {
                    authorities: ['ROLE_PROVIDER', 'ROLE_ADMIN']
                },
                params: {
                    backState: undefined
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-cancel-dialog.html',
                        controller: 'BatchVisualizerCancelController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Batch', function (Batch) {
                                return Batch.getSimple({id: $stateParams.id}).$promise;
                            }],
                            backState: [function () {
                                return $stateParams.backState;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-cancel-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-approved-detail-provider', {backState: $stateParams.backState}, {reload: 'credit-approved-detail-provider'});
                    }, function () {
                        $state.go('credit-approved-detail-provider', {backState: $stateParams.backState}, {reload: 'credit-approved-detail-provider'});
                    });
                }]
            })
            .state('dashboard/credit-provider-cancel', {
                parent: 'dashboard/credit-approved-detail-provider',
                url: '/{id}/cancel',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                params: {
                    backState: undefined
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-cancel-dialog.html',
                        controller: 'BatchVisualizerCancelController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Batch', function (Batch) {
                                return Batch.getSimple({id: $stateParams.id}).$promise;
                            }],
                            backState: [function () {
                                return $stateParams.backState;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-cancel-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('dashboard/credit-approved-detail-provider', {backState: $stateParams.backState}, {reload: 'dashboard/credit-approved-detail-provider'});
                    }, function () {
                        $state.go('dashboard/credit-approved-detail-provider', {backState: $stateParams.backState}, {reload: 'dashboard/credit-approved-detail-provider'});
                    });
                }]
            })
            .state('credit-detail-provider.request', {
                parent: 'credit-detail-provider',
                url: '/{id}/request',
                data: {
                    authorities: ['ROLE_PROVIDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-request-dialog.html',
                        controller: 'BatchVisualizerRequestController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function (Credit, $q) {
                                var defer = $q.defer();
                                Credit.get({id: $stateParams.id}, function (result) {

                                    if (result.status !== 'OPEN') {
                                        defer.reject();
                                        return;
                                    }

                                    var batch = {
                                        credits: [result],
                                        value: result.value
                                    };

                                    defer.resolve(batch);
                                });
                                return defer.promise;

                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('account');
                                $translatePartialLoader.addPart('batch-visualizer-request-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-provider', undefined, {reload: 'credit-detail-provider'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit-detail-provider.external-request', {
                url: 'credit-detail-provider/{id}/external',
                redirectTo: 'provider-panel'
            })
            .state('credit-detail-financial.reject', {
                parent: 'credit-detail-financial',
                url: '/{id}/reject',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-reject-dialog.html',
                        controller: 'CreditVisualizerRejectController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['Batch', function (Batch) {
                                return Batch.getFinancialWithCredit({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-reject-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit-detail-financial.batchesReject', {
                parent: 'credit-detail-financial',
                url: '/reject/batches',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                params: {
                    batches: []
                },
                onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-simple-reject-dialog.html',
                        controller: 'CreditVisualizerSimpleRejectController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-simple-reject-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    }, function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    });
                }]
            })
            .state('credit-detail-financial.remove', {
                parent: 'credit-detail-financial',
                url: '/{id}/remove',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                params: {
                    credit: undefined
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-remove-dialog.html',
                        controller: 'CreditVisualizerRemoveController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: [function () {
                                return $stateParams.credit;
                            }],
                            batchId: [function () {
                                return $stateParams.id;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-remove-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    }, function () {
                        $state.go('^');
                    });
                }]


            })
            .state('credit-approved-detail.removeCredit', {
                parent: 'credit-approved-detail',
                url: '/batch/{id}/credit/{creditId}/remove',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_FINANCIAL']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-remove-confirmed-credit-simple-dialog.html',
                        controller: 'CreditVisualizerRemoveConfirmedCreditSimpleController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            creditId: [function () {
                                return $stateParams.creditId;
                            }],
                            batchId: [function () {
                                return $stateParams.batchId;
                            }],
                            backState: [function () {
                                return $stateParams.backState;
                            }],
                            previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name,
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-remove-confirmed-credit-simple-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-approved-detail', undefined, {reload: 'credit-approved-detail'});
                    }, function () {
                        $state.go('credit-approved-detail', undefined, {reload: 'credit-approved-detail'});
                    });
                }]
            })
            .state('credit-approved-detail-provider.removeConfirmedCredit', {
                parent: 'credit-approved-detail-provider',
                url: '/batch/{id}/credit/{creditId}/remove',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-remove-confirmed-credit-dialog.html',
                        controller: 'CreditVisualizerRemoveConfirmedCreditController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            creditId: [function () {
                                return $stateParams.creditId;
                            }],
                            batchId: [function () {
                                return $stateParams.id;
                            }],
                            backState: [function () {
                                return $stateParams.backState;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-remove-confirmed-credit-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-approved-detail-provider', undefined, {reload: 'credit-approved-detail-provider'});
                    }, function () {
                        $state.go('credit-approved-detail-provider', undefined, {reload: 'credit-approved-detail-provider'});
                    });
                }]
            })
            .state('credit-detail-financial.acceptBatches', {
                parent: 'credit-detail-financial',
                url: '/accept/batches',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                params: {
                    batches: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-accept-batches-dialog.html',
                        controller: 'AcceptBatchesDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-accept-batches-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit-detail-financial.voucher', {
                parent: 'credit-detail-financial',
                url: '/{id}/voucher',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-visualizer/credit-voucher.html',
                        controller: 'CreditVoucherController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Batch', '$stateParams', function (Batch, $stateParams) {
                        return Batch.getFinancial({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'program-buyer',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('header');
                        $translatePartialLoader.addPart('footer');
                        $translatePartialLoader.addPart('modal-errors');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('voucher');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-detail-financial.accept', {
                parent: 'credit-detail-financial',
                url: '/{id}/accept',
                data: {
                    authorities: ['ROLE_FINANCIAL']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-visualizer/batch-visualizer-confirm-dialog.html',
                        controller: 'BatchVisualizerConfirmController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['Batch', function (Batch) {
                                return Batch.getFinancial({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('header');
                                $translatePartialLoader.addPart('footer');
                                $translatePartialLoader.addPart('modal-errors');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('batch-visualizer-confirm-dialog');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-detail-financial', undefined, {reload: 'credit-detail-financial'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
