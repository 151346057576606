(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .filter('phoneType', phoneType);

    function phoneType() {
        return function (value) {
            switch (value) {
                case 'HOME':
                    return "Residêncial";
                case 'PROFESSIONAL':
                    return "Profissional";
                case 'CELLULAR':
                    return "Celular";
                case 'OTHER':
                    return "Outro";
            }
            return value;
        }
    }
})();
