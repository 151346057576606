(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('AnticipateBlockedDialogController', AnticipateBlockedDialogController);

    function AnticipateBlockedDialogController($translate, $timeout, $uibModalInstance, entity, CompanyProvider, noty) {
        var vm = this;

        vm.companiesList = entity;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.blockCompanies = function () {
            var list = {
                ids: vm.companiesList
                    .map(function (company) {
                        return company.id
                    })
            };

            CompanyProvider.anticipateBlocked(list, function () {
                noty.success($translate.instant('message.alert.anticipate.blocked'));

                $timeout(function () {
                    $uibModalInstance.close(true);
                }, 150)
            });
        };
    }
})();
