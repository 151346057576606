(function () {
    'use strict';
    angular
        .module('giroApiApp')
        .factory('SessionPromise', SessionPromise);

    function SessionPromise($resource) {
        var resourceUrl = 'api/sessions-promise/:id/:first';

        return $resource(resourceUrl, {id: '@id'}, {
            'generate': {method: 'POST', params: {first: 'generate'}},
            'download': {method: 'GET', params: {first: 'file'}}
        });
    }
})();
