(function () {
    'use strict';

    angular
        .module('giroApiApp')
        .controller('CompanyNotAnticipateController', CompanyNotAnticipateController);

    /** @ngInject **/
    function CompanyNotAnticipateController(noty, $state, Credit, Batch, paginationConstants, pagingParams,
                                            $stateParams, $uibModal, ParseLinks, Company) {
        var vm = this;

        vm.transition = transition;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 100;
        vm.loading = true;

        vm.companiesList = [];
        vm.companies = [];
        vm.allSelected = false;

        query();

        function query() {
            Company.findAllCompaniesNotAnticipate({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, function (result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.companies = result.content;
                vm.loading = false;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        vm.companyChanged = function ($company) {
            if ($company.batched) {
                vm.companiesList.push($company)
            } else {
                var index = vm.companiesList.findIndex(function (company) {
                    return company.id === $company.id
                });

                vm.companiesList.splice(index, 1);
            }
        };

        vm.selectAll = function() {
            if(vm.allSelected) {
                vm.companiesList = vm.companies;
                vm.companies.forEach(function (company) {
                    company.batched = true;
                })
            } else {
                vm.companiesList = [];
                vm.companies.forEach(function (company) {
                    company.batched = false;
                })
            }
        };
    }
})();
